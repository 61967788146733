import { AfterViewInit, Component, EventEmitter, OnInit } from '@angular/core';
import { MerchantService } from 'src/app/services/merchant/merchant.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboardv2',
  templateUrl: './dashboardv2.component.html',
  styleUrls: ['./dashboardv2.component.scss']
})
export class Dashboardv2Component implements OnInit, AfterViewInit {

  genReportEvent: EventEmitter<string>;
  genPerformanceReportEvent: EventEmitter<string>;
  merchantGroups: [];

  query = {
    startDate: new Date(),
    endDate: new Date(),
    merchantGroupIds: '',
  };

  constructor(private merchantService: MerchantService) {
    this.genReportEvent = new EventEmitter<string>();
    this.genPerformanceReportEvent = new EventEmitter<string>();
  }

  ngAfterViewInit() {
    setTimeout(_ => this.genReports(), 500);
  }

  ngOnInit(): void {
    this.getMerchantGroups();
    this.genReports();
  }

  getMerchantGroups() {
    this.merchantService.getGrantedMerchantGroups().subscribe(data => {
      this.merchantGroups = data;
    });
  }

  genReports() {
    this.genReportEvent.next();
  }

  onGetDataDone($event) {
    this.genPerformanceReportEvent.emit();
  }
}
