import { Component } from '@angular/core';
import { Global } from "./classes/global";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sale-data-center-spa';
  _global: Global;

  constructor(global: Global) {
    this._global = global;
  }
}
