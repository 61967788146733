<main>
  <div class="container-fluid">
    <h1 class="mt-4">Account Messages</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Account Messages</li>
    </ol>
    <div class="mb-4 actions">
      <div class="float-left">
        <button type="button" class="btn btn-primary" (click)="queryAccountMessages()">
          <i class="fas fa-redo"></i>
          Refresh Table
        </button>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="table-responsive message-table">
      <table class="table table-bordered table-hover">
        <tr>
          <th style="width: 165px">Account</th>
          <th style="width: 190px">Order</th>
          <th>Snippet</th>
          <th style="width: 160px;">Actions</th>
        </tr>
        <tr>
          <th>
            <select class="form-control"
                    [(ngModel)]="query.merchantId" (ngModelChange)="queryAccountMessages()">
              <option value="">All Accounts</option>
              <option *ngFor="let m of merchants" [value]="m.merchantId">{{ m.name }}</option>
            </select>
          </th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tbody>
          <tr *ngFor="let m of accountMessages">
            <td [class.isHandled]="m.isHandled">{{m.merchantName}}</td>
            <td [class.isHandled]="m.isHandled">{{m.sourceOrderId}}</td>
            <td [class.isHandled]="m.isHandled">{{m.snippet}}</td>
            <td [class.isHandled]="m.isHandled">
              <div class="text-center">
                <button class="btn btn-outline-primary mr-2" (click)="openFullMessage(m)"><i class="fas fa-expand-arrows-alt"></i></button>
                <button class="btn btn-outline-success" (click)="toggleHandled(m)"><i class="fas fa-check"></i></button>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
      <div class="float-right">
        <app-pagination [(pageNumber)]="query.pageNumber"
                        [pageSize]="query.pageSize"
                        [totalItems]="totalItem"
                        (onPageChanged)="queryAccountMessages($event)">
        </app-pagination>
      </div>
    </div>
  </div>
</main>

<ng-template #fullMessageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Full Message</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="selectedMessage.message"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
  </div>
</ng-template>
