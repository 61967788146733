import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

const headers = new HttpHeaders({ 'responseType': 'arraybuffer' });

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private httpClient: HttpClient) { }

  getPersonalDailyReport(query: any) {
    return this.httpClient.get<any>('api/dailyReport/getPersonalDailyReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getSummaryReport(query: any) {
    return this.httpClient.get<any>('api/report/getSummaryReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getAccDisReport(query: any) {
    return this.httpClient.get<any>('api/report/getAccDisReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }
  
  getIncomeDisReport(query: any) {
    return this.httpClient.get<any>('api/report/getIncomeDisReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getNetworthDailyReport(query: any) {
    return this.httpClient.get<any>('api/report/getNetworthDailyReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getAsinPerformanceReport(query) {
    return this.httpClient.get<any>('api/report/getAsinPerformanceReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getEmployeePerformanceReport(query) {
    return this.httpClient.get<any>('api/report/getEmployeePerformanceReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  genFinancesReport(query: any) {
    return this.httpClient.get<any>('api/report/getFinancesReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getCurrencies(){
    return this.httpClient.get<any>('api/report/GetCurrencyRates/');
  }

  upsertDailyReport(teamReport) {
    return this.httpClient.post<any>('api/dailyReport/upsertDailyReport/', teamReport);
  }

  queryDailyReports(query: any) {
    return this.httpClient.get<any>('api/dailyReport/queryReports/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getDailyReportById(id) {
    return this.httpClient.get<any>('api/dailyReport/getDailyReportById?reportId=' + id);
  }

  getAvailableUsers() {
    return this.httpClient.get<any>('api/dailyReport/getAvailableUsers');
  }

  getDesPointDisReport(query) {
    return this.httpClient.get<any>('api/report/getDesPointDisReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }
}
