import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GenDesLayer, LayerOption } from '../../models/gen-des-layer';
import { fabric } from 'fabric';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { SaleRecordService } from "../../services/saleRecord/sale-record.service";
import { Global } from "../../classes/global";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-gen-des',
  templateUrl: './gen-des.component.html',
  styleUrls: ['./gen-des.component.scss']
})
export class GenDesComponent implements AfterViewInit {
  @ViewChild('GenDesPreview', { static: false })
  public genDesPreview: ElementRef;

  @ViewChild('genDesModal', { static: false })
  private genDesModal: NgbModalRef;

  @Input() inputSku: string;

  canvasSize = 1063;
  canvas: any;

  layerTypes = [
    { id: 0, value: 'Image', },
    { id: 1, value: 'Text', }
  ];
  layerFonts = ['Algerian', 'Pacifico', 'VT323', 'Quicksand', 'Inconsolata', 'BebasNeue', 'imagine'];
  layerFontSizes = Array.from({ length: 200 }, (_, i) => i + 1);

  template= {
    id: '',
    sku: '',
    canvasJson: '',
    layers: Array<GenDesLayer>()
  };

  alreadyHaveTemplate:Boolean = false;
  constructor(private modalService: NgbModal,
    private global: Global,
    private saleRecordService: SaleRecordService,
    private toastr: ToastrService) {

  }

  ngAfterViewInit(): void {
    
  }

  ngOnInit() {
    this.saleRecordService.templateForSkuExisted(this.inputSku)
      .subscribe((response: boolean) => {
        this.alreadyHaveTemplate = response;
      });

  }

  addLayer() {
    var newLayerName = 'New Layer ' + (this.template.layers.length + 1).toString();

    var newLayer = new GenDesLayer();
    newLayer.layerName = newLayerName;

    this.addLayerOption(newLayer);
    this.template.layers.push(newLayer);
  }

  addLayerOption(layer: GenDesLayer) {
    layer.options.push(new LayerOption());
  }

  chooseOptionImageFile(layer: GenDesLayer, layerOption: LayerOption, files: File) {
    this.toBase64(files[0]).then((base64String: string) => {
      layerOption.imageData = base64String;
      layerOption.thumbData = base64String;
      this.selectLayerOption(layer, layerOption);
    });
  }

  updateTextForLayer(layer: GenDesLayer) {
    var canvasLayers = this.canvas.getObjects();

    var layerIndex = this.template.layers.indexOf(layer);
    var oldLayerObject = undefined;
    if (layerIndex > -1) {
      oldLayerObject = canvasLayers[layerIndex];
      this.canvas.remove(oldLayerObject);
    }

    let left = oldLayerObject ? oldLayerObject.left : 0;
    let top = oldLayerObject ? oldLayerObject.top : 0;
    let width = oldLayerObject ? oldLayerObject.width : 500;
    let height = oldLayerObject ? oldLayerObject.height : 500;
    let angle = oldLayerObject ? oldLayerObject.angle : 0;
    let stroke = oldLayerObject ? oldLayerObject.storke : 'Black';
    let strokeWidth = oldLayerObject ? oldLayerObject.strokeWidth : 0;

    var newTextLayer = new fabric.Textbox(layer.layerText, {
      left: left,
      top: top,
      width: width,
      height: height,
      angle: angle,
      stroke: stroke,
      strokeWidth: strokeWidth
    });

//    newTextLayer.toObject = (toObject =>
//      () => fabric.util.object.extend(newTextLayer.callSuper('toObject'),
//        {
//          layerName: layer.layerName
//        }))(newTextLayer.toObject);

    newTextLayer.set('fontFamily', layer.layerFont);
    newTextLayer.set('fill', layer.layerTextColor);
    newTextLayer.set('fontSize', layer.layerFontSize);
    newTextLayer.set('strokeWidth', layer.layerStrokeSize);
    newTextLayer.set('stroke', layer.layerStrokeColor);


    this.canvas.add(newTextLayer);

    if (layerIndex > -1) {
      this.canvas.moveTo(newTextLayer, layerIndex);
    }

    this.canvas.renderAll();
  }

  selectLayerOption(layer: GenDesLayer, option: LayerOption) {
    if (option.isSelected) {
      return;
    }
    for (var i = 0; i < layer.options.length; i++) {
      layer.options[i].isSelected = false;
    }
    option.isSelected = true;

    var canvasLayers = this.canvas.getObjects();

    var layerIndex = this.template.layers.indexOf(layer);
    var oldLayerObject = undefined;
    if (layerIndex > -1) {
      oldLayerObject = canvasLayers[layerIndex];
      this.canvas.remove(oldLayerObject);
    }

    fabric.Image.fromURL(option.imageData,
      imageLayer => {
        var newImageScale = 1;
        if (imageLayer.width > this.canvasSize || imageLayer.height > this.canvasSize) {
          let maxWidthHeight = Math.max(imageLayer.width, imageLayer.height);
          newImageScale = this.canvasSize / maxWidthHeight;
        }

        let left = oldLayerObject ? oldLayerObject.left : 0;
        let top = oldLayerObject ? oldLayerObject.top : 0;
        let width = oldLayerObject ? oldLayerObject.width : imageLayer.width;
        let height = oldLayerObject ? oldLayerObject.height : imageLayer.height;
        var scale = oldLayerObject ? oldLayerObject.scaleX : newImageScale;
        let angle = oldLayerObject ? oldLayerObject.angle : 0;
        imageLayer.set(
          {
            left: left,
            top: top,
            width: width,
            height: height,
            angle: angle,
            scaleX: scale,
            scaleY: scale,
          });

        this.canvas.add(imageLayer);
        if (layerIndex > -1) {
          this.canvas.moveTo(imageLayer, layerIndex);
        }

        this.canvas.renderAll();
      });
  }

  clearCanvas(): void {
    if (this.canvas) {
      this.canvas.clear();
    }
  };

  saveAsImage() {
    var dataUrl = this.canvas.toDataURL({ format: 'png' });

    var link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'canvas.png';

    link.click();

    link.remove();
  };

  saveTemplate() {
    this.template.canvasJson = JSON.stringify(this.canvas.toJSON());
    this.template.sku = this.inputSku;
    this.global.pageLoading = true;
    this.saleRecordService.saveDesignTemplate(this.template)
      .subscribe(() => {
        this.toastr.success('Template được lưu');
        this.global.pageLoading = false;
      });
  };

  openDesTemplateModal() {
    this.template = {
      id: '',
      sku: '',
      canvasJson: '',
      layers: Array<GenDesLayer>()
    };

    if (this.canvas) {
      this.canvas.clear();
    }

    this.global.pageLoading = true;
    
    this.saleRecordService.getDesTemplateForSku(this.inputSku)
      .subscribe((response) => {

        this.modalService.open(this.genDesModal, { windowClass: "xlModal" });
        this.canvas = new fabric.Canvas('myCanvas');

        if (response) {
          this.mapSavedResponseToCurrentTemplate(response);
        }

        if (this.template.layers.length == 0) {
          this.addLayer();
        }

        this.global.pageLoading = false;
      });

  }

  private mapSavedResponseToCurrentTemplate(response: any) {
    this.alreadyHaveTemplate = true;
    var canvasData = JSON.parse(response.canvasJson);
    this.canvas.loadFromJSON(canvasData);
    this.canvas.renderAll();
    this.template = response;
  }

  private toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };
}
