import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Global } from "../../classes/global";
import { HttpClient, HttpEventType } from "@angular/common/http";

@Component({
  selector: 'app-images-show',
  templateUrl: './images-show.component.html',
  styleUrls: ['./images-show.component.scss']
})
export class ImagesShowComponent implements OnInit {

  @Input() images: any[];

  @Output() getImages: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('addImageModal', { static: false })
  private addImageModal: NgbModalRef;

  mainImageUrl = '';

  addImage ={
    link: '',
    file : ''
  };

  constructor(public global: Global,
    private http: HttpClient,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.images && this.images.length > 0) {
      this.mainImageUrl = this.images[0].imageUrl;
    }
  }

  openAddImageModal() {
    this.resetAddImageModel();
    this.addImageModal = this.modalService.open(this.addImageModal);
  }

  upload(files) {
    const formData = new FormData();

    if (!files || files.length === 0) {
      return;
    }

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.global.pageLoading = true;
    this.http
      .post("/api/Files/Uploads/" + "PreProductMockups", formData)
      .subscribe((res: any) => {
        this.global.pageLoading = false;
        this.addImage.link = res;
      });
  }

  addImageToShow() {
    if (!this.addImage.link) {
      return;
    }

    this.images.push({
      thumbUrl: this.addImage.link,
      imageUrl: this.addImage.link,
    });
    this.mainImageUrl = this.addImage.link;

    this.resetAddImageModel();
    this.closeModal();
  }

  closeModal() {
    this.addImageModal.dismiss();
  }

  resetAddImageModel() {
    this.addImage = {
      link: '',
      file: ''
    };
  }
}

