<span *ngIf="(records && records.length > 0) || (mockupRecords && mockupRecords.length > 0)">
  <h5>{{title}}</h5>
  <table class="table table-bordered table-sm sale-record-list">
    <tr>
      <th>Mã Đơn</th>
      <th>Seller</th>
      <th class="des-note" style="width: 300px;">Note</th>
      <th class="text-right">Des Point</th>
    </tr>
    <tr *ngFor="let r of records">
      <td style="min-width: 251px;">{{r.sourceOrderId}}</td>
      <td style="min-width: 251px;">{{r.merchant.merchantGroup.name}}</td>
      <td [innerHtml]="shortLinkForText(r.note)"></td>
      <td class="text-right" [class.bg-text-red]="r.desPoint >=5">
        <span *ngIf="!userData.fromSellerToAbove">{{r.desPoint}}</span>
        <ng-select *ngIf="userData.fromSellerToAbove"
                   [items]="desPoints"
                   [(ngModel)]="r.desPoint"
                   (change)="updateDesPointFromManager(r, 'SaleRecord')">
        </ng-select>
      </td>
    </tr>
    <tr *ngFor="let r of mockupRecords">
      <td style="min-width: 251px;">{{r.sku}}</td>
      <td style="min-width: 251px;">{{r.name}}</td>
      <td [innerHtml]="shortLinkForText(r.note)"></td>
      <td class="text-right" [class.bg-text-red]="r.desPoint >=5">
        <span *ngIf="!userData.fromSellerToAbove">{{r.desPoint}}</span>
        <ng-select
          *ngIf="userData.fromSellerToAbove"
          [items]="desPoints"  
          [(ngModel)]="r.desPoint"
          (change)="updateDesPointFromManager(r, 'Mockup')">
        </ng-select>
      </td>
    </tr>
  </table>
</span>
