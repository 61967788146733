<nav aria-label="Page navigation example">
  <ul class="pagination">
    <li><a class="page-link" (click)="goToFirst()">First</a></li>
    <li><a class="page-link" (click)="goToPrev()">Prev</a></li>

    <li class="page-item" *ngFor="let p of pages">
      <a class="page-link" [class.active]="p == pageNumber" (click)="goToPage(p)">{{p}}</a>
    </li>

    <li class="page-item"><a class="page-link" (click)="goToNext()">Next</a></li>
    <li class="page-item"><a class="page-link" (click)="goToLast()">Last</a></li>
  </ul>
</nav>
<span class="mt-4">Pages: {{totalPages}} - Total: {{totalItems}}</span>
