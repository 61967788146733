import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../../services/user/user.service";
import { MerchantService } from "../../services/merchant/merchant.service";
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { Global } from "../../classes/global";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { escapeRegExp } from 'lodash';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent extends NeedAuthorize {
  @ViewChild('merchantModal') merchantModal: ElementRef;
  @ViewChild('setupTiktokModal') setupTiktokModal: ElementRef;

  query = {
    queryString: '',
    pageSize: 10,
    pageNumber: 1,
    amzOnly: false,
    includeDeleted: false,
  };

  queryResult: any = {
    item: [],
    total: 0
  };

  merchantDetail: any = {
    merchantId: '',
    name: '',
    mainEmail: '',
    autoClientEmail: '',
    isDeleted: false,
    vpsName: '',
    note: '',
    bank: '',
    merchant: {
      id: '',
      name: ''
    }
  }

  merchantGroups = [];

  merchantTypes = [
    {
      id: 0,
      name: 'Chưa set'
    },
    {
      id: 1,
      name: 'Amazon'
    },
    {
      id: 2,
      name: 'TikTok'
    },
    {
      id: 3,
      name: 'Ebay'
    },
    {
      id: 4,
      name: 'Etsy'
    },
    {
      id: 5,
      name: 'Depop'
    }
  ];

  setupTiktok:any={
    merchantId: '',
    setupLink: ''
  }

  userData:any;

  constructor(protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    private global: Global,
    private modalService: NgbModal,
    private merchantService: MerchantService,
    private toastr: ToastrService) {
    super(router, userService, titleService);
    this.queryMerchants();
    this.getMerchantGroups();
  }

  ngOnInit(): void {
  }

  queryMerchants(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    this.global.pageLoading = true;
    this.merchantService.queryMerchants(this.query).subscribe(data => {
      this.queryResult = data;
      this.global.pageLoading = false;
    });
  }

  openMerchantModal(merchantId) {
    this.merchantDetail = {
      merchantId: '',
      name: '',
      mainEmail: '',
      autoClientEmail: '',
      isDeleted: false,
      vpsName: '',
      note: '',
      bank: '',
      merchantGroup: {
        id: '',
        name: ''
      }
    };
    this.merchantDetail.merchantId = merchantId;
    if (merchantId) {
      this.global.pageLoading = true;
      this.merchantService.getMerchant(merchantId)
        .subscribe(data => {          
          this.global.pageLoading = false;
          this.merchantDetail = data;
          this.modalService.open(this.merchantModal, { windowClass: "lModal" });
        });
    }
    else {
      this.modalService.open(this.merchantModal, { windowClass: "lModal" }); 
    }
  }

  openSetupTiktokModal(merchantId) {
    this.setupTiktok= {
      merchantId: merchantId,
      returnLink: ''
    };
    this.modalService.open(this.setupTiktokModal, { windowClass: "lModal" }); 
  }

  setupTiktokApi() {
    this.global.pageLoading = true;
    this.merchantService.setupTiktokApi(this.setupTiktok)
      .subscribe(data => {
        this.global.pageLoading = false;
        this.toastr.success('Setup Tiktok Api Done');
        this.modalService.dismissAll();
      });

  }

  getMerchantGroups() {
    this.merchantService.getGrantedMerchantGroups().subscribe(data => {
      this.merchantGroups = data;
    });
  }

  saveMerchant()  
  {
    this.global.pageLoading = true;
    this.merchantService.saveMerchant(this.merchantDetail).subscribe(data => {
      this.global.pageLoading = false;
      this.modalService.dismissAll();
      
      for (let i = 0; i < this.queryResult.items.length; i++) {
        if (this.queryResult.items[i].merchantId == data.merchantId) {
          this.queryResult.items[i] = data;          
          break;
        }
      }
    });
  }

  deleteMerchant(merchant) {
    this.global.pageLoading = true;
    merchant.isDeleted = !merchant.isDeleted;
    this.merchantService.saveMerchant(merchant).subscribe(data => {
      this.global.pageLoading = false;
      this.modalService.dismissAll();
      
      for (let i = 0; i < this.queryResult.items.length; i++) {
        if (this.queryResult.items[i].merchantId == data.merchantId) {
          this.queryResult.items[i] = data;          
          break;
        }
      }
    });
  }

  openOrderSheetModal() {

  }
}
