<main>
  <div class="container-fluid">
    <h1 class="mt-4">Product Reports</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Product Reports</li>
    </ol>
    <div class="row">
      <div class="col-4">
        <input class="form-control form-control-sm" [owlDateTimeTrigger]="startDateString" [owlDateTime]="startDateString"
               [(ngModel)]="query.startDateString" (ngModelChange)="queryAsinReport()" placeholder="Start Date" />
        <owl-date-time [pickerType]="'calendar'" #startDateString></owl-date-time>
      </div>

      <div class="col-4">
        <input class="form-control form-control-sm" [owlDateTimeTrigger]="endDateString" [owlDateTime]="endDateString"
               [(ngModel)]="query.endDateString" (ngModelChange)="queryAsinReport()" placeholder="End Date" />
        <owl-date-time [pickerType]="'calendar'" #endDateString></owl-date-time>
      </div>

      <!-- <div class="col-3" [hidden]="query.market != 'Amazon'">
        <div>
          <label class="switch">
            <input type="checkbox" [(ngModel)]="query.isCheckout" (ngModelChange)="changeToMediate()">
            <span class="slider"></span>
          </label> Is Mediate
        </div>
      </div> -->
    </div>
    
    <div class="row mt-20">
      <div class="col-12">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link tab-link" [class.active]="query.market =='Amazon'" (click)="changeTab('Amazon')">Amazon</a>
          </li>
          <li class="nav-item">
            <a class="nav-link tab-link" [class.active]="query.market =='Redbuble'" (click)="changeTab('Redbuble')" aria-current="page">Redbuble</a>
          </li>
        </ul>
      </div>
      <div class="col-12 mt-10">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Link</th>
              <th *ngIf="currentTab == 'Amazon'" class="text-left">Account Name</th>
              <th class="text-right">Order Count</th>
            </tr>
            <tbody>
              <tr *ngFor="let record of records">
                <td class="text-left">{{record.name}}</td>
                <td class="text-center">
                  <a *ngIf="record.link != null && record.link != undefined" href="{{record.link}}" target="_blank"><i class="fas fa-eye fa-lg"></i></a>
                </td>
                <td class="text-left" *ngIf="currentTab == 'Amazon'">{{record.accountName}}</td>
                <td class="text-right">{{record.orderCount}}</td>
              </tr>
            </tbody>
          </table>
          <div class="float-right">
            <app-pagination [(pageNumber)]="query.pageNumber"
                            [pageSize]="query.pageSize"
                            [totalItems]="totalItem"
                            (onPageChanged)="queryAsinReport($event)">
            </app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
