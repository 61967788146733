<h3>Báo cáo tài chính</h3>
<table class="table table-bordered table-sm" [hidden]="reportLoading">
    <tbody *ngFor="let g of data">
        <tr class="table-active">
            <th >{{g.groupName}}</th>
            <th class="text-right group-name-row">
                <div class="row">
                    <div class="text-success col-3 offset-3">In: {{g.totalIn| number : '1.0-2'}}</div>
                    <div class="text-danger col-3">Out: {{g.totalOut| number : '1.0-2'}}</div>
                    <div class="col-3" [class.text-success]="g.totalAmount >= 0"
                        [class.text-danger]="g.totalAmount < 0">Total: {{g.totalAmount| number : '1.0-2'}}</div>
                </div>
            </th>
        </tr>

        <tr *ngFor="let t of g.transactionTypeReports">
            <td>{{t.typeName}}</td>
            <td class="text-right">
                <span [class.text-success]="t.category == 'in'" [class.text-danger]="t.category == 'out'">
                    {{t.totalAmount| number : '1.0-2'}}
                </span>
            </td>
        </tr>
    </tbody>
</table>
<app-report-loading *ngIf="reportLoading"></app-report-loading>