<div class="col-12">
  <div class="row">
    <div class="col-1 flex-container thumbs">
      <span *ngFor="let image of images">
        <img class="img-fluid"
             [src]="image.imageUrl"
             [class.active]="image.imageUrl === mainImageUrl"
             (click)="mainImageUrl = image.imageUrl"/>
      </span>

      <button class="btn btn btn-outline-info add-image-btn" (click)="openAddImageModal()">
        <i class="fas fa-plus-square"></i>
      </button>
    </div>
    <div class="col-11">
      <img *ngIf="!images || images?.length ===0"
           class="img-fluid main-image"
           src="https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg" />

      <a *ngIf="images?.length > 0" [href]="mainImageUrl" target="_blank">
        <img  class="img-fluid main-image" [src]="mainImageUrl" />
      </a>
    </div>
  </div>
</div>


<ng-template #addImageModal let-modal>
  <div class="addImageModal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Thêm ảnh
      </h4>
    </div>

    <div class="modal-body">
      <form name="addImageForm">
        <div class="form-row">
          <label class="col-form-label-sm">Link</label>
          <input type="text" class="form-control form-control-sm" name="sku" [(ngModel)]="addImage.link"/>
        </div>
        <br />
        <div class="form-row">
          <label class="col-form-label-sm">File</label>
          <input #addImageFileInput
                 type="file"
                 [(ngModel)]="addImage.file" (change)="upload($event.target.files)"
                 [name]="addImageFileInput">
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="closeModal()">Hủy</button>
      <button type="button" class="btn btn-primary" (click)="addImageToShow()">Thêm Ảnh</button>
    </div>
  </div>
</ng-template>
