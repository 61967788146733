import { Component, OnInit } from '@angular/core';
import { Global } from "../../classes/global";
import { Router } from '@angular/router'
import { NeedAuthorize } from "../../classes/needAuthorize";
import { UserService } from "../../services/user/user.service";

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {

  currentUrl: any;
  userData: any;

  constructor(private router: Router, public global: Global, private userService: UserService) {
    this.userData = userService.getLocalUserInfo();

    this.router.events.subscribe((event) => {
      var evenUrl = (event as any).url;
      if (evenUrl != undefined) {
        this.currentUrl = evenUrl;
      }
    });
  }

  ngOnInit(): void {
  }

  logout() {
    this.userService.logout();
  }
}
