import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user/user.service";
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { Global } from "../../classes/global";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent extends NeedAuthorize {

  isCreateNew = false;
  accountId = '';

  user: any = {
    roleDetails: [],
    accountGroups: [],
  };

  constructor(protected router: Router, protected userService: UserService, protected titleService: Title, private global: Global, private route: ActivatedRoute) {
    super(router, userService, titleService);

    this.route.params.subscribe(params => {

      this.accountId = params['accountId'];
      this.isCreateNew = this.accountId === 'new';

      if (!this.isCreateNew) {
        this.global.pageLoading = true;
        this.userService.getAccountDetail(this.accountId).subscribe(data => {
          this.user = data;
          this.global.pageLoading = false;
        });
      }
    });
    
  }

  ngOnInit(): void {
  }

  saveChanges() {
    this.user.isCreateNew = this.isCreateNew;
    this.userService.saveAccountDetailAdmin(this.user).subscribe(data => {
      this.router.navigate(["/accountAdmin"]);
    });
  }
}
