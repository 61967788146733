<form>
    <div class="form-row">
        <div class="col-5">
            <div class="input-group input-group-sm">
                <input type="text" class="form-control" 
                        aria-label="Số tiền" 
                        name="sourceAmount" 
                        (change)="updateDes()" 
                        [(ngModel)]="sourceAmount"
                        [disabled]="disabled">

                <div class="input-group-append" style="min-width: 100px;">
                    <ng-select [items]="currencyRates" 
                            class="custom-ng-select"
                            placeholder="Loại tiền"
                            bindValue="symbol"
                            bindLabel="symbol"                    
                            groupBy="group"        
                            [closeOnSelect]="true"          
                            name="symbol"     
                            (change)="updateSymbol()"                                      
                            [(ngModel)]="symbol"
                            [disabled]="disabled">                        
                        </ng-select>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="input-group input-group-sm">
                <input type="text" class="form-control" name="rate" (change)="updateDes()" [(ngModel)]="rate" [disabled]="disabled">
                <div class="input-group-append ">
                    <span class="input-group-text">Rate</span>
                </div>
            </div>
        </div>

        <div class="col-4">
            <div class="input-group input-group-sm">
                <input type="text" class="form-control" name="orderPrice" [(ngModel)]="des" [disabled]="disabled" (change)="desChange.emit(des)">
                <div class="input-group-append">
                    <span class="input-group-text">USD</span>
                </div>
            </div>
        </div>
    </div>
</form>