import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss']
})
export class SummaryReportComponent implements OnInit {
  @Input() query: any;
  @Input() genReportEvent: EventEmitter<string>;

  reportLoading = false;
  data = {    
    importNetworth: {
      orderCount: 0,
      orderMoney: 0
    },
    otherRefundNetworth: {
      orderCount: 0,
      orderMoney: 0
    },
    boughtSaleRecordNetworth: {
      orderCount: 0,
      orderMoney: 0
    },

    netIncomeString: ''
  };

  constructor(private reportService:ReportService) { 
  }

  ngOnInit(): void {
    this.genReportEvent.subscribe(data => {
      //Detail Sale Report Data      
      this.reportLoading = true;

      var query = JSON.parse(JSON.stringify(this.query));

      query.startDate = moment(query.startDate).format();
      query.endDate = moment(query.endDate).format();

      this.reportService.getSummaryReport(query).subscribe((data) => {
        this.data = data;

        this.reportLoading = false;
      })
    });
  }

}
