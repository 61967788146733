<p *ngIf='!editMode' (click)='activeEdit()' class="cell {{dataType}}">
  <span *ngIf="dataType == 'number' && data != '' && data != undefined && data != null && data != 0 && data != '0'">$ {{data}}</span>
  <span *ngIf="dataType != 'number'">{{shortLinkForText(data)}}</span>
</p>

<textarea [hidden]="!(editMode && dataType== 'textarea')" (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()' [(ngModel)]='data' class='form-control'
          (keydown.enter)='$event.target.blur()' (focusout)="editMode=false" autofocus #textarea rows="6"></textarea>

<input [hidden]="!(editMode && dataType== 'text')" (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()' [(ngModel)]='data' class='form-control'
       (keydown.enter)='$event.target.blur()' (focusout)="editMode=false" autofocus #text />

<input [hidden]="!(editMode && dataType== 'number')" (focusout)="onFocusOut()" (keydown.enter)='$event.target.blur()' [(ngModel)]='data' class='form-control'
       (keydown.enter)='$event.target.blur()' (focusout)="editMode=false" autofocus #number type="number" />
