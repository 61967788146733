<main>
  <div class="container-fluid">
    <h1 class="mt-4">Check Trademark</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Check Trademark</li>
    </ol>
    <div class="row">
      <div class="col-12">
        <input name="target" [(ngModel)]="target" (change)="checkTrademark()" class="form-control" placeholder="Please enter your keywork" />
      </div>
    </div>

    <div style="margin-top: 50px;"></div>
    <h5 *ngFor="let record of results" style="color: red"> - {{record}}</h5>
  </div>
</main>
