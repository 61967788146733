import { Injectable, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { UserService } from "../services/user/user.service";
import { Title, Meta } from '@angular/platform-browser';

export class NeedAuthorize implements OnInit  {
   public userData: any;

  constructor(protected router: Router, protected userService: UserService, protected titleService: Title) {
    this.userData = userService.getLocalUserInfo();
    if (this.userData == null) {
      this.router.navigate(["/login"]);
      return;
    }

    var componentName = this.constructor.name.replace('Component', '');
    titleService.setTitle(componentName);
  }

  ngOnInit(): void {
  }
}
