import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Global } from 'src/app/classes/global';
import { TransactionService as TransactionService } from 'src/app/services/transaction-service/transaction-service.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})
export class AddTransactionComponent implements OnInit {

  @ViewChild('transactionModal', { static: false }) private transactionModal: NgbModalRef;

  @Input() openTransactionModalEvent: EventEmitter<string>;
  @Output() transactionSaved = new EventEmitter<string>();

  merchantGroups: [];
  transactionTypes: any[];

  userData: any;

  transaction: any = {
    groupId: undefined,
    category: 'in',
    typeId: undefined,
    explain: undefined,
    amount: undefined,
    proof: undefined,
    loading: false,
    spendTime: new Date()
  };

  constructor(private modalService: NgbModal,
    private http: HttpClient,
    private transactionService: TransactionService,
    private toast: ToastrService,
    private userDataService: UserService) { }

  ngOnInit(): void {
    this.getMerchantGroupsForTransaction();

    this.openTransactionModalEvent.subscribe((category) => {
      this.resetTransaction();
      this.getTransactionTypes();
      this.transaction.category = category;
      this.openTransactionModal();
    });

    this.userData = this.userDataService.getLocalUserInfo();
  }

  openTransactionModal() {
    this.modalService.open(this.transactionModal, { windowClass: "lModal" });
  }

  updateTransactionCategory(target) {
    this.transaction.category = target;
    this.getTransactionTypes();
  }

  getMerchantGroupsForTransaction() {
    this.transactionService.getMerchantGroupsForTransaction()
      .subscribe(data => { this.merchantGroups = data });
  }

  getTransactionTypes() {
    this.transaction.typeId = undefined;
    this.transactionService.getTransactionTypes()
      .subscribe(data => {
        this.transactionTypes = _.filter(data, (t) => t.category == this.transaction.category);
      });
  }

  addTransactionType(newTransactionType) {

    if (newTransactionType
      && newTransactionType['typeName']
      && !newTransactionType['id']) {
      newTransactionType.category = this.transaction.category;
      this.transactionService.addTransactionType(newTransactionType)
        .subscribe(data => {
          this.getTransactionTypes();
          this.transaction.typeId = data.id;
        });
    }
  }

  uploadProofFile(files) {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    this.transaction.loading = true;
    this.http
      .post("/api/Transactions/UploadProof/", formData)
      .subscribe(res => {
        this.transaction.proof = res;
        this.transaction.loading = false;
      },
        error => {
          alert('Có lỗi xảy ra, xin thử lại');
          this.transaction.loading = false;
        });
  }

  saveTransaction() {
    if (!this.transaction.spendTime
      || !this.transaction.typeId
      || !this.transaction.explain
      || !this.transaction.amount
      || !this.transaction.proof) {
      this.toast.error('Xin kiểm tra lại thông tin giao dịch');
      return;
    }

    if (this.userData.roles.indexOf('Team Leader') > -1
      && !this.transaction.groupId) {
        this.toast.error('Xin kiểm tra lại thông tin giao dịch');
        return;
    }

    this.transactionService.saveTransaction(this.transaction)
      .subscribe(data => {
        this.toast.success('Giao dịch đã được lưu');
        this.modalService.dismissAll();
        this.transactionSaved.emit();
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  resetTransaction() {
    this.transaction = {
      groupId: undefined,
      category: 'in',
      typeId: undefined,
      explain: undefined,
      amount: undefined,
      proof: undefined,
      loading: false,
      spendTime: new Date()
    };
  }
}
