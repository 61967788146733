import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommandService {
    constructor(private httpClient: HttpClient) {
  }

  queryCommands(query) {
    return this.httpClient.get('api/ClientCommand/queryCommands' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  saveCommand(command: any)
  {
    return this.httpClient.put<any>('api/ClientCommand/saveCommand', command);
  }

  createSoldCommandsForAll() {
    return this.httpClient.post<any>('api/ClientCommand/createSoldCommandsForAll',null);
  }

  createSoldCommandForMerchant(merchantId: any) {
    return this.httpClient.post<any>('api/ClientCommand/createSoldCommandForMerchant?merchantId='+merchantId, merchantId);
  }

  createUploadDepopCommand(uploadCommand) {
    return this.httpClient.post<any>('api/ClientCommand/createUploadDepopCommand', uploadCommand);
  }
}
