import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router/router";
import { UserService } from "../../services/user/user.service";
import { Title } from "@angular/platform-browser/platform-browser";
import { Global } from "../../classes/global";
import { NeedAuthorize } from "../../classes/needAuthorize";
import { ReportService } from "../../services/report/report.service";
import * as moment from 'moment';

@Component({
  selector: 'app-personal-daily-report-list',
  templateUrl: './personal-daily-report-list.component.html',
  styleUrls: ['./personal-daily-report-list.component.scss']
})
export class PersonalDailyReportListComponent extends NeedAuthorize {
  query = {
    queryString: '',
    pageSize: 50,
    pageNumber: 1,
  };

  queryResult: any = {
    items: [],
    total: 0
  };

  constructor(protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    private reportService: ReportService,
    private global: Global) {

    super(router, userService, titleService);
    this.queryReports();
  }

  ngOnInit(): void {

  }

  queryReports(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    this.global.pageLoading = true;
    this.reportService.queryDailyReports(this.query).subscribe(data => {

      this.queryResult = data;
      this.global.pageLoading = false;
    });
  }

}
