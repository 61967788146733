import { Component, ViewChild, EventEmitter } from '@angular/core';
import { Global } from "../../classes/global";
import { UserService } from "../../services/user/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { MockupService } from "../../services/mockup/mockup.service";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType, } from "@angular/common/http";
import { map, timeout, catchError } from 'rxjs/operators';

import * as _ from 'lodash';
import { LogTimeService } from "../../services/log-time/log-time.service";

@Component({
  selector: 'app-mockup-list',
  templateUrl: './mockup-list.component.html',
  styleUrls: ['./mockup-list.component.scss']
})
export class MockupListComponent extends NeedAuthorize {
  constructor(protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    public global: Global,
    private activeRoute: ActivatedRoute,
    private mockupService: MockupService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private http: HttpClient,
    private logTimeService: LogTimeService
  ) {
    super(router, userService, titleService);

    this.activeRoute.queryParams.subscribe(params => {
    });

    this.getAvailableDesigners();
    this.queryMockups(1);
  }

  ngOnInit(): void {
  }

  @ViewChild('mockupModal', { static: false })
  private mockupModal: NgbModalRef;

  getImages: EventEmitter<any> = new EventEmitter<any>();
  openConfirmDeleteMockupAction: EventEmitter<any> = new EventEmitter<any>();

  query = {
    designerId: undefined,
    queryString: '',
    quickFilter: undefined,
    createdDate: undefined,
    pageNumber: 1,
    pageSize: 30
  }

  queryResult={
    items: [],
    backlogCount: 0,
    waitingForDesCount: 0,
    waitingApprovalCount: 0,
    total: 0
  };

  designers = [];

  mockupDetail = {
    id: '',
    sku: '',
    merchantGroupId: '',
    name: '',
    description: '',
    thumbnails: '',
    note: '',
    designerId: '',
    destinationProductLink: '',
    destinationProductThumbnail: '',
    progressState: 0,
    availableDesigners: [],
    availableMerchantGroups: [],
    desPoint: 0,
  }
  logs = [];

  desImages = '';
  temptPreviewImages= [];
  timerRunning = false;

  toggleQuickFilter(type) {
    if (type === this.query.quickFilter) {
      this.query.quickFilter = null;
    } else {
      this.query.quickFilter = type;
    }

    this.queryMockups();
  };

  queryMockups(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }

    this.global.pageLoading = true;
    this.mockupService.query(this.query)
      .subscribe(data => {
        this.global.pageLoading = false;
        this.queryResult = data;
      });
  };

  getAvailableDesigners() {
    this.mockupService.getAvailableDesigners().subscribe(data => {
      this.designers = data;
    });
  };

  openCreateMockupModal(mockup) {
    this.resetMockupDetail();
    this.global.pageLoading = true;

    var mockupId = 'newID';
    if (mockup) {
      mockupId = mockup.id;
    }

    this.mockupService.getMockupEdit(mockupId).subscribe(data => {
      this.mockupDetail = data;

      this.modalService.open(this.mockupModal, { windowClass: 'xlModal' });

      if (!this.mockupDetail.merchantGroupId) {
        this.mockupDetail.merchantGroupId = this.mockupDetail.availableMerchantGroups[0].id;
      }

      if (this.mockupDetail.destinationProductThumbnail
        && this.mockupDetail.destinationProductThumbnail.indexOf('tạo link cho file') == -1) {
        var images = this.mockupDetail.destinationProductThumbnail.split('\n');
        this.temptPreviewImages = images;
      }

      this.global.pageLoading = false;
    });

    this.mockupService.getLogs(mockup)
      .subscribe(data => {
        this.logs = data;
      });
  };

  passToDesigner() {
    if (!this.mockupDetail.designerId) {
      return;
    }
    this.mockupDetail.progressState = 1;
    this.saveMockup();
  }

  passToBacklog() {
    this.mockupDetail.progressState = 0;
    this.saveMockup();
  }

  passToApproval() {
    if (!this.mockupDetail.destinationProductLink) {
      this.toastr.error('Thiếu link sản phẩm');
      return;
    }

    if (this.timerRunning) {
      this.toastr.error('Bộ đếm thời gian vẫn đang chạy');
      return;
    }
      
    this.mockupDetail.progressState = 2;
    this.saveMockup();
  }

  passToDone() {
    this.mockupDetail.progressState = 3;
    this.saveMockup();
  }

  saveMockup() {
    if (!this.mockupDetail.sku || !this.mockupDetail.merchantGroupId || !this.mockupDetail.name) {
      this.toastr.error('Mockup thiếu thông tin');
      return;
    }

    this.global.pageLoading = true;
    this.mockupService.saveMockup(this.mockupDetail).subscribe(data => {

      this.global.pageLoading = false;
      this.modalService.dismissAll();
      this.toastr.success('Mockup được lưu');

      this.queryMockups();
    });

  };

  updateSingleValue(mockup, type, value) {
    if (!mockup.id) {
      return;
    }

    if (type == "Sku") {
      mockup.sku = value;
    } else if (type == "Name") {
      mockup.name = value;
    } else if (type == "Description") {
      mockup.description = value;
    } else if (type == "Note") {
      mockup.note = value;
    } else if (type == "Designer") {
      mockup.desingerId = value;
    } else if (type == "ProgressState") {
      mockup.progressState = value;
    } else if (type == "DesPoint") {
      mockup.desPoint = value;
    }

    this.mockupService.updateSingleValue(mockup, type).subscribe(data => {

        for (var i = 0; i < this.queryResult.items.length; i++) {

          var model = _.find(data, o => o.id === this.queryResult.items[i].id);
          if (model == undefined || model.length === 0) {
            continue;
          }
          this.queryResult.items[i] = model;
        }

      },
      error => {
      });
  };

  resetMockupDetail() {
    this.mockupDetail = {
      id: '',
      sku: '',
      merchantGroupId: '',
      name: '',
      description: '',
      thumbnails: '',
      note: '',
      designerId: '',
      progressState: 0,
      availableDesigners: [],
      destinationProductLink: '',
      destinationProductThumbnail: '',
      availableMerchantGroups: [],
      desPoint: 0,
    }
    this.logs = [];
    this.temptPreviewImages = [];
  }

  upload(files) {
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.global.pageLoading = true;

    this.http.options(
        'http://172.16.0.101/api/SaleRecord/UploadDesign'
      )
      .pipe(
        timeout(1500),
        catchError(e => {
          this.uploadToServer('https://havigroupllc.com/api/SaleRecord/UploadDesign/', formData, files);
          return null;
        })
      )
      .subscribe(() => {
        this.uploadToServer('http://172.16.0.101/api/SaleRecord/UploadDesign/', formData, files);
      });
  };

  uploadToServer(url, formData, files: any) {
    this.http.post(url, formData)
      .subscribe((res: any) => {
        this.global.pageLoading = false;
        this.mockupDetail.destinationProductLink = res.destinationProductLinkText;
        this.mockupDetail.destinationProductThumbnail = res.destinationProductLinkText;

        this.temptPreviewImages = [];
        for (var previewIndex = 0; previewIndex < res.previewImages.length; previewIndex++) {
          var previewMess = res.previewImages[previewIndex];
          if (previewMess.indexOf('link cho file') === -1) {
            continue;
          }

          for (var orgFileIndex = 0; orgFileIndex < files.length; orgFileIndex++) {
            var orgFile = files[orgFileIndex];
            var fileName = orgFile.name;
            var fileNameFormPreviewMess = previewMess.split('/')[previewMess.split('/').length - 1];
            if (fileNameFormPreviewMess.toLowerCase() !== fileName.toLowerCase()) {
              continue;
            }
            var reader = new FileReader();

            reader.onload = (event: any) => {
              this.temptPreviewImages.push(event.target.result);
            }

            reader.readAsDataURL(files[orgFileIndex]);
          }
        }

        this.desImages = '';
      },
        error => {
          alert('Có lỗi xảy ra, xin thử lại');
          this.global.pageLoading = false;
          this.desImages = '';
        });
  }

  recalculateDesPoint(event) {
    this.logTimeService.calculateDesPoint(this.mockupDetail.id, 'Design')
      .subscribe(data => {
        this.mockupDetail.desPoint = data;
        this.updateSingleValue(this.mockupDetail, 'DesPoint', data);
      });
  }

  shortLinkForText(text, threshold = 40): string {
    var result = this.shortLinkForTextWithSplitChar(text, threshold, ' ');
    result = this.shortLinkForTextWithSplitChar(result, threshold, '\n');
    return result;
  }

  shortLinkForTextWithSplitChar(text, threshold, splitchar) {
    if (!text) {
      return '';
    }
    const words = text.split(splitchar);
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.startsWith("http") && word.length > threshold) {
        words[i] = word.slice(0, threshold) + "...";
      }
    }

    var result = words.join(splitchar);
    return result;
  }

  assignTimerRunning(event) {
    this.timerRunning = (event == "true");
  }

  deletedMockup;
  confirmDeleteMockup(deletedMockup) {
    this.openConfirmDeleteMockupAction.emit(deletedMockup);
    this.deletedMockup = deletedMockup;
  }

  deleteMockup() {
    this.global.pageLoading = true;

    this.deletedMockup.isDeleted = 1;
    this.mockupService.saveMockup(this.deletedMockup).subscribe(data => {

      this.global.pageLoading = false;
      this.toastr.success('Mockup được xóa');
      this.modalService.dismissAll();
      this.queryMockups();
    });

  }

  
}   
