import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef, Output, ViewChild, } from '@angular/core';
import { Global } from "../../classes/global";
import { SaleRecordService } from "../../services/saleRecord/sale-record.service";
import { UserService } from "../../services/user/user.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-split-order-item',
  templateUrl: './split-order-item.component.html',
  styleUrls: ['./split-order-item.component.scss']
})
export class SplitOrderItemComponent implements OnInit {

  @Input() saleRecord: any;
  @Output() splitOrderDone: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('splitFromQuantity1', { static: false })
  private splitFromQuantity1: NgbModalRef;

  openConfirmAction: EventEmitter<string> = new EventEmitter<string>(); 

  productQuantity: number;
  desiredProductQuantity: number = 1;
  modalResult: any;

  constructor(public global: Global,
    private saleRecordService: SaleRecordService,
    private toastr: ToastrService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.productQuantity = parseInt(this.saleRecord.productQuantity);
  }

  confirmSplitOrder() {
    if (this.productQuantity > 1) {
      this.openConfirmAction.emit();
      return;
    }

    this.modalResult = this.modalService.open(this.splitFromQuantity1);
  }

  splitOrder() {
    this.global.pageLoading = true;
    this.saleRecordService.splitOrder(this.saleRecord)
      .subscribe((data) => {
        this.global.pageLoading = false;
        this.toastr.success('Đơn được tách thành ' + this.saleRecord.productQuantity + ' đơn con');
        this.splitOrderDone.emit('');
      });
  }

  splitOrderFromSingleOrder() {
    this.productQuantity = this.desiredProductQuantity;
    this.saleRecord.productQuantity = this.desiredProductQuantity;
    this.splitOrder();
    this.modalResult.close();
  }

  closeSplitFromQuantity1Modal() {
    this.modalResult.close();
  }
}
