import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MerchantService } from "../../services/merchant/merchant.service";
import { Global } from "../../classes/global";

@Component({
  selector: 'app-sync-order-sheet',
  templateUrl: './sync-order-sheet.component.html',
  styleUrls: ['./sync-order-sheet.component.scss']
})
export class SyncOrderSheetComponent implements OnInit {

  @ViewChild('syncOrderSheetModal', { static: false }) private syncOrderSheetModal: NgbModalRef;
  merchantGroups: any[];
  selectedMerchant: any = {};

  constructor(
    private modalService: NgbModal,
    private toast: ToastrService,
    private merchantService: MerchantService,
    private global: Global) {
  }

  ngOnInit(): void {
    this.merchantService.getTeamMerchantGroups()
      .subscribe(response => {
        this.merchantGroups = response;
        if (this.merchantGroups.length == 1) {
          this.selectedMerchant = this.merchantGroups[0];
        }

      });
  }

  openOrderSheetModal() {
    this.modalService.open(this.syncOrderSheetModal);
  }

  saveSyncOrderSheet() {
    this.merchantService.saveAdminMerchantGroup(this.selectedMerchant)
      .subscribe(response => {
        this.modalService.dismissAll();
        this.toast.success('Thông tin được lưu cho: ' + this.selectedMerchant.name);
      });
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
