<main>
  <div class="container-fluid">
    <h1 class="mt-4">Account Admin</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Account Admin</li>
    </ol>

    <div class="row">
      <div class="col">
        <a class="btn btn-primary" routerLink="/accountDetail/new">Create Account</a>
      </div>
    </div>

    <div class="row">
      <div class="col mt-3">
        <form>
          <input name="find" type="text" class="form-control" placeholder="Find" [(ngModel)]="query.queryString" (ngModelChange)="queryUsers()">
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <table class="table table-bordered table-hover mt-3">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Roles</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let a of accounts">
              <td scope="col">{{a.userName}}</td>
              <td scope="col">
                <strong *ngFor="let r of a.roles">{{r}} </strong>
              </td>
              <td scope="col">
                <a class="btn btn-sm btn-success" [routerLink]="['/accountDetail', a.userId]">
                  <i class="fas fa-edit"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</main>
