import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-des-point-report',
  templateUrl: './des-point-report.component.html',
  styleUrls: ['./des-point-report.component.scss']
})
export class DesPointReportComponent implements OnInit {

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() query: any;
  @Input() genReportEvent: EventEmitter<string>;

  @Output() onGetDataDone: EventEmitter<number> = new EventEmitter<number>();

  reportLoading = false;
  rows = [];

  constructor(private reportService: ReportService) { }

  ngOnInit(): void {

    this.genReportEvent.subscribe(data => {

      var query = JSON.parse(JSON.stringify(this.query));
      query.startDate = moment(query.startDate).format();
      query.endDate = moment(query.endDate).format();
      this.reportLoading = true;
      this.reportService.getDesPointDisReport(query).subscribe((data) => {
        this.reportLoading = false;

        this.rows = data;
        this.onGetDataDone.emit();
      });
    });
  }
}
