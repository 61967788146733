import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Global } from 'src/app/classes/global';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user/user.service';
import { MerchantService } from "../../services/merchant/merchant.service";
import { CommandService } from "../../services/command-service/command.service";

@Component({
  selector: 'app-create-depop-upload-command',
  templateUrl: './create-depop-upload-command.component.html',
  styleUrls: ['./create-depop-upload-command.component.scss']
})
export class CreateDepopUploadCommandComponent implements OnInit {
  

  @ViewChild('createCommandModal', { static: false }) private createCommandModal: NgbModalRef;

  @Input() openCreateCommandModalModalEvent: EventEmitter<string>;
  @Output() commandSaved = new EventEmitter<string>();

  merchants = [];
  merchantId = undefined;

  command ={
    merchantId: undefined,
    dropboxFolder: undefined,
    sampleLink: undefined,
    isManualShip: false
  };

  constructor(private modalService: NgbModal,
    private toast: ToastrService,
    private merchantService: MerchantService,
    private commandService: CommandService,
    private global: Global) {
    this.getMerchants();
  }

  ngOnInit(): void {
    this.openCreateCommandModalModalEvent.subscribe((category) => {
      this.resetCommandForm();
      this.openModal();
    });
  }

  openModal() {
    this.modalService.open(this.createCommandModal);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  getMerchants() {
    this.merchantService.queryMerchants({ merchantType: 5, pageNumber: 1, pageSize: 1000 }).subscribe((data:any) => {
      this.merchants = data.items;
    });
  }

  createUploadDepopCommand() {
    if (!this.command.merchantId || !this.command.dropboxFolder || !this.command.sampleLink) {
      return;
    }

    this.global.pageLoading = true;
    this.commandService.createUploadDepopCommand(this.command).subscribe(data => {
      this.commandSaved.emit('');
      this.toast.success('Lệnh đã được tạo');
      this.closeModal();

      this.global.pageLoading = false;
    });
  }

  resetCommandForm() {
    this.command = {
      merchantId: undefined,
      dropboxFolder: undefined,
      sampleLink: undefined,
      isManualShip: false
    };
  }

  autoDropboxFolder() {
    if (!this.command.merchantId) {
      return;
    }
    var merchant = _.find(this.merchants, i => i.merchantId === this.command.merchantId);
    if (!merchant.note) {
      return;
    }

    var noteLines = merchant.note.split('\n');
    var dropboxNoteLine = _.find(noteLines, i => i.indexOf('Dropbox Folder:') > -1);
    if (dropboxNoteLine) {
      this.command.dropboxFolder = dropboxNoteLine.replace('Dropbox Folder:', '').trim();
    }

    var sampleLink = _.find(noteLines, i => i.indexOf('Sample Link:') > -1);
    if (sampleLink) {
      this.command.sampleLink = sampleLink.replace('Sample Link:',  '').trim();
    }
  }
}
