import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report/report.service';
import { Global } from 'src/app/classes/global';
import { Title } from '@angular/platform-browser';
import { NeedAuthorize } from 'src/app/classes/needAuthorize';
import { UserService } from "../../services/user/user.service";
import { CheckTrademarkService } from "../../services/checkTrademark/check-trademark.service";


@Component({
  selector: 'app-check-trademark',
  templateUrl: './check-trademark.component.html',
  styleUrls: ['./check-trademark.component.scss']
})
export class CheckTrademarkComponent extends NeedAuthorize  {

  target = '';
  results = [];

  constructor(
    protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    private global: Global,
    private reportService: ReportService,
    private checkTrademarkService: CheckTrademarkService
  ) {
    super(router, userService, titleService);
  }

  ngOnInit(): void {
  }

  checkTrademark() {
    this.results = [];
    if (this.target == '') {
      return;
    }

    this.global.pageLoading = true;
    this.checkTrademarkService.checkTrademark(this.target).subscribe(data => {
      this.results = data;
      this.global.pageLoading = false;      
    });
  }
}
