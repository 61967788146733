<div class="fixed-des-container" [hidden]="fixedDesPoint == 0 && !userData.fromSellerToAbove">
  Điểm DES cố định cho SKU:

  <strong [hidden]="userData.fromSellerToAbove">{{fixedDesPoint}}</strong>
  <input class="form-control"
         [(ngModel)]="fixedDesPoint"
         step=".2"
         [hidden]="!userData.fromSellerToAbove"
         type="number"
         (blur)="updateFixedDesPoint(sku, $event)" />
</div>
