<main>
    <div class="container-fluid">
        <h1 class="mt-4">Giao dịch</h1>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Giao dịch</li>
        </ol>

        <div class="row">
            <div class="col-9">
                <div class="form-row">
                    <div class="form-group col-2">
                        <label for="inout">Tiền ra/vào</label>
                        <ng-select [items]="['Tiền ra', 'Tiền vào']" name="target" [(ngModel)]="query.readableCategory"
                            (change)="updateCategory()">
                        </ng-select>
                    </div>
                    <div class="form-group col-2">
                        <label for="groups">Đối tượng chi tiêu</label>
                        <ng-select [items]="merchantGroups" [multiple]="true" class="table-select-filter" bindValue="id"
                            bindLabel="name" [closeOnSelect]="false" [(ngModel)]="query.merchantGroupIds"
                            (change)="queryTransactions()">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="fromDate">Từ ngày</label>
                        <input class="form-control" [owlDateTimeTrigger]="startDate" [owlDateTime]="startDate"
                            [(ngModel)]="query.startDate" name="startDate" (ngModelChange)="queryTransactions()" />
                        <owl-date-time [pickerType]="'calendar'" #startDate name="startDate"></owl-date-time>
                    </div>
                    <div class="form-group col-sm-2">
                        <label for="endDate">Đến ngày</label>
                        <input class="form-control" [owlDateTimeTrigger]="endDate" [owlDateTime]="endDate"
                            [(ngModel)]="query.endDate" name="endDate" (ngModelChange)="queryTransactions()" />
                        <owl-date-time [pickerType]="'calendar'" #endDate name="endDate"></owl-date-time>
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1" name="ignorePod" 
                            [(ngModel)]="query.ignorePod" (change)="queryTransactions()">
                            <label class="form-check-label" for="exampleCheck1">Bỏ qua giao dịch POD</label>
                          </div>
                    </div>                    
                </div>
            </div>
            <div class="col-3">
                <button class="btn btn-primary ml-2 float-right" (click)="openTransactionModal('in')">
                    <i class="fas fa-plus-circle"></i>
                    Thêm khoản thu</button>
                <button class="btn btn-warning ml-2 float-right" (click)="openTransactionModal('out')">
                    <i class="fas fa-minus-circle"></i>
                    Thêm khoản chi</button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="float-right mt-4">
                    <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize"
                        [totalItems]="queryResult.total" (onPageChanged)="queryTransactions($event)">
                    </app-pagination>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                <strong>Tổng tiền ra</strong> = {{queryResult.totalOut | number : '1.0-5'}}
            </div>
            <div class="col-2">
                <strong>Tổng tiền vào</strong> = {{queryResult.totalIn| number : '1.0-5'}}
            </div>
            <div class="col-2">
                <strong>Tổng tiền</strong> = {{queryResult.totalAmount| number : '1.0-5'}}
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover mt-3">
                    <thead>
                        <tr>
                            <th>Thời gian</th>
                            <th>Loại</th>
                            <th>Đối tượng sử dụng</th>
                            <th>Nội dung</th>
                            <th class="text-right">Số tiền</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of queryResult.items"
                            [class.table-warning]="t.transactionType.category == 'out'"
                            [class.table-success]="t.transactionType.category == 'in'"
                            [class.table-danger]="t.isDeleted">
                            <td>
                                {{formatDate(t.spendTime)}}
                            </td>
                            <td>{{t.transactionType.typeName}}</td>
                            <td><span *ngIf="t.merchantGroup">{{t.merchantGroup.name}}</span></td>
                            <td>{{t.explain}}</td>
                            <td class="text-right">{{t.amount | number : '1.0-5' }}</td>
                            <td class="text-center actions"  >
                                <a [href]="t.proof" target="_blank">
                                    <i class="fas fa-file-invoice-dollar"></i>
                                </a>
                                <span [hidden]="userData.roles.indexOf('Team Leader') > -1" class="ml-2" style="color: red;" (click)="toggleDelete(t)">
                                    <i class="fas fa-trash"></i>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="float-right">
            <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize"
                [totalItems]="queryResult.total" (onPageChanged)="queryTransactions($event)">
            </app-pagination>
        </div>
    </div>
</main>

<app-add-transaction [openTransactionModalEvent]="openTransactionModalEvent"
    (transactionSaved)="queryTransactions()"></app-add-transaction>