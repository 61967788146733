<main>
  <div class="container-fluid">
    <h1 class="mt-4">Merchant List</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Merchant List</li>
    </ol>

    <div class="row">
      <div class="col">
        <button class="btn btn-primary" (click)="openMerchantModal('')">Tạo Account bán hàng</button>
      </div>
      <div class="col text-right">

        <app-sync-order-sheet
          *ngIf="this.userData.roles.indexOf('Team Leader') >-1
          || this.userData.roles.indexOf('Admin') > -1
          || this.userData.roles.indexOf('SuperAdmin') > -1">
        </app-sync-order-sheet>

      </div>
    </div>

    <div class="row">
      <div class="col mt-3">
        <form>
          <input name="find" type="text" class="form-control" placeholder="Find" [(ngModel)]="query.queryString"
                 (ngModelChange)="queryMerchants()">
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="amzOnly" [(ngModel)]="query.amzOnly" (ngModelChange)="queryMerchants()">
          <label class="form-check-label" for="amzOnly">
            Amz Only
          </label>
        </div>
      </div>
      <div class="col-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="includeDeleted" [(ngModel)]="query.includeDeleted" (ngModelChange)="queryMerchants()">
          <label class="form-check-label" for="includeDeleted">
            Include Deleted
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-hover mt-3">
          <thead>
            <tr>
              <th>Merchant Id</th>
              <th>Name</th>
              <th>Group</th>
              <th>Main Email</th>
              <th>Sub Email</th>
              <th>VPS</th>
              <th>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let m of queryResult.items" [class.table-danger]="m.isDeleted">
              <td>{{m.merchantId}}</td>
              <td>{{m.name}}</td>
              <td>{{m.merchantGroup.name}}</td>
              <td>{{m.mainEmail}}</td>
              <td>{{m.autoClientEmail}}</td>
              <td>{{m.vpsName}}</td>
              <td>
                <button class="btn btn-primary" (click)="openSetupTiktokModal(m.merchantId)">
                  <i class="fab fa-tiktok"></i>
                </button>
                <button class="btn btn-primary ml-2" (click)="openMerchantModal(m.merchantId)">
                  <i class="fas fa-pen-square"></i>
                </button>
                <button class="btn btn-danger ml-2" (click)="deleteMerchant(m)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="float-right">
      <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="queryResult.total"
                      (onPageChanged)="queryMerchants($event)">
      </app-pagination>
    </div>
  </div>

  <ng-template #merchantModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        <span *ngIf="merchantDetail.merchantId">Edit</span>
        <span *ngIf="!merchantDetail.merchantId">Create New</span>
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="merchantDetail">
        <div class="form-group row">
          <label class="col-2 col-form-label">Merchant ID</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.merchantId" name="merchantId">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label">Name</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.name" name="merchantName">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">Merchant Type</label>
          <div class="col-10">
            <ng-select [items]="merchantTypes"
                       bindValue="id"
                       bindLabel="name"
                       [closeOnSelect]="true"
                       name="merchantType"
                       [(ngModel)]="merchantDetail.merchantType">
            </ng-select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-2 col-form-label">Group</label>
          <div class="col-10">
            <ng-select [items]="merchantGroups"
                       bindValue="id"
                       bindLabel="name"
                       [closeOnSelect]="true"
                       name="merchantGroup"
                       [(ngModel)]="merchantDetail.merchantGroupId">
            </ng-select>
            <!-- <input type="text" class="form-control" [(ngModel)]="merchantDetail.merchantGroup.Id" name="merchantGroup"> -->
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label">Main Email</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.mainEmail" name="mainEmail">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label">Auto Client Email</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.autoClientEmail" name="autoClientEmail">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label">VPS</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.vpsName" name="vps">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label">Bank</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.bank" name="bank">
          </div>
        </div>
        <div class="form-group row" *ngIf="merchantDetail.merchantType == 2">
          <label class="col-2 col-form-label">Access Token</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.tikTokAccessToken" name="accessToken">
          </div>
        </div>
        <div class="form-group row" *ngIf="merchantDetail.merchantType == 2">
          <label class="col-2 col-form-label">Refresh Token</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.tikTokRefreshToken" name="refreshToken">
          </div>
        </div>
        <div class="form-group row" *ngIf="merchantDetail.merchantType == 2">
          <label class="col-2 col-form-label">Cipher Id</label>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="merchantDetail.tikTokCipherId" name="tikTokCipherId">
          </div>
        </div>
        <div class="form-group row">
          <label class="col-2 col-form-label">Note</label>
          <div class="col-10">
            <textarea type="text" class="form-control" [(ngModel)]="merchantDetail.note" name="note">
            </textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveMerchant()">Save</button>
    </div>
  </ng-template>

  <ng-template #setupTiktokModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        Setup Tiktok Api
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form name="merchantDetail">
        <div class="form-group row">
          <div class="col-2">Setup Link</div>
          <div class="col-10">
            <input type="text" class="form-control" [(ngModel)]="setupTiktok.setupLink" name="merchantId">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="setupTiktokApi()">Setup Tiktok Api</button>
    </div>
  </ng-template>
</main>
