import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

const headers = new HttpHeaders({ 'responseType': 'arraybuffer' });

@Injectable({
  providedIn: 'root'
})
export class SaleRecordService {
  updateVariantInfo(orderItemId: any, type: any, value: any) {
    return this.httpClient.put<any>('api/saleRecord/updateVariantInfo?orderItemId=' + orderItemId + '&type=' + type + '&value=' + value, null);
  }
  getSaleRecordLog(record: any) {
    return this.httpClient.get<any>('api/saleRecord/getLog?orderItemId=' + record.orderItemId);
  }

  getFilterCount() {
    return this.httpClient.get<any>('api/saleRecord/getFilterCount/');
  }
  constructor(private httpClient: HttpClient) { }

  query(query) {
    return this.httpClient.get<any>('api/saleRecord/query/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  updateSingleValue(record, type) {
    return this.httpClient.post<any>('api/saleRecord/UpdateSingleValue/?type=' + type, record);
  }

  reorder(record) {
    return this.httpClient.post<any>('api/saleRecord/reorder/', record);
  }

  getDashboardData(query) {
    return this.httpClient.get<any>('api/saleRecord/getDashboardData/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getCustomSaleSummary(query) {
    return this.httpClient.get<any>('api/saleRecord/getCustomSaleSummary/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getDetailSaleReportData(query) {
    return this.httpClient.get<any>('api/saleRecord/getDetailSaleReport/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  downloadRecentDesigns(sourceOrderId) {
    return this.httpClient.get('api/saleRecord/downloadRecentDesigns?sourceOrderId=' + sourceOrderId, { responseType: 'arraybuffer', headers: headers });
  }

    getSellerCheckModel(record) {
    return this.httpClient.get('api/saleRecord/getSellerCheckModel?orderItemId=' + record.orderItemId);
  }

  getUploadDesignModal(record: any) {
    return this.httpClient.get('api/saleRecord/getUploadDesignModel?orderItemId=' + record.orderItemId);
  }

  update(record: any) {
    return this.httpClient.put<any>('api/saleRecord/update/', record);
  }

  updatePrintService(orderItemId: any, wantedPrintService: any) {
    return this.httpClient.get('api/saleRecord/ChangePrintService?orderItemId=' + orderItemId + '&wantedPrintService=' + wantedPrintService);
  }

  ignoreDes(orderItemId: any, forSku: any) {
    return this.httpClient.put<any>('api/saleRecord/ignoreDes?orderItemId=' + orderItemId + '&forSku=' + forSku, {});
  }

  ignoreDesTotally(orderItemId: any) {
    return this.httpClient.put<any>('api/saleRecord/ignoreDesTotally?orderItemId=' + orderItemId, {});
  }

  sendBatchAction(request: any) {
    return this.httpClient.post<any>('api/saleRecord/batchAction/', request);
  }

  checkIfValidToStartDes(record) {
    return this.httpClient.get('api/saleRecord/checkIfValidToStartDes?orderItemId=' + record.orderItemId);
  }

  confirmDesWork(record) {
    return this.httpClient.put('api/saleRecord/confirmDesWork?orderItemId=' + record.orderItemId, {});
  }

  getDesTemplateForSku(sku) {
    return this.httpClient.get('api/saleRecord/getDesTemplateForSku?sku='  + sku);
  }

  saveDesignTemplate(template: any) {
    return this.httpClient.post<any>('api/saleRecord/saveDesignTemplate', template);
  }

  templateForSkuExisted(inputSku: string) {
    return this.httpClient.get('api/saleRecord/templateForSkuExisted?sku=' + inputSku);
  }

  getFixedDesPointForSku(sku: string) {
    return this.httpClient.get<any>('api/saleRecord/getFixedDesPointForSku?sku=' + sku);
  }

  updateFixedDesPoint(sku,fixedDesPoint) {
    return this.httpClient.get<any>('api/saleRecord/updateFixedDesPoint?sku=' + sku + '&fixedDesPoint=' + fixedDesPoint);
  }

  splitOrder(saleRecord) {
    return this.httpClient.put<any>('api/saleRecord/splitOrder', saleRecord);
  }
}
