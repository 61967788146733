import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-income-dis-report',
  templateUrl: './income-dis-report.component.html',
  styleUrls: ['./income-dis-report.component.scss']
})
export class IncomeDisReportComponent implements OnInit {
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  @Input() query: any;
  @Input() genReportEvent: EventEmitter<string>;

  reportLoading = false;
  detailSaleChartPlugins = [pluginAnnotations];
  labels: Label[] = ['Team 1 ', 'Team 2', 'Team 3', 'Team 4','Team 5', 'Team 6', 'Team 7', 'Team 8', 'Team 9', 'Team 10'];

  data: ChartDataSets[] = [
    {
      label: 'Net',
      data: [100,100,100,100,100,100,100,100,100, 100],
    },
  ];

  chartOptions: ChartOptions = {
    animation: {
      duration: 1,
    },
    title: {
      display: true,
      text: 'Phân bổ lợi nhuận'
    }    
  }

  constructor(private reportService:ReportService) { }

  ngOnInit(): void {
    
    this.genReportEvent.subscribe(data => {
      
      var query = JSON.parse(JSON.stringify(this.query));
      query.startDate = moment(query.startDate).format();
      query.endDate = moment(query.endDate).format();
      this.reportLoading = true;
      this.reportService.getIncomeDisReport(query).subscribe((data) => {
        this.labels = _.map(data, i => i.label);
        this.data[0].data  = _.map(data, i=> i.amount);
        this.chart.updateColors();
        this.chart.update();
        this.reportLoading = false;
      })
    });
  }
}
