import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-networth-daily-chart',
  templateUrl: './networth-daily-chart.component.html',
  styleUrls: ['./networth-daily-chart.component.scss']
})
export class NetworthDailyChartComponent implements OnInit {
  @Input() query: any;
  @Input() genReportEvent : EventEmitter<string>;
  
  detailSaleReportLoading = false;

  detailSaleReportChartData: ChartDataSets[] = [
    { label: 'Import', data: [] },
    { label: 'Buy', data: [] },
    { label: 'Net Income', data: [] },
  ];

  lineChartOptions: (ChartOptions) = {
    animation: {
      duration: 1,
      onComplete: function (this) {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        function numberWithCommas(x) {
          var parts = x.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".");
        }

        this.data.datasets.forEach((dataset, i) => {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            var data = dataset.data[index];
            ctx.fillStyle = bar._model.backgroundColor;

            var pointString = '$' + numberWithCommas(data.toFixed(0));
            var orderCount = '';

            if (bar._model.backgroundColor == 'rgba(0, 104, 173, 0.95)') {
              orderCount = numberWithCommas(window['dailyNetChartData'][index].saleSummary.importNetworth.orderCount);
            }
            if (bar._model.backgroundColor == 'rgba(128, 128, 128, 1)') {
              orderCount = numberWithCommas(window['dailyNetChartData'][index].saleSummary.boughtSaleRecordNetworth.orderCount);
            } 

            ctx.fillText(
              pointString,
              bar._model.x,
              bar._model.y - 5);

            ctx.fillText(
              orderCount,
              bar._model.x,
              bar._model.y - 20);
          });
        });
      }
    },
    title: {
      display: true,
      text: 'Tiền ra vào'
    }    
  };

  lineChartLegend: boolean = true;

  detailSaleReportChartLabels: Label[] = [];

  detailSaleChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  
  constructor(private reportService:ReportService) { 
    
  }

  ngOnInit(): void {
    this.genReportEvent.subscribe(data => {
      //Detail Sale Report Data      
      this.detailSaleReportLoading = true;

      var query = JSON.parse(JSON.stringify(this.query));

      query.startDate = moment(query.startDate).format();
      query.endDate = moment(query.endDate).format();

      this.reportService.getNetworthDailyReport(query).subscribe((data) => {

        window['dailyNetChartData'] = data;

        this.detailSaleReportChartLabels = _.map(data, a => a.label);

        this.detailSaleReportChartData[0].data = _.map(data, a => a.saleSummary.importNetworth.orderMoney);
        this.detailSaleReportChartData[1].data = _.map(data, a => a.saleSummary.boughtSaleRecordNetworth.orderMoney);
        this.detailSaleReportChartData[2].data = _.map(data, a => a.saleSummary.netIncome);

        this.chart.updateColors();
        this.chart.update();
        this.detailSaleReportLoading = false;
      })
    });
  }
}