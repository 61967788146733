import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReportService } from 'src/app/services/report/report.service';

@Component({
  selector: 'app-employee-performance-report',
  templateUrl: './employee-performance-report.component.html',
  styleUrls: ['./employee-performance-report.component.scss']
})
export class EmployeePerformanceReportComponent implements OnInit {

  @Input() query: any;
  @Input() genReportEvent: EventEmitter<string>;

  reportLoading = false;

  data=[];

  constructor(private reportService:ReportService) { 
  }

  ngOnInit(): void {
    this.genReportEvent.subscribe(data => {
      //Detail Sale Report Data      
      this.reportLoading = true;

      var query = JSON.parse(JSON.stringify(this.query));

      query.startDate = moment(query.startDate).format();
      query.endDate = moment(query.endDate).format();

      this.reportService.getEmployeePerformanceReport(query).subscribe((data) => {
        this.data = data;

        this.reportLoading = false;
      });
    });
  }

  getTotalSellerCheck() {
    var sellerCheckCount = _.sum(this.data.map(i => i.sellerCheckCount));
    return sellerCheckCount;
  }

  getTotalDesign() {
    var designCount = _.sum(this.data.map(i => i.designCount));
    var reDesignCount = _.sum(this.data.map(i => i.reDesignCount));
    return designCount + '(' + reDesignCount+ ')';
  }

  getTotalFulfill() {
    return _.sum(this.data.map(i => i.fulfillCount));
  }

  getTotalAddTrackAcc() {
    return _.sum(this.data.map(i => i.addTrackCount));
  }

  getTotalFollowTrack() {
    return _.sum(this.data.map(i => i.followTrackCount));
  }

}
