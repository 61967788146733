<div class="time-logger container">
  <div class="row">
    <div class="col-6 actions">
      <button *ngIf="!isRunning"
              class="btn btn-sm btn-primary btn-primary"
              (click)="startTimer()"
              [disabled]="readonly">
        <i class="fas fa-play"></i>
        Bắt Đầu Log Time
      </button>

      <button *ngIf="isRunning"
              class="btn btn-sm btn-danger"
              (click)="stopTimer()"
              [disabled]="readonly">
        <i class="fas fa-stop"></i>
        Dừng {{ formatTime(elapsedTime) }}
      </button>
      
    </div>
    <div class="col-6 total-time">
      Tổng thời gian: {{ formatTime(totalTime) }}
    </div>
  </div>
</div>
