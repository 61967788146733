<button class="btn btn-primary" (click)="openDesTemplateModal()">
  <span *ngIf="!alreadyHaveTemplate">Tạo Design Template</span>
  <span *ngIf="alreadyHaveTemplate">Truy Cập Template</span>
</button>

<ng-template #genDesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Design Template - SKU: {{inputSku}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-8">
        <div class="preview-gen-des">
          <canvas #GenDesPreview width="1063" height="1063" id="myCanvas"></canvas>
        </div>
      </div>

      <div class="col-4">
        <form>
          <div class="row">
            <div class="col gen-des-actions">
              <button class="btn btn-info" (click)="saveTemplate()">Save Template</button>
              <button class="btn btn-info" (click)="saveAsImage()">Gen Des</button>
            </div>
          </div>

          <span class="layers-container">
            <div *ngFor="let layer of template.layers; let i = index" class="layer-class">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label col-form-label-sm">
                  Layer Name
                </label>

                <div class="col-sm-6">
                  <input type="text" class="form-control form-control-sm"
                         name="layerName{{i}}"
                         [(ngModel)]="layer.layerName">
                </div>
                <div class="col-sm-3">
                  <ng-select [items]="layerTypes"
                             [(ngModel)]="layer.layerType"
                             name="layerType{{i}}"
                             bindValue="id" bindLabel="value">
                  </ng-select>
                </div>
              </div>
              <!-- IF IMAGE TYPE -->
              <div *ngIf="layer.layerType == 0">
                <div class="form-group row">
                  <div class="col-12">
                    Options:
                    <button class="btn btn-sm btn-primary" (click)="addLayerOption(layer)">
                      Add Option
                    </button>
                  </div>
                </div>

                <!-- OPTION -->
                <div class="form-group row">
                  <div class="col-12">
                    <div class="container layer-option-container">
                      <div class="row flex-nowrap">
                        <div class="option-thumb-container">
                          <div class="option-thumb card"
                               *ngFor="let layerOption of layer.options; let j = index"
                               [class.isSelected]="layerOption.isSelected">
                            <input type="file" (change)="chooseOptionImageFile(layer, layerOption, $event.target.files)" #file [hidden]="true" />

                            <img class="card-img-top" *ngIf="layerOption.thumbData" src="{{layerOption.thumbData}}"
                                 (click)="selectLayerOption(layer, layerOption)">
                            <img class="card-img-top placeholder" (click)="file.click()"
                                 *ngIf="!layerOption.thumbData" src="https://cdn.pixabay.com/photo/2017/11/10/05/24/add-2935429_960_720.png">
                            <div class="card-body">
                              <input type="text" class="form-control form-control-sm"
                                     name="optionName{{j}}"
                                     [(ngModel)]="layerOption.optionName">
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

              <!-- IF TEXT TYPE -->
              <div *ngIf="layer.layerType == 1">
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label col-form-label-sm">
                    Text
                  </label>

                  <div class="col-sm-6">
                    <input type="text" class="form-control form-control-sm"
                           name="layerText{{i}}"
                           [(ngModel)]="layer.layerText"
                           (change)="updateTextForLayer(layer)">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label col-form-label-sm">
                    Font
                  </label>

                  <div class="col-sm-4">
                    <ng-select [items]="layerFonts"
                               [(ngModel)]="layer.layerFont"
                               name="layerFont{{i}}"
                               (change)="updateTextForLayer(layer)">
                    </ng-select>
                  </div>

                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label col-form-label-sm">
                    Color
                  </label>

                  <div class="col-sm-4">
                    <input type="color" name="fontColor{{i}}" [(ngModel)]="layer.layerTextColor"
                           (change)="updateTextForLayer(layer)">
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label col-form-label-sm">
                    Size
                  </label>

                  <div class="col-sm-10 d-flex align-items-center">
                    <input type="range"
                           class="range-input"
                           [(ngModel)]="layer.layerFontSize"
                           name="layerFontSizes{{i}}" min="0" max="200"
                           (input)="updateTextForLayer(layer)" />
                    <span class="ml-2">{{ layer.layerFontSize }}</span>
                  </div>
                </div>

                <!-- STROKE -->
                <div class="form-group row">
                  <label class="col-sm-2 col-form-label col-form-label-sm">
                    Stroke Color
                  </label>

                  <div class="col-sm-4">
                    <input type="color" name="stokeColor{{i}}" [(ngModel)]="layer.layerStrokeColor"
                           (change)="updateTextForLayer(layer)">
                    {{layer.layerStrokeColor}}
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-2 col-form-label col-form-label-sm">
                    Stroke Size
                  </label>

                  <div class="col-sm-10 d-flex align-items-center">
                    <input type="range"
                           class="range-input"
                           [(ngModel)]="layer.layerStrokeSize"
                           name="stokeSize{{i}}" min="0" max="10"
                           step="0.1"
                           (input)="updateTextForLayer(layer)" />
                    <span class="ml-2">{{ layer.layerStrokeSize }}</span>
                  </div>
                </div>
                <!-- END STROKE -->

              </div>

            </div>

            <button class="btn btn-sm btn-primary" (click)="addLayer()">Add Layer</button>
          </span>
        </form>
      </div>
    </div>
  </div>
</ng-template>

