<main>
  <div class="container-fluid">
    <h1 class="mt-4">Mockups</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Mockups</li>
    </ol>
    <div class="clearfix"></div>
  </div>
  <div class="clearfix"></div>

  <div class="col-12 mb-4 actions">
    <button type="button" class="btn btn btn-primary"
            (click)="openCreateMockupModal('')">
      <i class="far fa-plus-square"></i>
      Tạo mockup
    </button>
  </div>
  <div class="clearfix"></div>

  <div class="col-12 mb-4 actions">
    <div class="float-left">
      <button type="button" class="btn-custom btn-outline-info-custom mr-1"
              (click)="toggleQuickFilter(0)" [class.active]="query.quickFilter == 0">
        <i class="far fa-lightbulb"></i>
        Backlog
        <span class="badge badge-info">{{queryResult.backlogCount}}</span>
      </button>
    </div>
    <button type="button" class="btn-custom btn-outline-warning-custom mr-1"
            (click)="toggleQuickFilter(1)" [class.active]="query.quickFilter == 1">
      <i class="fas fa-paint-roller"></i>
      Chờ Des
      <span class="badge badge-warning">{{queryResult.waitingForDesCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-success-custom mr-1"
            (click)="toggleQuickFilter(2)" [class.active]="query.quickFilter == 2">
      <i class="fas fa-thermometer"></i>
      Chờ Duyệt
      <span class="badge badge-success">{{queryResult.waitingApprovalCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-info-custom mr-1"
            (click)="toggleQuickFilter(3)" [class.active]="query.quickFilter == 3">
      <i class="fas fa-check-square"></i>
      Done
    </button>
  </div>
  <div class="clearfix"></div>

  <div class="col-12">

    <table class="table table-bordered table-hover mockup-table">
      <tr>
        <td class="text-center" width="100px">
          <input class="form-control form-control-sm" [owlDateTimeTrigger]="createdDate" [owlDateTime]="createdDate"
                 [(ngModel)]="query.createdDate" (ngModelChange)="queryMockups()" placeholder="Ngày tạo">
          <owl-date-time [pickerType]="'calendar'" #createdDate></owl-date-time>
        </td>
        <td width="350px" colspan="3">
          <input class="form-control form-control-sm"
                 [(ngModel)]="query.queryString" (ngModelChange)="queryMockups()" placeholder="Tìm kiếm">
        </td>
        <td width="250px">
          <ng-select [items]="designers"
                     bindLabel="userName"
                     bindValue="id"
                     placeholder="Designers"
                     (clear)="queryMockups()"
                     (change)="queryMockups()"
                     [(ngModel)]="query.designerId">
          </ng-select>
        </td>
        <td class="text-center" width="150px"></td>
      </tr>

      <tr>
        <th class="text-center" width="100px">Ngày tạo</th>
        <th width="350px">Sku - Name</th>
        <th class="text-center">Mô tả</th>
        <th>Note</th>
        <th width="150px">Nhóm/Designer</th>
        <th class="text-center" width="150px">Actions</th>
      </tr>
      <tbody>
        <tr *ngFor="let mockup of queryResult.items">
          <td class="text-center">
            {{mockup.createdDateString}}
            <br />
            {{mockup.createdTimeString}}
          </td>
          <td>
            <strong>{{mockup.sku}}</strong>
            <br />
            {{mockup.name}}
          </td>
          <td>{{shortLinkForText(mockup.description)}}</td>
          <td>{{shortLinkForText(mockup.note)}}</td>
          <td>
            <strong>{{mockup.merchantGroupName}}</strong>
            <br />
            {{mockup.designerName}}
            <br />
            Điểm design: <strong>{{mockup.desPoint}}</strong>
            <br />
            <i *ngIf="mockup.currentlyDesign" class="fas fa-play" style="color: #74C0FC;"></i>
          </td>
          <td class="text-center">
            <button class="btn-custom btn-outline-info-custom" (click)="openCreateMockupModal(mockup)"
                    *ngIf="mockup.progressState == 0"
                    title="Backlog">
              <i class="far fa-lightbulb"></i>
              Backlog
            </button>

            <button class="btn-custom btn-outline-warning-custom" (click)="openCreateMockupModal(mockup)"
                    *ngIf="mockup.progressState == 1"
                    title="Chờ Des">
              <i class="fas fa-paint-roller"></i>
              Chờ Des
            </button>

            <button class="btn-custom btn-outline-success-custom" (click)="openCreateMockupModal(mockup)"
                    *ngIf="mockup.progressState == 2"
                    [class.text-red]="mockup.stillGetLink"
                    title="Chờ Duyệt">
              <i class="fas fa-thermometer"></i>
              Chờ Duyệt
            </button>

            <button class="btn-custom btn-outline-info-custom" (click)="openCreateMockupModal(mockup)"
                    *ngIf="mockup.progressState == 3"
                    title="Done">
              <i class="fas fa-check-square"></i>
              Done
            </button>

            <br/>
            <br/>
            <button class="btn-custom btn-outline-danger-custom" (click)="confirmDeleteMockup(mockup)"
                    *ngIf="userData.fromSellerToAbove"
                    title="Xóa">
              <i class="fas fa-trash"></i>
              Xóa
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="queryResult.total"
                    (onPageChanged)="queryMockups($event)">
    </app-pagination>

  </div>
</main>

<ng-template #mockupModal let-modal>
  <div class="mockupModal">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Mockup
      </h4>
    </div>

    <div class="modal-body">
      <form name="mockupForm">
        <div class="row">
          <div class="col-6">
            <div class="form-row">
              <div class="form-group col-6">
                <label class="col-form-label-sm">Sku</label>
                <input type="text" class="form-control form-control-sm" name="sku" [(ngModel)]="mockupDetail.sku"
                       (change)="updateSingleValue(mockupDetail, 'Sku', mockupDetail.sku);" />
              </div>
              <div class="form-group col-6">
                <label class="col-form-label-sm">Nhóm</label>

                <ng-select [items]="mockupDetail.availableMerchantGroups"
                           placeholder="Toàn bộ các team"
                           bindValue="id"
                           bindLabel="name"
                           [(ngModel)]="mockupDetail.merchantGroupId"
                           (change)="updateSingleValue(mockupDetail, 'Group', mockupDetail.merchantGroupId)"
                           name="merchantGroupId">
                </ng-select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12 w-100">
                <label class="col-form-label-sm">Tên</label>
                <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="mockupDetail.name"
                       (change)="updateSingleValue(mockupDetail, 'Name', mockupDetail.name)"/>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12 w-100">
                <label class="col-form-label-sm">Mô tả</label>
                <textarea rows="5" class="form-control form-control-sm" name="description" [(ngModel)]="mockupDetail.description"
                          (change)="updateSingleValue(mockupDetail, 'Description', mockupDetail.description)"></textarea>
              </div>
            </div>

            <div class="form-row">
              <label class="col-form-label-sm">Ảnh demo</label>
              <div class="col-12">
                <app-images-show [images]="mockupDetail.thumbnails"></app-images-show>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12 w-100">
                <label class="col-form-label-sm">Note</label>
                <textarea rows="3" class="form-control form-control-sm" name="note" [(ngModel)]="mockupDetail.note"
                          (change)="updateSingleValue(mockupDetail, 'Note', mockupDetail.note)"></textarea>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12 w-100">
                <label class="col-form-label-sm">Link sản phẩm chọn:</label>
                <textarea rows="3" class="form-control form-control-sm" name="selectDesLink"
                          [(ngModel)]="mockupDetail.destinationProductLink"></textarea>
              </div>
            </div>



            <div class="row mt-36 no-padding-side left-actions">

              <div class="col-4 mt-36" *ngIf="mockupDetail.progressState== 0
                   || mockupDetail.progressState== 1">
                <button class="btn btn-info" (click)="passToBacklog()">
                  <i class="fas fa-save"></i>
                  Chuyển Backlog
                </button>
              </div>

              <div class="col-4"
                   *ngIf="mockupDetail.progressState == 0
                        || mockupDetail.progressState == 2">
                <ng-select [items]="mockupDetail.availableDesigners"
                           bindLabel="userName"
                           bindValue="id"
                           placeholder="Designers"
                           [(ngModel)]="mockupDetail.designerId"
                           name="desId">
                </ng-select>

                <button class="btn btn-warning" (click)="passToDesigner()">
                  <i class="fas fa-paint-roller"></i>
                  Chuyển Designer
                </button>
              </div>

              <div class="col-4 mt-36" *ngIf="mockupDetail.progressState== 1">
                <button class="btn btn-success" (click)="passToApproval()">
                  <i class="fas fa-thermometer"></i>
                  Chuyển sang Chờ Duyệt
                </button>
              </div>

              <div class="col-4 mt-36" *ngIf="mockupDetail.progressState== 2">
                <button class="btn btn-info" (click)="passToDone()">
                  <i class="fas fa-check-square"></i>
                  Chuyển sang Done
                </button>
              </div>

            </div>

            <div class="row no-padding-side">
              <div class="col-12 mt-10 saleRecordLogs">
                <p *ngFor="let log of logs">
                  {{log.createdString}} - <span [innerHTML]="log.logMessage"></span>
                </p>
              </div>
            </div>

          </div>

          <div class="col-6 text-left">
            <app-log-time [targetId]="mockupDetail.id"
                          [type]="'Design'"
                          [readonly]="mockupDetail.progressState != 1"
                          (stopTimerEvent)="recalculateDesPoint($event)"
                          (timerRunningChanged)="assignTimerRunning($event)">
            </app-log-time>
            <br />
            <div class="col-12">
              <div class="col-12">
                Điểm design: <strong>{{mockupDetail.desPoint}}</strong>
              </div>
            </div>

            <hr />
            <div class="uploadDesign" *ngIf="mockupDetail.progressState == 1">
              Upload file design
              <input #uploadDesInput type="file" multiple="true" [(ngModel)]="desImages" (change)="upload($event.target.files)" name="uploadDesInput">
            </div>
            <div class="mt-10" *ngFor="let demoImageLink of temptPreviewImages; let i = index" [hidden]="temptPreviewImages.length == 0">
              <div class="preview-container">
                <img [src]="demoImageLink == null ? global.imgHodlder : demoImageLink"
                     class="img-fluid show-trans-image preview-image" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<app-confirm-action [confirmMessage]="'Bạn có chắc muốn xóa mockup này?'"
                    (action)="deleteMockup()"
                    [openModal]="openConfirmDeleteMockupAction">
