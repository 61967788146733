import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";

@Injectable({
  providedIn: 'root'
})
export class LogTimeService {
  getLogTimeInfo(targetId, type) {
    return this.httpClient.get<any>(`/api/LogTime/GetLogTimeInfo/${type}/${targetId}`);
  }

  startLogTime(targetId, type) {
    return this.httpClient.get<any>(`/api/LogTime/startLogTime/${type}/${targetId}`); 
  }

  stopLogTime(targetId, type) {
    return this.httpClient.get<any>(`/api/LogTime/stopLogTime/${type}/${targetId}`); 
  }

  calculateDesPoint(targetId, type) {
    return this.httpClient.get<any>(`/api/LogTime/calculateDesPoint/${type}/${targetId}`);
  }

  updateDesPointFromManager(targetId, desPoint) {
    return this.httpClient.get<any>(`/api/LogTime/updateDesPointFromManager/${targetId}/${desPoint}`);
  }

  constructor(private httpClient: HttpClient) { }
}
