import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from "./global";
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private global: Global, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = localStorage.getItem('LTV');

    if (token != undefined && request.url.indexOf('172.16.0.101') === -1) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization',
          'Bearer ' + token
        )
      });
    }

    return (next.handle(request).pipe(catchError((error, caught) => {
      //intercept the respons error and displace it to the console
      console.log(error);
      this.handleAuthError(error);
      return of(error);
    }) as any)) as any;
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {

      localStorage.setItem('LTV', undefined);
      localStorage.setItem('UserInfo', undefined);

      this.global.pageLoading = false;

      this.router.navigate(['login']);

      return of(err.message);
    }
    if (err.status === 500 ) {
      this.global.pageLoading = false;
    }
    throw err;
  }
}
