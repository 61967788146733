export class UrlHelper {
  parseToUrlParams(target: any, prefix: string): string {
    var str = "";
    for (var key in target) {
      if (str != "") {
        str += "&";
      }
      str += (prefix + key)+ "=" + encodeURIComponent(target[key]);
    }

    return '?'+str;
  }

}
