<ng-template #transactionModal let-modal>
    <div class="transaction-modal actions-modal">
        <div class="modal-header">
            Thêm khoản thu/chi
        </div>

        <div class="modal-body">
            <form>
                <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">Chi/Thu</label>
                    <div class="col-sm-8">
                        <div class="btn-group" role="group" aria-label="Thu Chi">
                            <button type="button" class="btn btn-lg btn-warning"
                                [class.active]="transaction.category == 'out'"
                                (click)="updateTransactionCategory('out')">
                                Chi
                            </button>
                            <button type="button" class="btn btn-lg btn-primary"
                                [class.active]="transaction.category == 'in'" (click)="updateTransactionCategory('in')">
                                Thu
                            </button>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Ngày giao dịch</label>
                    <div class="col-sm-8">
                        <input class="form-control" [owlDateTimeTrigger]="spendTime"
                            [owlDateTime]="spendTime" [(ngModel)]="transaction.spendTime" name="spendTimeInput"/>
                        <owl-date-time [pickerType]="'calendar'" #spendTime name="spendTime"></owl-date-time>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Đối tượng</label>
                    <div class="col-sm-8">
                        <ng-select [items]="merchantGroups" bindValue="id" bindLabel="name" name="target"
                            [(ngModel)]="transaction.groupId">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Khoản</label>
                    <div class="col-sm-8">
                        <ng-select [items]="transactionTypes" bindValue="id" bindLabel="typeName" name="target"
                            [(ngModel)]="transaction.typeId" [addTag]="true" (change)="addTransactionType($event)">
                        </ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Nội dung</label>
                    <div class="col-sm-8">
                        <input class="form-control" name="explain" [(ngModel)]="transaction.explain" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Số tiền</label>
                    <div class="col-sm-8">
                        <app-all-to-usd [(des)]="transaction.amount"></app-all-to-usd>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label">Chứng từ</label>
                    <div class="col-sm-8">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" name="proof" [(ngModel)]="transaction.proof" />
                            <div class="input-group-append">
                                <label for="file-upload" class="custom-file-upload">
                                    <i class="fas fa-upload"></i>
                                </label>
                                <input id="file-upload" type="file" (change)="uploadProofFile($event.target.files)" />
                            </div>
                            <span [hidden]="!transaction.loading">
                                <i class="fas fa-spinner fa-spin"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="transaction.proof">
                    <div class="col-sm-8 offset-4 proof-img">
                        <a [href]="transaction.proof" target="_blank">
                            <img [src]="transaction.proof" [hidden]="!(transaction.proof.indexOf('.png') > -1
                                                                    || transaction.proof.indexOf('.jpg') > -1
                                                                    || transaction.proof.indexOf('.jpeg') > -1)" />
                            <br />
                            Xem trước
                        </a>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveTransaction()">Tạo Giao Dịch</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Cancel</button>
        </div>
    </div>
</ng-template>