<main>
    <div class="container-fluid">
        <h1 class="mt-4">Dashboard</h1>
        <ol class="breadcrumb mb-4">
            <li class="breadcrumb-item active">Dashboard</li>
        </ol>
        <div class="col-12">
            <div>
                <div class="row">
                    <div class="col-3 row-eq-height">
                        <input class="form-control form-control-sm" [owlDateTimeTrigger]="startDate"
                            [owlDateTime]="startDate" [(ngModel)]="query.startDate"
                            placeholder="Start Date" />
                        <owl-date-time [pickerType]="'calendar'" #startDate></owl-date-time>
                    </div>

                    <div class="col-3 row-eq-height">
                        <input class="form-control form-control-sm" [owlDateTimeTrigger]="endDate"
                            [owlDateTime]="endDate" [(ngModel)]="query.endDate"
                            placeholder="End Date" />
                        <owl-date-time [pickerType]="'calendar'" #endDate></owl-date-time>
                    </div>

                    <div class="col-3 row-eq-height">
                        <ng-select [items]="merchantGroups" [multiple]="true" class="table-select-filter"
                            placeholder="Toàn bộ các team" bindValue="id" bindLabel="name" [closeOnSelect]="false"
                            [(ngModel)]="query.merchantGroupIds">
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                    <div class="col-3">
                        <button class="btn btn-primary" (click)="genReports()">Tạo báo cáo</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 row-eq-height">
                    <app-networth-daily-chart 
                    [query]="query"
                    [genReportEvent]="genReportEvent"></app-networth-daily-chart>
                </div>
                <div class="col-6 row-eq-height">
                    <app-summary-report
                    [query]="query"
                    [genReportEvent]="genReportEvent"></app-summary-report>
                </div>
            </div>

            <div class="row">
              <div class="col-6 row-eq-height">
                <app-income-dis-report
                  [query]="query"
                  [genReportEvent]="genReportEvent"></app-income-dis-report>
              </div>
              <div class="col-6 row-eq-height">
                <app-acc-dis-report
                  [query]="query"
                  [genReportEvent]="genReportEvent"></app-acc-dis-report>
              </div>
            </div>

            <div class="row">
              <div class="col-6 offset-3">
                <app-des-point-report [query]="query"
                                      [genReportEvent]="genReportEvent"
                                      (onGetDataDone)="onGetDataDone($event)"></app-des-point-report>
              </div>
            </div>

            <div class="row">
              <div class="col-12 mt-4">
                <app-finances-report
                  [query]="query"
                  [genReportEvent]="genReportEvent"></app-finances-report>
              </div>
            </div>

            <div class="row">
                <div class="col-12 mt-4">
                    <app-employee-performance-report
                    [query]="query"
                    [genReportEvent]="genPerformanceReportEvent"></app-employee-performance-report>
                </div>
            </div>
        </div>
    </div>
</main>
