import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
    constructor(private httpClient: HttpClient) {
  }

  getMerchant(merchantId: any) {
    return this.httpClient.get<any>('api/Merchant/getMerchant?merchantId='+ merchantId);
  }

  getGrantedMerchants(merchantId) {
    return this.httpClient.get<any>('api/Merchant/getGrantedMerchants?merchantGroupIds='+ merchantId);
  }

  getGrantedMerchantGroups() {
    return this.httpClient.get<any>('api/Merchant/getGrantedMerchantGroups');
  }

  queryMerchants(query) {
    return this.httpClient.get('api/Merchant/queryMerchants' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  saveMerchant(merchant: any)
  {
    return this.httpClient.post<any>('api/Merchant/saveMerchant', merchant);
  }

  setupTiktokApi(setupTiktokModel: any) {
    return this.httpClient.post<any>('api/Merchant/setupTiktokApi', setupTiktokModel);
  }

  getTeamMerchantGroups() {
    return this.httpClient.get<any>('api/Merchant/getTeamMerchantGroups');
  }

  saveAdminMerchantGroup(selectedMerchant) {
    return this.httpClient.post<any>('api/Merchant/saveAdminMerchantGroup', selectedMerchant);
  }
}
