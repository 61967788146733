<ng-template #confirmActionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Xác nhận</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{confirmMessage}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Hủy bỏ</button>
    <button type="button" class="btn btn-light btn-danger" (click)="invokeAction()">Ok</button>
  </div>
</ng-template>
