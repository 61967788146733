<div class="card bg-primary text-white mt-4" *ngIf="!reportLoading && data.importNetworth != undefined">
    <div class="card-body">Báo cáo chi tiết</div>
    <div class="card-footer d-flex align-items-center justify-content-between small text-white stretched-link">
      <table class="sale-summary-table">
        <tr>
          <td>Imported</td>
          <td class="text-right"> {{data?.importNetworth?.orderCount}} units - ${{data?.importNetworth?.orderMoney}}</td>
        </tr>
        <tr>
          <td>Refunds</td>
          <td class="text-right">{{data?.otherRefundNetworth?.orderCount}} units - ${{data?.otherRefundNetworth?.orderMoney}}</td>
        </tr>
        <tr>
          <td>Buy</td>
          <td class="text-right">{{data?.boughtSaleRecordNetworth?.orderCount}} units - ${{data?.boughtSaleRecordNetworth?.orderMoney}}</td>
        </tr>
        <tr>
          <td>Net Income</td>
          <td class="text-right">${{data?.netIncomeString}}</td>
        </tr>
      </table>
    </div>
  </div>
  <app-report-loading *ngIf="reportLoading"></app-report-loading>