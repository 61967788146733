
<h3 style="margin-top: 20px; text-align:center; width: 100%">Điểm des</h3>

<table class="table table-bordered table-sm" [hidden]="reportLoading">
  <tr class="table-active">
    <th>Seller</th>
    <th class="text-right group-name-row">
      Điểm Des
    </th>
  </tr>

  <tr *ngFor="let row of rows">
    <td>{{row.label}}</td>
    <td class="text-right">
      {{row.amount}}
      </td>
  </tr>
</table>

<app-report-loading *ngIf="reportLoading"></app-report-loading>
