import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";

const headers = new HttpHeaders({ 'responseType': 'arraybuffer' });

@Injectable({
  providedIn: 'root'
})
export class FulfillService {
  getImageSize(parts: string) {
    var arrayParts = parts.split('###');
    return this.httpClient.post<any>('/api/Fulfill/GetImageSizes/', { shareLinks: arrayParts });
  }

  getAvailablePrintConfigs() {
    return this.httpClient.get<any>('/api/Fulfill/getAvailablePrintConfigs');
  }
  constructor(private httpClient: HttpClient) { }

  getPrintifyFulfillConfigs() {
    return this.httpClient.get<any>('/api/Fulfill/GetPrintifyFulfillConfigs');
  }

  downloadExportFileForPrintify(exportPrintify: any, saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',',';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key])
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForPrintify?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  buyMultipleRecordsByPrintifyApi(saleRecords: any[]) {
    var params = this.selectedSaleRecordsToUrlParams(saleRecords);
    return this.httpClient.post(
      '/api/Fulfill/SendOrderPrintifyApi?' + params.toString(),
      {});
  }

  buyMultipleRecordsMerchizeApi(saleRecords: any[]) {
    var params = this.selectedSaleRecordsToUrlParams(saleRecords);
    return this.httpClient.post(
      '/api/Fulfill/SendOrderToMerchizeApi?' + params.toString(),
      {});
  }

  sendOrderBestPrintCareApi(saleRecords: any[]) {
    var params = this.selectedSaleRecordsToUrlParams(saleRecords);
    return this.httpClient.post(
      '/api/Fulfill/sendOrderBestPrintCareApi?' + params.toString(),
      {});
  }

  selectedSaleRecordsToUrlParams(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',', ';'));
      }
    }
    var exportModel = { selectedOrderItemIds: [] };
    exportModel.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportModel) {
      params.set(key, exportModel[key]);
    }
    return params;
  }

  downloadExportFileForFlashship(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = {selectedOrderItemIds: []};
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',',';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key])
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForFlashship?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  downloadExportFileForBestPrintCare(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = { selectedOrderItemIds: [] };
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',', ';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key])
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForBestPrintCare?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  downloadExportFileForMerchize(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = {selectedOrderItemIds: []};
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',',';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key])
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForMerchize?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  downloadExportFileForGeargag(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = { selectedOrderItemIds: [] };
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',', ';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key])
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForGeargag?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  downloadExportFileForPrintWay(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = { selectedOrderItemIds: [] };
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',', ';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key]);
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForPrintWay?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  getBlueprints() {
    return this.httpClient.get<any>('/api/Fulfill/GetPrintifyBluePrints');
  }

  getPrintifyProviders() {
    return this.httpClient.get<any>('/api/Fulfill/getPrintifyProviders');
  }

  downloadExportFileForArtsAdd(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = { selectedOrderItemIds: [] };
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',', ';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key]);
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForArtsAdd?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );

  }

  downloadExportFileForGearment(saleRecords: any[]) {
    var selectedSaleRecordIds = [];
    var exportPrintify = { selectedOrderItemIds: [] };
    for (var i = 0; i < saleRecords.length; i++) {
      if (saleRecords[i].isSelected == true) {
        selectedSaleRecordIds.push(saleRecords[i].orderItemId.replace(',', ';'));
      }
    }
    exportPrintify.selectedOrderItemIds = selectedSaleRecordIds;
    let params = new URLSearchParams();
    for (let key in exportPrintify) {
      params.set(key, exportPrintify[key]);
    }

    return this.httpClient.get(
      '/api/Fulfill/downloadExportFileForGearment?' + params.toString(),
      { responseType: 'arraybuffer', headers: headers }
    );
  }

  sendOrderPrintifyApi(record):any {
    return this.httpClient.post('api/Fulfill/sendOrderPrintifyApi?providerId=&productId=&selectedOrderItemIds=' + record.orderItemId, {});
  }

  sendOrderFlashshipApi(saleRecords: any[]) {
    var params = this.selectedSaleRecordsToUrlParams(saleRecords);
    return this.httpClient.post(
      '/api/Fulfill/sendOrderFlashshipApi?' + params.toString(),
      {});
  }

  sendOrderPrintwayApi(saleRecords: any[]) {
    var params = this.selectedSaleRecordsToUrlParams(saleRecords);
    return this.httpClient.post(
      '/api/Fulfill/sendOrderPrintwayApi?' + params.toString(),
      {});
  }

  addColor(newColor) {
    return this.httpClient.post(
      '/api/Fulfill/addColor?newColor=' + newColor,
      {});
  }

  addSize(newSize) {
    return this.httpClient.post(
      '/api/Fulfill/addSize?newSize=' + newSize,
      {});
  }
}
