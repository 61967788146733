import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/services/report/report.service';
import { Global } from 'src/app/classes/global';
import { Title } from '@angular/platform-browser';
import { NeedAuthorize } from 'src/app/classes/needAuthorize';
import { UserService } from "../../services/user/user.service";

@Component({
  selector: 'app-asin-report',
  templateUrl: './asin-report.component.html',
  styleUrls: ['./asin-report.component.scss']
})
export class AsinReportComponent extends NeedAuthorize {

  constructor(
    protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    private global: Global,
    private reportService: ReportService,    
  ) {
    super(router, userService, titleService);
    this.changeTab('Amazon')
  }
  public userData: any;

  currentTab = ''
  query = {
    startDateString: '',
    endDateString: '',
    market: '',
    pageSize: 20,
    pageNumber: 1,
    isCheckout: false
  }

  totalItem = 0;

  records = [];

  changeTab(currentTab: string) {
    this.resetQuery();
    this.currentTab = currentTab;
    this.query.market = currentTab;
    this.queryAsinReport();
  }

  ngOnInit(): void {
  }

  resetQuery() {
    this.query = {
      startDateString: '',
      endDateString: '',
      market: '',
      pageSize: 20,
      pageNumber: 1,
      isCheckout: false
    };
  }

  queryAsinReport(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    this.global.pageLoading = true;
    this.reportService.getAsinPerformanceReport(this.query).subscribe(data => {
      this.records = data.items;
      this.totalItem = data.total;
      this.global.pageLoading = false;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
  }

  changeToMediate() {
    this.queryAsinReport();

  }
}
