import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from 'src/app/services/report/report.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-all-to-usd',
  templateUrl: './all-to-usd.component.html',
  styleUrls: ['./all-to-usd.component.scss']
})
export class AllToUsdComponent implements OnInit {
  @Input() des = undefined;
  @Output() desChange = new EventEmitter<number>();
  
  @Input() disabled = false;

  currencyRates = [];
  sourceAmount = undefined;
  symbol = "VND";
  rate = undefined;

  constructor(private reportService: ReportService) { }

  ngOnInit(): void {
    this.getCurrencies();
  }

  getCurrencies() {
    this.reportService.getCurrencies().subscribe(data => {
      data.push({ symbol: "GIFT", amount: 16.0, group: "Hay dùng" });
      this.currencyRates = data;
      this.rate = this.roundCurrency(_.find(data, i => i.symbol == "VND").amount);
    });
  }

  updateDes() {
    if (!this.sourceAmount
      || !this.symbol
      || !this.rate) {
      return;
    }

    if (this.symbol == "GIFT") {
      var vndRate = _.find(this.currencyRates, i => i.symbol == "VND");
      var vndAmount = this.sourceAmount * this.rate * 1000;
      this.des = this.roundCurrency(vndAmount / vndRate.amount);
    }
    else {
      this.des = this.roundCurrency(this.sourceAmount / this.rate);  
    }

    this.desChange.emit(this.des);
  }

  updateSymbol() {
    var rateConfig = _.find(this.currencyRates, i => i.symbol == this.symbol);
    if (!rateConfig) {
      return;
    }
    this.rate = this.roundCurrency(rateConfig.amount);
    this.updateDes();
  }

  roundCurrency(source) {
    return source.toFixed(5);
  }

}
