import { Component, OnInit, ViewChild } from '@angular/core';
import { MerchantService } from "../../services/merchant/merchant.service";
import { Global } from "../../classes/global";
import { AccountMessageService } from "../../services/accountMessage/account-message.service";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-account-message',
  templateUrl: './account-message.component.html',
  styleUrls: ['./account-message.component.scss']
})
export class AccountMessageComponent implements OnInit {

  constructor(private merchantService: MerchantService,
    private global: Global,
    private accountMessageService: AccountMessageService,
    private modalService: NgbModal) {
    this.getMerchants();
    this.queryAccountMessages();
  }

  query: any = {
    merchantId: '',
    pageSize: 30,
    pageNumber: 1,
    sortType: 'Desc',
  };

  totalItem = 0;
  merchants = [];
  accountMessages = [];

  selectedMessage = {
    message: ''
  };

  @ViewChild('fullMessageModal', { static: false }) private fullMessageModal: NgbModalRef;

  ngOnInit(): void {
  }

  queryAccountMessages(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    this.global.pageLoading = true;
    this.accountMessageService.query(this.query).subscribe(data => {

      this.accountMessages = data.items;
      this.totalItem = data.total;

      this.global.pageLoading = false;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
  }

  openFullMessage(message) {
    this.selectedMessage = message;
    this.modalService.open(this.fullMessageModal, {size: 'xl'});
  }

  getMerchants() {
    this.merchantService.getGrantedMerchants('').subscribe(data => {
      this.merchants = data;
    });
  }

  toggleHandled(message) {
    message.isHandled = !message.isHandled;
    this.accountMessageService.toggleHandled(message).subscribe(data => {});
  }

}
