<main>
  <div class="container-fluid">
    <h1 class="mt-4">Team Leader duyệt báo cáo ngày</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Team Leader duyệt báo cáo ngày</li>
    </ol>

    <div class="row mt-3">
      <div class="col-12">
        <div class="float-right">
          <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="queryResult.total"
                          (onPageChanged)="queryReports($event)">
          </app-pagination>
        </div>
      </div>
    </div>

    <div class="col-12">
      <table class="table table-bordered table-hover mt-3">
        <thead>
          <tr>
            <th>Ngày</th>
            <th>Tên Nhân viên</th>
            <th>Team</th>
            <th>Trạng thái</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of queryResult.items">
            <td>{{item.reportDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{item.teamName}}</td>
            <td>{{item.username}}</td>
            <td>
              <span *ngIf="item.state == 0">
                <i class="fas fa-retweet" style="color: #4b6eaf;"></i>
                Chờ gửi lại
              </span>
              <span *ngIf="item.state == 1">
                <i class="fas fa-pause-circle" style="color: #eae562;"></i>
                Chờ duyệt
              </span>
              <span *ngIf="item.state == 2">
                <i class="fas fa-check-circle" style="color: #2be337;"></i>
                Đã duyệt
              </span>
            </td>
            <td class="text-center">
              <a [href]="'/personalDailyReport/' + item.id" target="_blank">Xem</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="float-right">
        <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="queryResult.total"
                        (onPageChanged)="queryReports($event)">
        </app-pagination>
      </div>
    </div>

  </div>
</main>
