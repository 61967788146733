<div class="container-fluid">
  <div class="row">

    <div class="col-4">
      <input class="form-control form-control-sm" [owlDateTimeTrigger]="startDate" [owlDateTime]="startDate" [(ngModel)]="query.startDate" (ngModelChange)="queryRedBubbleSaleSummary()" placeholder="Start Date" />
      <owl-date-time [pickerType]="'calendar'" #startDate></owl-date-time>
    </div>

    <div class="col-4">
      <input class="form-control form-control-sm" [owlDateTimeTrigger]="endDate" [owlDateTime]="endDate" [(ngModel)]="query.endDate" (ngModelChange)="queryRedBubbleSaleSummary()" placeholder="End Date" />
      <owl-date-time [pickerType]="'calendar'" #endDate></owl-date-time>
    </div>
  </div>

  <div class="row mt-1">
    <div class="col-xl-3 col-md-3">
      <div class="card bg-light text-info mt-4 mb-2 sale-summary">
        <div class="card-body">
          Redbuble
        </div>
        <div class="card-footer d-flex align-items-center justify-content-between small text-info stretched-link">
          <table class="sale-summary-table" *ngIf="rBdataModels != undefined">
            <tr>
              <td>This month</td>
              <td class="text-right">{{rBdataModels[2].sale}} units - ${{rBdataModels[2].margin}}</td>
            </tr>
            <tr>
              <td>Yesterday</td>
              <td class="text-right">{{rBdataModels[1].sale}} units - ${{rBdataModels[1].margin}}</td>
            </tr>
            <tr>
              <td>Today</td>
              <td class="text-right">{{rBdataModels[0].sale}} units - ${{rBdataModels[0].margin}}</td>
            </tr>
            <tr *ngIf="rBdataModels.length >= 4">
              <td>Custom</td>
              <td class="text-right">{{rBdataModels[3].sale}} units - ${{rBdataModels[3].margin}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

