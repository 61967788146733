import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { SiteLayoutComponent } from "./components/site-layout/site-layout.component";
import { AuthLayoutComponent } from "./components/auth-layout/auth-layout.component";
import { LoginComponent } from "./components/login/login.component";
import { OrdersComponent } from "./components/orders/orders.component";
import { AccountAdminComponent } from "./components/account-admin/account-admin.component";
import { AccountDetailComponent } from "./components/account-detail/account-detail.component";
import { AccountMessageComponent } from "./components/account-message/account-message.component";
import { RedBubbleOrderComponent } from "./components/red-bubble-order/red-bubble-order.component";
import { AsinReportComponent } from './components/asin-report/asin-report.component';
import { CheckTrademarkComponent } from './components/check-trademark/check-trademark.component';
import { MerchantListComponent } from './components/merchant-list/merchant-list.component';
import { AutoCommandsComponent } from './components/auto-commands/auto-commands.component';
import { Dashboardv2Component } from './components/dashboardv2/dashboardv2.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { PersonalDailyReportComponent } from "./components/personal-daily-report/personal-daily-report.component";
import { PersonalDailyReportListComponent } from "./components/personal-daily-report-list/personal-daily-report-list.component";
import { GenDesComponent } from "./components/gen-des/gen-des.component";
import { MockupListComponent } from "./components/mockup-list/mockup-list.component";

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent }
    ]
  },
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboardv2', component: Dashboardv2Component },
      { path: 'orders', component: OrdersComponent, pathMatch: 'prefix' },
      { path: 'accountAdmin', component: AccountAdminComponent },
      { path: 'accountDetail/:accountId', component:  AccountDetailComponent},
      { path: 'accountMessage', component:  AccountMessageComponent}, 
      { path: 'rbOrder', component: RedBubbleOrderComponent },
      { path: 'transactions', component: TransactionsComponent },
      { path: 'merchants', component: MerchantListComponent },
      { path: 'asinReport', component: AsinReportComponent },
      { path: 'checkTrademark', component: CheckTrademarkComponent },
      { path: 'autoCommands', component: AutoCommandsComponent },
      { path: 'personalDailyReport/:reportId', component: PersonalDailyReportComponent },
      { path: 'personalDailyReportList', component: PersonalDailyReportListComponent },
      { path: 'mockupList', component: MockupListComponent }
    ]
  }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
