import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TransactionService as TransactionService } from 'src/app/services/transaction-service/transaction-service.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { SaleRecordService } from "../../services/saleRecord/sale-record.service";
import { Global } from "../../classes/global";

@Component({
  selector: 'app-sale-record-batch-action',
  templateUrl: './sale-record-batch-action.component.html',
  styleUrls: ['./sale-record-batch-action.component.scss']
})
export class SaleRecordBatchActionComponent implements OnInit {
  @ViewChild('batchActionModal', { static: false }) private batchActionModal: NgbModalRef;
  @Input() saleRecords: any[];
  @Output() batchActionDone = new EventEmitter<string>();

  actionTypes = [
    { label: 'Chuyển trạng thái', value: 0 }
  ];
  selectedActionType: any;

  progressStates = [
    { label: 'Cần Seller Check', value: 0 },
    { label: 'Cần Xác Minh Thêm', value: 5 },
    { label: 'Cần Design', value: 10 },
    { label: 'Cần Fulfill', value: 20 },
    { label: 'Đợi Tracking', value: 30 },
    { label: 'Cần Trả Track TK', value: 35 },
    { label: 'Đang Theo Dõi Tracking', value: 40 },
    { label: 'Done', value: 50 }
  ];
  selectedState: any;

  selectedSaleRecordCount = 0;
  selectedRecords: any[];

  constructor(
    private transactionService: TransactionService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private saleRecordService: SaleRecordService,
    public global: Global) { }

  ngOnInit(): void {
  }

  openBatchActionModal() {
    
    this.selectedActionType = this.actionTypes[0];
    this.selectedState = undefined;
    this.getSelectedRecords();
    
    this.modalService.open(this.batchActionModal, { windowClass: "lModal" });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  processBatchAction() {
    if (this.selectedActionType.value == 0) {
      this.changeStatus();
      return;
    }
  }

  getSelectedRecords(): any {
    this.selectedRecords = [];
    for (var i = 0; i < this.saleRecords.length; i++) {
      if (this.saleRecords[i].isSelected === true) {
        this.selectedRecords.push(this.saleRecords[i]);
      }
    }
  }

  changeStatus() {
    if (this.selectedRecords.length == 0 || this.selectedState == undefined) {
      this.toast.error("Thiếu thông tin, xin kiểm tra lại");
      return;
    }
    this.sendBatchAction();
  }

  sendBatchAction() {
    var selectedSaleRecordIds = [];
    for (var i = 0; i < this.selectedRecords.length; i++) {
      selectedSaleRecordIds.push(this.selectedRecords[i].orderItemId.replace(',', ';'));
    }

    var request = {
      selectedSaleRecordIds: selectedSaleRecordIds,
      actionId: this.selectedActionType.value,
      saleRecordState: this.selectedState.value
    };
    this.global.pageLoading = true;
    this.saleRecordService.sendBatchAction(request)
      .subscribe(
        (data) => {
          this.toast.success(data);
          this.batchActionDone.emit("done");
          this.closeModal();
          this.global.pageLoading = false;
        },
        (data) => {
          this.toast.error("Có lỗi xảy ra");
          this.global.pageLoading = false;
        }
      );
  }
}
