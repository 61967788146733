<button class="btn btn-primary" (click)="openOrderSheetModal()">
  Lưu thông tin sheet gom đơn
</button>

<ng-template #syncOrderSheetModal let-modal>
  <div class="#syncOrderSheetModal-modal actions-modal">
    <div class="modal-header">
      Lưu thông tin sheet gom đơn
    </div>

    <div class="modal-body">
      <ng-select [items]="merchantGroups"
                 placeholder="Toàn bộ các team"
                 bindLabel="name"
                 [(ngModel)]="selectedMerchant">
      </ng-select>

      <div class="form-group">
        <label>Link sheet gom đơn</label>
        <input class="form-control"
               [(ngModel)]="selectedMerchant.orderSheetId" />
      </div>

    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="saveSyncOrderSheet()">Lưu</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Hủy bỏ</button>
    </div>
  </div>
</ng-template>
