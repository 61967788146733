///<reference types="chrome"/>
//// Remove chrome import
import { AfterViewInit, Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { Global } from "../../classes/global";
import { UserService } from "../../services/user/user.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { SaleRecordService } from "../../services/saleRecord/sale-record.service";
import { MerchantService } from "../../services/merchant/merchant.service";
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef } from '@angular/core';

import * as _ from 'lodash';
import { FulfillService } from '../../services/fulfill/fulfill.service';
import { ViewEncapsulation } from '@angular/core';
import { map, timeout, catchError } from 'rxjs/operators';

import {
  HttpClient,
  HttpEventType,
} from "@angular/common/http";
import { LogTimeService } from "../../services/log-time/log-time.service";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrdersComponent extends NeedAuthorize {
  queryType: number = 1;
  availablePrintConfig: any;
  blueprints= [];
  saleRecordTypes= ['POD', 'Physical'];
  query: any = {
    purchaseDate: '',
    shipDueDate: '',
    sourceOrderId: '',
    customerName: '',
    asin: '',
    sku: '',
    destinationOrderId: '',
    trackingNumber: '',
    note: '',
    merchantIds: '',
    merchantGroupIds: '',
    wantedPrintService: '',
    pageSize: 30,
    pageNumber: 1,
    sortBy: 'CreatedTime',
    sortType: 'Desc',
    saleRecordType: undefined
  };

  sellerCheck: any = {
    sourceImage: '',
    suggestProducts: [],
    destinationProductLinkText: "",
    downloadLink: [],
    availableDesigners: [],
    selectedDesigner: null,
  };

  saleRecords = [];
  totalItem = 0;
  merchants = [];
  depopMerchants = [];
  tiktokMerchants = [];
  etsyMerchants = [];
  merchantGroups = [];
  temptPreviewImages = [];
  uploadToMerchantGroupId = '';
  uploadToMerchantId = '';
  checkAll = false;
  sinceOrderId = '';

  printifyExport: any = {
    providerId: '',
    productId: ''
  }
  printifyProviders = [];
  printifyProducts = [];
  saleRecordLogs: any = [];
  quickFilters = [
    'isNeedFindLink',
    'isNeedSellerCheck',
    'isNeedFulfill',
    'isNeedShip',
    'isNeedAddTrackToAccount',
    'isFollowTrack',
    'isDone',
    'isNoticed',
    'isWarningLateTrack',
    'isWarningLateDelivery'
  ];

  productLinkSaleRecord: any;
  deletedSaleRecord: any = {
    productName: ''
  };

  uploadImportFileProgress = 1;
  importMessages = [];

  progressStates = [
    { name: 'Cần Seller Check', value: 0 },
    { name: 'Cần Xác Minh Thêm', value: 5 },
    { name: 'Cần Design', value: 10 },
    { name: 'Cần Fulfill', value: 20 },
    { name: 'Đợi Tracking', value: 30 },
    { name: 'Cần Trả Track TK', value: 35 },
    { name: 'Đang Theo Dõi Tracking', value: 40 },
    { name: 'Done', value: 50 },
  ];

  filterCount: any = {
  };

  bestPrintCareLineShips = [
    'standard',
    'expedite',
    'priority',
    'vip',
    'express'
  ];

  @ViewChild('exportPrinityShirtModal', { static: false })
  private exportPrinityShirtModal: NgbModalRef;
  @ViewChild('productLinkModal', { static: false })
  private productLinkModal: NgbModalRef;
  @ViewChild('deleteSaleRecordModal', { static: false })
  private deleteSaleRecordModal: NgbModalRef;
  @ViewChild('importSaleRecordModal', { static: false })
  private importSaleRecordModal: NgbModalRef;

  @ViewChild('importSaleRecordTiktokModal', { static: false })
  private importSaleRecordTiktokModal: NgbModalRef;

  @ViewChild('productLinkInput')
  productLinkInput: ElementRef;

  desImages = '';
  preparedIgnoreProduct: any;
  openConfirmAction: EventEmitter<string> = new EventEmitter<string>();
  openConfirmBuyApiAction: EventEmitter<string> = new EventEmitter<string>();
  openConfirmStartDesModal: EventEmitter<string> = new EventEmitter<string>();
  openProductLinkModalEvent: EventEmitter<string> = new EventEmitter<string>();
  ignoreDesTotallyEvent: EventEmitter<string> = new EventEmitter<string>();

  confirmBuyApiMessage= "";
  timerRunning = false;

  buyApiAction: any;
  constructor(protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    public global: Global,
    private saleRecordService: SaleRecordService,
    private merchantService: MerchantService,
    private fulfillService: FulfillService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private http: HttpClient,
    private logTimeService: LogTimeService) {
    super(router, userService, titleService);

    this.activeRoute.queryParams.subscribe(params => {
      this.queryType = parseInt(params['queryType']);
      this.query.queryType = this.queryType;

      if (this.userData.roles.indexOf('Designer') > -1) {
        this.progressStates = this.progressStates.filter(_ => _.value <= 20);
        this.query.isNeedFindLink = true;
      } else if (this.userData.roles.indexOf('Fulfill') > -1) {
        this.query.isNeedFulfill = true;
        this.query.page = 60;
      } else if (this.userData.roles.indexOf('Support') > -1) {
        this.query.isNeedAddTrackToAccount = true;
      }
      this.getMerchants();
      this.getMerchantGroups();
      //this.getPrintifyFulfillConfigs();
      this.querySaleRecords();
      this.getAvailablePrintConfigs();
      this.getBlueprints();
      this.getPrintifyProviders();

//      this.ignoreDesTotallyEvent.subscribe(() => {
//        this.ignoreDesTotally();
//      });
    });
  }

  getAvailablePrintConfigs() {
    this.fulfillService.getAvailablePrintConfigs().subscribe
    (data => {
      this.availablePrintConfig = data;
    });
  }

  ngOnInit(): void {
    
  }

  clearFilter(isQueryAlso = true) {
    this.query = {
      purchaseDate: '',
      shipDueDate: '',
      sourceOrderId: '',
      customerName: '',
      asin: '',
      sku: '',
      destinationOrderId: '',
      trackingNumber: '',
      note: '',
      merchantIds: '',
      merchantGroupIds: '',
      pageSize: 30,
      pageNumber: 1,
      wantedPrintService: '',
      sortBy: 'CreatedTime',
      sortType: 'Desc',
      saleRecordType: undefined
    };

    for (var i = 0; i < this.quickFilters.length; i++) {
      var filter = this.quickFilters[i];
      this.query[filter] = false;
    }

    if (this.userData.roles.indexOf('Designer') > -1) {
      this.query.isNeedFindLink = true;
    } else if (this.userData.roles.indexOf('Fulfill') > -1) {
      this.query.isNeedFulfill = true;
      this.query.page = 60;
    } else if (this.userData.roles.indexOf('Support') > -1) {
      this.query.isNeedAddTrackToAccount = true;
    }

    if (isQueryAlso) {
      this.querySaleRecords();
    }
  }

  querySaleRecords(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    this.global.pageLoading = true;
    this.saleRecordService.query(this.query).subscribe(data => {

      this.saleRecords = data.items;
      this.totalItem = data.total;

      this.global.pageLoading = false;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      this.filterCount = data.filterCount;
    });
  }

  getMerchants() {
    this.merchantService.getGrantedMerchants(this.query.merchantGroupIds).subscribe(data => {
      this.merchants = data;

      this.depopMerchants = _.filter(data, i => i.merchantType == 5);
      this.tiktokMerchants = _.filter(data, i => i.merchantType == 2);
      this.etsyMerchants = _.filter(data, i => i.merchantType == 4);
    });
  }

  getMerchantGroups() {
    this.merchantService.getGrantedMerchantGroups().subscribe(data => {
      this.merchantGroups = data;
    });
  }

  updateMerchantGroupFilter() {
    this.getMerchants();
    this.query.merchantIds = '';
    this.querySaleRecords(1);
  }

  updateSingleValue(record, type, value) {

    if (type == "Note") {
      record.note = value;
    } else if (type == "DestinationOrderId") {
      record.destinationOrderId = value;
    } else if (type == "ProductPrice") {
      record.productPrice = value;
    } else if (type == "OrderPrice") {
      record.orderPrice = value;
    } else if (type == "TrackingNumber") {
      record.trackingNumber = value;
    } else if (type == "OrderRefund") {
      record.orderRefund = value;
    } else if (type == "IsNeedReorder") {
      record.isNeedReorder = !record.isNeedReorder;
    } else if (type == "IsNeedFindLink") {
      record.isNeedFindLink = !record.isNeedFindLink;
    } else if (type == "IsCanceled") {
      record.isCanceled = !record.isCanceled;
    } else if (type == "IsNoticed") {
      record.isNoticed = !record.isNoticed;
    } else if (type == "IsDeleted") {
      record.isDeleted = value;
    } else if (type == "ProgressState") {
      record.progressState = value;
    } else if (type == "DesPoint") {
      record.desPoint = value;
    } else if (type == "DesPointExplain") {
      record.desPointExplain = value;
    } else if (type == "DesPointExplain") {
      record.desPointExplain = value;
    } else if (type == "Des") {
      record.destinationProductLinkText = value;
      record.destinationProductLink = value;
      record.destinationProductThumbnail = value;
    }

    this.saleRecordService.updateSingleValue(record, type).subscribe(data => {
        for (var i = 0; i < this.saleRecords.length; i++) {

          var model = _.find(data, o => o.orderItemId === this.saleRecords[i].orderItemId);
          if (model == undefined || model.length === 0) {
            continue;
          }
          var oldSeller = this.saleRecords[i].sellerName;
          var oldDesigner = this.saleRecords[i].designerName;

          this.saleRecords[i] = model;

          if (!model.sellerName) {
            this.saleRecords[i].sellerName = oldSeller;
          }
          if (!model.designerName) {
            this.saleRecords[i].designerName = oldDesigner;
          }

        }

        this.getFilterCount();
        if (type == "Des") {
          this.toastr.success("Cập nhật Des thành công");
        }
      },
      error => {
        this.toastr.error("Có lỗi xảy ra khi cập nhật thông tin đơn hàng, xin vui lòng báo cho Admin");
      });
  }

  updateNoteForSellerCheck(record, value) {
    record.note = this.sellerCheck.sourceProduct.note;
    this.updateSingleValue(record, 'Note', this.sellerCheck.sourceProduct.note);
  }

  updateDesForSellerCheck(record, value) {
    if (this.userData.roles.indexOf('Fulfill') === -1 || record.progressState !== 20) {
      return;
    }

    record.destinationProductLinkText = value;
    this.updateSingleValue(record, 'Des', this.sellerCheck.destinationProductLinkText);
  }

  getFilterCount() {
    this.saleRecordService.getFilterCount().subscribe(data => {
      this.filterCount = data;
    });
  }

  onCoppied(record, type) {
    for (var i = 0; i < this.saleRecords.length; i++) {
      this.saleRecords[i].sourceOrderIdCoppied = false;
      this.saleRecords[i].customerNameCoppied = false;
      this.saleRecords[i].asinCoppied = false;
      this.saleRecords[i].destinationOrderIdCoppied = false;
      this.saleRecords[i].productPriceCoppied = false;
      this.saleRecords[i].orderPriceCoppied = false;
      this.saleRecords[i].trackingNumberCoppied = false;
    }

    if (type === "sourceOrderId") {
      record.sourceOrderIdCoppied = true;
    }
    if (type === "customerName") {
      record.customerNameCoppied = true;
    }
    if (type === "asin") {
      record.asinCoppied = true;
    }
    if (type === "destinationOrderId") {
      record.destinationOrderIdCoppied = true;
    }
    if (type === "productPrice") {
      record.productPriceCoppied = true;
    }
    if (type === "orderPrice") {
      record.orderPriceCoppied = true;
    }
    if (type === "trackingNumber") {
      record.trackingNumberCoppied = true;
    }
  }

  reorder(record) {
    this.saleRecordService.reorder(record).subscribe((data) => {
      for (var i = 0; i < this.saleRecords.length; i++) {
        if (this.saleRecords[i].orderItemId != record.orderItemId) {
          continue;
        }

        this.saleRecords.splice(i, 0, data);
        record.isNeedReorder = false;
        break;
      }
    });
  }

  onProductLinkCopied() {
    this.toastr.success('copied');
    this.modalService.dismissAll();
  }

  saveProductLink() {
    this.updateSingleValue(this.productLinkSaleRecord, "ProductLink", this.productLinkSaleRecord.destinationProductLink);
    this.modalService.dismissAll();
  }

  confirmDeleteSaleRecord(record) {
    this.deletedSaleRecord = JSON.parse(JSON.stringify(record));
    this.modalService.open(this.deleteSaleRecordModal);
  }

  deleteSaleRecord() {
    this.updateSingleValue(this.deletedSaleRecord, "IsDeleted", true);
    this.modalService.dismissAll();
  }

  toggleQuickFilter(type) {

    for (var i = 0; i < this.quickFilters.length; i++) {
      var filter = this.quickFilters[i];
      if (
        filter == type && this.query[filter] == true && (this.userData.roles.indexOf('Designer') > -1 || this.userData.roles.indexOf('Fulfill') > -1 || this.userData.roles.indexOf('Support') > -1)) {

      } else if (filter == type) {
        this.query[filter] = !this.query[filter];
      } else if (this.quickFilters[i] == type) {
        this.query[filter] = true;
      } else {
        this.query[filter] = false;
      }
    }

    this.querySaleRecords();
  };

  getCustomization(customization, index) {
    if (customization == null || customization == '') {
      return '';
    }

    index = index - 1;

    var partSeperate = '';
    var informSeperate = '';
    if (customization.indexOf('====') > -1 || customization.indexOf('>>>') > -1) {
      partSeperate = '====';
      informSeperate = ">>>";
    } else {
      partSeperate = ',';
      informSeperate = ":";
    }

    var parts = customization.split(partSeperate);
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].split(informSeperate).length == 2) {
        parts[i] = parts[i].split(informSeperate)[1].trim();
      } else {
        parts[i] = parts[i].trim();
      }
    }
    if (index > parts.length - 1) {
      return '';
    }
    return parts[index];
  };

  copyOrderData(record) {
    if (record != undefined && (record.streetAddress2 == undefined || record.streetAddress2 == null)) {
      record.streetAddress2 = '';
    }
    let event = new CustomEvent('copySaleRecord', { 'detail': record });
    window.dispatchEvent(event);
  };

  copyHeading(record) {
    let str = 'ASIN: ' + record.asin;
    if (record.sku != undefined && record.sku != null && record.sku !== '') {
      let formatSkuParts = record.sku.split('-');

      if (formatSkuParts.length >= 2) {
        str += ' SKU: ' + formatSkuParts[0] + '-' + formatSkuParts[1];
      } else {
        str = ' SKU: ' + record.sku;
      }
    }
    this.copyStringToClipboard(str);
  };

  openExportPrintifyDialog() {
    this.printifyExport.providerId = '';
    this.printifyExport.productId = '';
    this.modalService.open(this.exportPrinityShirtModal);
  };

  downloadExportFileForPrintify() {
    this.global.pageLoading = true;
    this.modalService.dismissAll();
    this.fulfillService.downloadExportFileForPrintify(this.printifyExport, this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'text/csv;charset=ASCII;' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-printify.csv";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  downloadExportFileForFlashship() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForFlashship(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'text/csv;charset=ASCII;' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-flash.csv";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });

  };

  downloadExportFileForBestPrintCare() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForBestPrintCare(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Best-Print-Care.xlsx";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  buyMultipleRecordsByMerchizeApi() {
    this.global.pageLoading = true;
    this.fulfillService.buyMultipleRecordsMerchizeApi(this.saleRecords)
      .subscribe(data => {
          this.handleAfterSubmitApiOrders(data);
        },
        error => {
          this.toastr.error(error.message);
          this.global.pageLoading = false;
        });
  }

  buyMultipleRecordsByBestPrintCareApi() {
    this.global.pageLoading = true;
    this.fulfillService.sendOrderBestPrintCareApi(this.saleRecords)
      .subscribe(data => {
          this.handleAfterSubmitApiOrders(data);
        },
        error => {
          this.toastr.error(error.message);
          this.global.pageLoading = false;
        });
  }

  buyMultipleRecordsByPrintifyApi() {
    this.global.pageLoading = true;
    this.fulfillService.buyMultipleRecordsByPrintifyApi(this.saleRecords)
      .subscribe(data => {
          this.handleAfterSubmitApiOrders(data);
        },
        error => {
          this.toastr.error(error.message);
          this.global.pageLoading = false;
        });
  }

  preConfirmBuyApi(printService) {
    var checkRecordCount = 0;

    for (var i = 0; i < this.saleRecords.length; i++) {
      if (this.saleRecords[i].isSelected) {
        checkRecordCount++;
      }
    }

    this.confirmBuyApiMessage = "Bạn có muốn mua " + checkRecordCount + " bản ghi bằng " + printService + "?";
    if (printService == "flashship") {
      this.buyApiAction = this.buyMultipleRecordsByFlashshipApi;
    }
    if (printService == "printify") {
      this.buyApiAction = this.buyMultipleRecordsByPrintifyApi;
    }
    if (printService == "merchize") {
      this.buyApiAction = this.buyMultipleRecordsByMerchizeApi;
    }
    if (printService == "bestPrintCare") {
      this.buyApiAction = this.buyMultipleRecordsByBestPrintCareApi;
    }
    if (printService == "printway") {
      this.buyApiAction = this.buyMultipleRecordsByPrinwayApi;
    }
    this.openConfirmBuyApiAction.emit();
  }

  buyMultipleRecordsByPrinwayApi() {
    this.global.pageLoading = true;
    this.fulfillService.sendOrderPrintwayApi(this.saleRecords)
      .subscribe(data => {
          this.handleAfterSubmitApiOrders(data, 'printway');
        },
        error => {
          this.toastr.error(error.message);
          this.global.pageLoading = false;
        });
  }

  buyMultipleRecordsByFlashshipApi() {
    this.global.pageLoading = true;
    this.fulfillService.sendOrderFlashshipApi(this.saleRecords)
      .subscribe(data => {
          this.handleAfterSubmitApiOrders(data, 'flashship');
        },
        error => {
          this.toastr.error(error.message);
          this.global.pageLoading = false;
        });
  }

  handleAfterSubmitApiOrders(data, service= '') {
    this.refreshSaleRecordData(data);
    this.global.pageLoading = false;
    if (service == 'flashship') {
      var hasErrorFlashship = false;
      for (var i = 0; i < data.length; i++) {
        if (data[i].destinationOrderId == undefined || data[i].destinationOrderId == '') {
          this.toastr.error("Có lỗi xảy ra: " + data[i].streetAddress);
        }
      }
      return;
    }

    var hasError = false;
    for (var i = 0; i < data.length; i++) {
      if (!data[i]) {
        hasError = true;
        break;
      }
    }

    if (hasError) {
      this.toastr.error("Có lỗi xảy ra với một hoặc nhiều đơn");
    } else {
      this.toastr.success("Đơn đã được gửi sang hệ thống nhà in");
    }
  }

  downloadExportFileForMerchize() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForMerchize(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'text/csv;charset=ASCII;' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-Merchize.csv";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  downloadExportFileForGeargag() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForGeargag(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'text/csv;charset=ASCII;' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-Geargag.csv";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  downloadExportFileForPrintWay() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForPrintWay(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-PrintWay.xlsx";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  downloadExportFileForArtsAdd() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForArtsAdd(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'application/octet-stream' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-ArtsAdd.xls";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  downloadExportFileForGearment() {
    this.global.pageLoading = true;
    this.fulfillService.downloadExportFileForGearment(this.saleRecords).subscribe(data => {
      var blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var url = window.URL.createObjectURL(blob);
      this.global.pageLoading = false;

      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = "Export-Gearment.xlsx";
      fileLink.click();

      fileLink.parentNode.removeChild(fileLink);
    });
  };

  copyStringToClipboard(str) {
    if (str === null || str === undefined || str === '') {
      str = ' ';
    }
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
  };

  toggleCheckAll() {
    for (var i = 0; i < this.saleRecords.length; i++) {
      this.saleRecords[i].isSelected = !this.checkAll;
    }
  };

  openImportSaleRecordModal() {
    this.uploadImportFileProgress = 0;
    this.importMessages = [];
    this.uploadToMerchantGroupId = '';

    if (this.merchantGroups.length == 1) {
      this.uploadToMerchantGroupId = this.merchantGroups[0].id;
      this.uploadToMerchantId = undefined;
    }

    this.modalService.open(this.importSaleRecordModal, { windowClass: "xlModal" });

  }

  uploadImportFile(files) {
    if (this.uploadToMerchantGroupId == '') {
      return;
    }
    this.uploadImportFileProgress = 1;
    const formData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.global.pageLoading = true;
    this.http
      .post("/api/SaleRecord/UploadSaleRecordsViaCsv?uploadToMerchantGroupId=" + this.uploadToMerchantGroupId
        + "&uploadToMerchantId=" + this.uploadToMerchantId,
        formData,
        {
          reportProgress: true,
          observe: "events"
        })
      .subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadImportFileProgress = Math.round((100 / event.total) * event.loaded);

        } else if (event.type == HttpEventType.Response) {

          this.uploadImportFileProgress = null;
          this.global.pageLoading = false;

          this.importMessages = [];
          for (let i = 0; i < (<any>event.body).length; i++) {
            const element = event.body[i];
            this.importMessages.push(element.message);
          }
        }
      });
  }

  //Seller Check => Start
  openProductLinkModal(record) {
    this.global.pageLoading = true;
    this.saleRecordLogs = [];
    this.sellerCheck = {
      suggestProducts: [],
      destinationProductLinkText: "",
      selectedProduct: {
      },
      availableDesigners: [],
      selectedDesigner: null
    };

    this.temptPreviewImages = [];
    

    this.saleRecordService.getSellerCheckModel(record).subscribe(data => {
      this.sellerCheck = data;
      this.sellerCheck.currentRecord = record;

      if (this.sellerCheck.suggestProducts.length >= 1 && this.sellerCheck.sourceProduct.progressState >= 20) {
        this.selectSuggestProduct(this.sellerCheck.suggestProducts[0]);
      }

      if ((this.sellerCheck.selectedDesigner == null || this.sellerCheck.selectedDesigner == undefined) && this.sellerCheck.availableDesigners.length > 0) {
        this.sellerCheck.selectedDesigner = this.sellerCheck.availableDesigners[0];
      }

      this.modalService.open(this.productLinkModal, { windowClass: "xlModal" });
      this.genDownloadLinks();
      this.getImageSizes();

      setTimeout(
        () => {
          this.openProductLinkModalEvent.emit(record.sku);
        },
        200);

      this.openProductLinkModalEvent.emit(record.sku);
      this.global.pageLoading = false;
    });

    this.saleRecordService.getSaleRecordLog(record).subscribe(data => {
      this.saleRecordLogs = data;
    });

  };

  selectSuggestProduct(suggestProduct) {
    this.sellerCheck.selectedProduct = suggestProduct;
    this.sellerCheck.destinationProductLinkText = suggestProduct.imageLinkText;
  };

  passToSeller() {
    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;
    record.destinationProductLink = this.sellerCheck.destinationProductLinkText;
    record.note = this.sellerCheck.sourceProduct.note;
    record.destinationProductThumbnail = this.sellerCheck?.selectedProduct?.demoLinks.join('\n') ?? '';
    record.progressState = 0;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        for (var j = 0; j < data.length; j++) {
          if (data[j].orderItemId != this.saleRecords[i].orderItemId) {
            continue;
          }
          this.saleRecords[i] = data[j];
        }
      }

      this.global.pageLoading = false;
      this.modalService.dismissAll();

      this.getFilterCount();
    });
  };

  passToNeedConfirm() {
    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;
    record.destinationProductLink = this.sellerCheck.destinationProductLinkText;
    record.note = this.sellerCheck.sourceProduct.note;
    record.destinationProductThumbnail = this.sellerCheck?.selectedProduct?.demoLinks.join('\n') ?? '';
    record.progressState = 5;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        for (var j = 0; j < data.length; j++) {
          if (data[j].orderItemId != this.saleRecords[i].orderItemId) {
            continue;
          }
          this.saleRecords[i] = data[j];
        }
      }

      this.global.pageLoading = false;
      this.getFilterCount();
      this.modalService.dismissAll();
    });
  };

  passToDesigner() {
    if (this.sellerCheck.selectedDesigner == null) {
      return;
    }
    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;
    record.destinationProductLink = this.sellerCheck.destinationProductLinkText;
    record.note = this.sellerCheck.sourceProduct.note;
    record.destinationProductThumbnail = this.sellerCheck?.selectedProduct?.demoLinks.join('\n') ?? '';
    record.progressState = 10;
    record.selectedDesignerId = this.sellerCheck.selectedDesigner.id;
    record.desPoint = this.sellerCheck.sourceProduct.desPoint;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        for (var j = 0; j < data.length; j++) {
          if (data[j].orderItemId != this.saleRecords[i].orderItemId) {
            continue;
          }
          this.saleRecords[i] = data[j];
        }
      }
      this.global.pageLoading = false;
      this.getFilterCount();
      this.modalService.dismissAll();
    });
  };

  passToFulfill() {
    if (this.sellerCheck.destinationProductLinkText == undefined || this.sellerCheck.destinationProductLinkText.trim() == '') {
      return;
    }

    if (this.timerRunning) {
      this.toastr.error('Bộ đếm thời gian vẫn đang chạy');
      return;
    }

    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;
    record.destinationProductLink = this.sellerCheck.destinationProductLinkText;
    record.note = this.sellerCheck.sourceProduct.note;
    record.desPoint = this.sellerCheck.sourceProduct.desPoint;
    if (this.sellerCheck.selectedProduct) {
      record.destinationProductThumbnail = this.sellerCheck.selectedProduct.demoLinks.join('\n');
    }

    record.progressState = 20;
    this.saleRecordService.update(record).subscribe(data => {
      this.refreshSaleRecordData(data);
    });
  };

  refreshSaleRecordData(data: any) {
    for (var i = 0; i < this.saleRecords.length; i++) {

      for (var j = 0; j < data.length; j++) {
        if (!data[j]) {
          this.toastr.error("Có lỗi xảy ra với đơn mua api");
          continue;
        }
        if (data[j].orderItemId != this.saleRecords[i].orderItemId) {
          continue;
        }
        this.saleRecords[i] = data[j];
      }
    }

    this.global.pageLoading = false;
    this.getFilterCount();
    this.modalService.dismissAll();
  }

  upload(files) {
    this.sellerCheck.uploadProgress = 1;
    const formData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }

    this.global.pageLoading = true;

    this.http.options(
        'http://172.16.0.101/api/SaleRecord/UploadDesign'
      )
      .pipe(
        timeout(1500),
        catchError(e => {
          this.uploadToPublicServer(formData, files);
          return null;
        })
      )
      .subscribe(() => {
        this.uploadToPrivateServer(formData, files);
      });
  };

  uploadToPrivateServer(formData, files:any) {
    this.http.post("http://172.16.0.101/api/SaleRecord/UploadDesign/", formData)
      .subscribe((res: any) => {
          this.sellerCheck.uploadProgress = null;
          this.global.pageLoading = false;
          this.sellerCheck.destinationProductLinkText = res.destinationProductLinkText;

          this.temptPreviewImages = [];
          for (var previewIndex = 0; previewIndex < res.previewImages.length; previewIndex++) {
            var previewMess = res.previewImages[previewIndex];
            if (previewMess.indexOf('link cho file') === -1) {
              continue;
            }

            for (var orgFileIndex = 0; orgFileIndex < files.length; orgFileIndex++) {
              var orgFile = files[orgFileIndex];
              var fileName = orgFile.name;
              var fileNameFormPreviewMess = previewMess.split('/')[previewMess.split('/').length - 1];
              if (fileNameFormPreviewMess.toLowerCase() !== fileName.toLowerCase()) {
                continue;
              }
              var reader = new FileReader();

              reader.onload = (event: any) => {
                this.temptPreviewImages.push(event.target.result);
              }

              reader.readAsDataURL(files[orgFileIndex]);
            }
          }

          this.sellerCheck.selectedProduct = {
            demoLinks: res.previewImages
          };

          this.genDownloadLinks();
          this.desImages = '';
        },
        error => {
          alert('Có lỗi xảy ra, xin thử lại');
          this.sellerCheck.uploadProgress = null;
          this.global.pageLoading = false;
          this.desImages = '';
        });

  }

  uploadToPublicServer(formData, files:any) {
    this.http
      .post("/api/SaleRecord/UploadDesign/", formData)
      .subscribe((res: any) => {
          this.sellerCheck.uploadProgress = null;
          this.global.pageLoading = false;
          this.sellerCheck.destinationProductLinkText = res.destinationProductLinkText;

          this.temptPreviewImages = [];
          for (var previewIndex = 0; previewIndex < res.previewImages.length; previewIndex++) {
            var previewMess = res.previewImages[previewIndex];
            if (previewMess.indexOf('link cho file') === -1) {
              continue;
            }

            for (var orgFileIndex = 0; orgFileIndex < files.length; orgFileIndex++) {
              var orgFile = files[orgFileIndex];
              var fileName = orgFile.name;
              var fileNameFormPreviewMess = previewMess.split('/')[previewMess.split('/').length - 1];
              if (fileNameFormPreviewMess.toLowerCase() !== fileName.toLowerCase()) {
                continue;
              }
              var reader = new FileReader();

              reader.onload = (event: any) => {
                this.temptPreviewImages.push(event.target.result);
              }

              reader.readAsDataURL(files[orgFileIndex]);
            }
          }

          this.sellerCheck.selectedProduct = {
            demoLinks: res.previewImages
          };

          this.genDownloadLinks();
          this.desImages = '';
        },
        error => {
          alert('Có lỗi xảy ra, xin thử lại');
          this.sellerCheck.uploadProgress = null;
          this.global.pageLoading = false;
          this.desImages = '';
        });

  }

  genDownloadLinks() {
    this.sellerCheck.downloadLinks = [];
    var text = this.sellerCheck.destinationProductLinkText;
    if (text == undefined || text == '') {
      return;
    }
    var parts = text.split('\n');
    for (let i = 0; i < parts.length; i++) {
      const element = parts[i];
      var downloadLink = element.replace('raw=', 'dl=');
      this.sellerCheck.downloadLinks.push(downloadLink);
    }
  }

  getImageSizes() {
    var text = this.sellerCheck.destinationProductLinkText;
    this.sellerCheck.imageSizes = [];
    if (text == undefined || text == '') {
      return;
    }
    if (this.sellerCheck.sourceProduct.progressState != 20) {
      return;
    }
    var parts = text.replaceAll('\n', '###')
      .replaceAll(' ', '###');
    this.fulfillService.getImageSize(parts).subscribe(res => {
      this.sellerCheck.imageSizes = res;
    });

  }

  genSellerCheckCustomizeInfo(customization) {
    var result = [];
    for (let i = 1; i < 20; i++) {
      var customizeInfoPart = this.getCustomization(customization, i);
      if (customizeInfoPart != undefined && customizeInfoPart != '') {
        result.push(customizeInfoPart);
        continue;
      }
      break
    }
    return result.join(' | ');
  }

  syncSellerCheckPrice(record, type) {
    if (type == 'orderPrice' && record.orderPrice > 0) {
      record.productPrice = 0;
    }
    if (type == 'productPrice' && record.productPrice > 0) {
      record.orderPrice = 0;
    }

  }

  confirmBuy() {
    // if (this.sellerCheck.sourceProduct.destinationOrderId == undefined
    //   || this.sellerCheck.sourceProduct.destinationOrderId.trim() == ''
    //   || (this.sellerCheck.sourceProduct.productPrice == 0 && this.sellerCheck.sourceProduct.orderPrice == 0)) {
    //   return;
    // }
    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;

    record.note = this.sellerCheck.sourceProduct.note;
    record.destinationOrderId = this.sellerCheck.sourceProduct.destinationOrderId;
    record.destinationProductLink = this.sellerCheck.destinationProductLinkText;
    record.productPrice = this.sellerCheck.sourceProduct.productPrice;
    record.orderPrice = this.sellerCheck.sourceProduct.orderPrice;
    record.progressState = 30;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        var model = _.find(data, o => o.orderItemId === this.saleRecords[i].orderItemId);
        if (model == undefined || model.length === 0) {
          continue;
        }
        this.saleRecords[i] = model;

      }

      this.global.pageLoading = false;
      this.getFilterCount();
      this.modalService.dismissAll();
    });
  }

  confirmTracking() {
    if (this.sellerCheck.sourceProduct.trackingNumber == 0) {
      return;
    }
    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;

    record.note = this.sellerCheck.sourceProduct.note;
    record.trackingNumber = this.sellerCheck.sourceProduct.trackingNumber;
    record.progressState = 35;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        var model = _.find(data, o => o.orderItemId === this.saleRecords[i].orderItemId);
        if (model == undefined || model.length === 0) {
          continue;
        }
        this.saleRecords[i] = model;

      }

      this.global.pageLoading = false;
      this.getFilterCount();
      this.modalService.dismissAll();
    });
  }

  confirmTrackingAccount() {

    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;

    record.note = this.sellerCheck.sourceProduct.note;
    record.progressState = 40;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        var model = _.find(data, o => o.orderItemId === this.saleRecords[i].orderItemId);
        if (model == undefined || model.length === 0) {
          continue;
        }
        this.saleRecords[i] = model;

      }

      this.global.pageLoading = false;
      this.getFilterCount();
      this.modalService.dismissAll();
    });
  }

  confirmTrackingDone() {
    if (this.sellerCheck.sourceProduct.trackingNumber == 0) {
      return;
    }
    var record = this.sellerCheck.currentRecord;

    this.global.pageLoading = true;

    record.note = this.sellerCheck.sourceProduct.note;
    record.progressState = 50;

    this.saleRecordService.update(record).subscribe(data => {

      for (var i = 0; i < this.saleRecords.length; i++) {

        var model = _.find(data, o => o.orderItemId === this.saleRecords[i].orderItemId);
        if (model == undefined || model.length === 0) {
          continue;
        }
        this.saleRecords[i] = model;

      }

      this.global.pageLoading = false;
      this.getFilterCount();
      this.modalService.dismissAll();
    });
  }

  updatePrintService(orderItemId, wantedPrintService) {
    this.saleRecordService.updatePrintService(orderItemId, wantedPrintService)
      .subscribe(data => {
        for (var i = 0; i < this.saleRecords.length; i++) {

          if (orderItemId != this.saleRecords[i].orderItemId) {
            continue;
          }
          this.saleRecords[i].wantedPrintService = wantedPrintService;
        }
      }
      , error => {
        this.toastr.error("Có lỗi xảy ra khi cập nhật thông tin đơn hàng, xin vui lòng báo cho Admin");
      });
  }

  updateVariantInfo(orderItemId, type, value) {
    this.saleRecordService.updateVariantInfo(orderItemId, type, value)
      .subscribe(data => {
        for (var i = 0; i < this.saleRecords.length; i++) {

          if (orderItemId != this.saleRecords[i].orderItemId) {
            continue;
          }

          this.saleRecords[i].customizeInfo = data;
        }
      }
    ,error => {
      this.toastr.error("Có lỗi xảy ra khi cập nhật thông tin đơn hàng, xin vui lòng báo cho Admin");
    });
  }

  getBlueprints() {
    this.fulfillService.getBlueprints().subscribe(data => {
      this.blueprints = data;
    });
  }

  getPrintifyProviders() {
    this.fulfillService.getPrintifyProviders().subscribe(data => {
      this.printifyProviders = data;
    });
  }

  ignoreDes(suggestProduct) {
    suggestProduct.ignoredDes = true;
    this.saleRecordService.ignoreDes(suggestProduct.orderItemId, this.sellerCheck.sourceProduct.sku).subscribe(data => { });
  }

  ignoreDesTotally() {
    this.preparedIgnoreProduct.ignoredDes = true;
    this.saleRecordService.ignoreDesTotally(this.preparedIgnoreProduct.orderItemId).subscribe(data => { });
  }

  confirmIgnoreDesTotally(suggestProduct) {
    this.preparedIgnoreProduct = suggestProduct;
    this.openConfirmAction.emit(suggestProduct);
  }

  preCheckConfirmDesWork(record) {
    this.global.pageLoading = true;
    this.saleRecordService.checkIfValidToStartDes(record).subscribe(data => {
      if (!data) {
        this.global.pageLoading = false;
        this.openConfirmStartDesModal.emit();
      } else {
        this.confirmDesWork();
      }
    });
  }

  confirmDesWork() {
    this.global.pageLoading = true;
    this.saleRecordService.confirmDesWork(this.sellerCheck.currentRecord).subscribe(data => {
      this.toastr.success('Bạn đã xác nhận làm việc trên Des này');

      this.global.pageLoading = false;
    });
  }

  //Seller Check => End

  groupByPrintway(item) {
    return item.group;
  }

  buyPrintifyByApi() {

    this.global.pageLoading = true;
    this.fulfillService.sendOrderPrintifyApi(this.sellerCheck.currentRecord).subscribe(data => {
        this.toastr.success('Đặt hàng thành công');
        this.refreshSaleRecordData(data);
        this.modalService.dismissAll();
        this.global.pageLoading = false;
      },
      error => {
        this.toastr.error(error.message);
        this.global.pageLoading = false;
      });
  }

  buyFlashshipByApi() {
    this.global.pageLoading = true;
    this.sellerCheck.currentRecord.isSelected = true;
    this.fulfillService.sendOrderFlashshipApi([this.sellerCheck.currentRecord]).subscribe(data => {
        this.toastr.success('Đặt hàng thành công');
        this.refreshSaleRecordData(data);
        this.modalService.dismissAll();
        this.global.pageLoading = false;

        this.handleAfterSubmitApiOrders(data, 'flashship');

      },
      error => {
        this.toastr.error(error.message);
        this.global.pageLoading = false;
      });
  }

  buyPrintwayByApi() {
    this.global.pageLoading = true;
    this.sellerCheck.currentRecord.isSelected = true;
    this.fulfillService.sendOrderPrintwayApi([this.sellerCheck.currentRecord]).subscribe(data => {
        this.toastr.success('Đặt hàng thành công');
        this.refreshSaleRecordData(data);
        this.modalService.dismissAll();
        this.global.pageLoading = false;

        this.handleAfterSubmitApiOrders(data, 'printway');

      },
      error => {
        this.toastr.error(error.message);
        this.global.pageLoading = false;
      });
  }

  buyPrintCareByApi() {
    this.global.pageLoading = true;
    this.sellerCheck.currentRecord.isSelected = true;
    this.fulfillService.sendOrderBestPrintCareApi([this.sellerCheck.currentRecord]).subscribe(data => {
        this.toastr.success('Đặt hàng thành công');
        this.refreshSaleRecordData(data);
        this.modalService.dismissAll();
        this.global.pageLoading = false;
      },
      error => {
        this.toastr.error(error.message);
        this.global.pageLoading = false;
      });
  }

  openImportSaleRecordTiktokModal() {
    this.uploadImportFileProgress = 0;
    this.importMessages = [];
    this.uploadToMerchantGroupId = '';

    if (this.merchantGroups.length == 1) {
      this.uploadToMerchantGroupId = this.merchantGroups[0].id;
    }

    this.modalService.open(this.importSaleRecordTiktokModal, { windowClass: "xlModal" });

  }

  recalculateDesPoint(event) {
    this.global.pageLoading = true;
    this.logTimeService.calculateDesPoint(this.sellerCheck.currentRecord.orderItemId, 'Design')
      .subscribe(data => {
        this.sellerCheck.sourceProduct.desPoint = data;
        this.updateSingleValue(this.sellerCheck.sourceProduct, 'DesPoint', data);

        this.global.pageLoading = false;
      });
  }

  assignTimerRunning(event) {
    this.timerRunning = (event == "true");
  }

  addNewColor = (newColor) => {
    this.global.pageLoading = true;
    this.fulfillService.addColor(newColor)
      .subscribe(i => {
        this.global.pageLoading = false;
      });

    return newColor;
  }

  addNewSize = (newSize) => {
    this.global.pageLoading = true;
    this.fulfillService.addSize(newSize)
      .subscribe(i => {
        this.global.pageLoading = false;
      });

    return newSize;
  }
}

