import { Component, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Global } from "../../classes/global";
import { Router } from '@angular/router';
import { UserService } from "../../services/user/user.service";
import { NeedAuthorize } from "../../classes/needAuthorize";
import { SaleRecordService } from "../../services/saleRecord/sale-record.service";
import { MerchantService } from "../../services/merchant/merchant.service";

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends NeedAuthorize {

  dataModel = undefined;
  merchantGroups = [];
  detailSaleReportLoading = false;
  query = {
    startDate: '',
    endDate: '',
    merchantGroupId: '',
    market: 'DirectOrder'
  };

  customData = undefined;
  
  detailSaleReportChartData: ChartDataSets[] = [
    { label: 'Import', data: [] },
    { label: 'Buy', data: [] },
    { label: 'Net Income', data: [] },
  ];

  public lineChartOptions: (ChartOptions) = {
    animation: {
      duration: 1,
      onComplete: function (this) {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
        function numberWithCommas(x) {
          var parts = x.toString().split(".");
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return parts.join(".");
        }

        this.data.datasets.forEach((dataset, i) => {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            console.log(bar);
            var data = dataset.data[index];
            ctx.fillStyle = bar._model.backgroundColor;

            var pointString = '$' + numberWithCommas(data.toFixed(0));
            var orderCount = '';

            if (bar._model.backgroundColor == 'rgba(0, 104, 173, 0.95)') {
              orderCount = numberWithCommas(window['chartData'].saleChartPoints[index].saleSummary.importNetworth.orderCount);
            }
            if (bar._model.backgroundColor == 'rgba(128, 128, 128, 1)') {
              orderCount = numberWithCommas(window['chartData'].saleChartPoints[index].saleSummary.boughtSaleRecordNetworth.orderCount);
            } 

            ctx.fillText(
              pointString,
              bar._model.x,
              bar._model.y - 5);

            ctx.fillText(
              orderCount,
              bar._model.x,
              bar._model.y - 20);
          });
        });
      }
    }
  };
  lineChartLegend: boolean = true;

  detailSaleReportChartLabels: Label[] = [];

  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(0, 104, 173, 0.95)',
      pointBackgroundColor: 'rgba(0, 104, 173, 0.95)',
      backgroundColor: 'rgba(0, 104, 173, 0.95)',
      pointBorderColor: 'rgba(0, 104, 173, 0.95)',
      pointHoverBackgroundColor: 'rgba(0, 104, 173, 0.95)',
      pointHoverBorderColor: 'rgba(0, 104, 173, 0.95)'
    },
    {
      borderColor: 'rgba(128, 128, 128, 1)',
      pointBackgroundColor: 'rgba(128, 128, 128, 1)',
      backgroundColor: 'rgba(128, 128, 128, 1)',
      pointBorderColor: 'rgba(128, 128, 128, 1)',
      pointHoverBackgroundColor: 'rgba(128, 128, 128, 1)',
      pointHoverBorderColor: 'rgba(128, 128, 128, 1)'
    },
    {
      borderColor: 'rgba(0, 179, 83, 0.99)',
      backgroundColor: 'rgba(0, 179, 83, 0.99)',
      pointBackgroundColor: 'rgba(0, 179, 83, 0.99)',
      pointBorderColor: 'rgba(0, 179, 83, 0.99)',
      pointHoverBackgroundColor: 'rgba(0, 179, 83, 0.99)',
      pointHoverBorderColor: 'rgba(0, 179, 83, 0.99)'
    }
  ];

  detailSaleChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    private saleRecordService: SaleRecordService,
    private global: Global,
    private merchantService: MerchantService) {
    super(router, userService, titleService);
    this.getMerchantGroups();    
    this.queryDashboardData();
  }
    queryDashboardData() {
      this.global.pageLoading = true;

      //Detail Sale Report Data
      this.queryDetailSaleReport();

      this.saleRecordService.getDashboardData(this.query).subscribe((data) => {
        this.dataModel = data;

        this.global.pageLoading = false;
      });
    }

  getMerchantGroups() {
    this.merchantService.getGrantedMerchantGroups().subscribe(data => {
      this.merchantGroups = data;
    });
  }

  queryCustomSaleSummary() {
    console.log('queryCustomSaleSummary');

    this.global.pageLoading = true;
    this.saleRecordService.getCustomSaleSummary(this.query).subscribe(data => {
      this.global.pageLoading = false;
      this.customData = data;
    });
  }

  queryDetailSaleReport() {
    //Detail Sale Report Data
    this.detailSaleReportLoading = true;
    this.saleRecordService.getDetailSaleReportData(this.query).subscribe((data) => {

      window['chartData'] = data;

      this.detailSaleReportChartLabels = _.map(data.saleChartPoints, a => a.label);

      this.detailSaleReportChartData[0].data = _.map(data.saleChartPoints, a => a.saleSummary.importNetworth.orderMoney);
      this.detailSaleReportChartData[1].data = _.map(data.saleChartPoints, a => a.saleSummary.boughtSaleRecordNetworth.orderMoney);
      this.detailSaleReportChartData[2].data = _.map(data.saleChartPoints, a => a.saleSummary.netIncome);

      this.chart.updateColors();
      this.chart.update();
      this.detailSaleReportLoading = false;
    });
  }

  changeTab(currentMarket) {
    this.query = {
      startDate: '',
      endDate: '',
      merchantGroupId: '',
      market: currentMarket
    };
    if (currentMarket == 'Redbubble') {
      console.log("Redbubble");
    }
    else {
      this.queryDashboardData();
    }
  }



  ngOnInit(): void {
  }
}
