import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Global } from 'src/app/classes/global';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {
  @ViewChild('confirmActionModal', { static: false }) private confirmActionModal: NgbModalRef;

  @Input() confirmMessage: string;
  @Input() openModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    this.openModal.subscribe(() => {
      this.confirmActionModal =  this.modalService.open(this.confirmActionModal, { windowClass: "lModal" });
    });
  }

  invokeAction() {
    this.confirmActionModal.close();
    this.action.emit('');
  }
}
