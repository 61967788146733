import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenDesLayer {
    constructor() {
      this.layerType = 0;
      this.layerFont = 'Algerian';
      this.layerTextColor = 'Black';
      this.layerFontSize = 20;
      this.layerStrokeColor = 'Black';
      this.layerStrokeSize = 0;
    }
  id: string;
  designTemplateId: string;
  layerName: string;
  layerIndex: number;
  layerType: number;

  layerText: string;
  layerFont: string;
  layerTextColor: string;
  layerFontSize: number;
  layerStrokeSize: number;
  layerStrokeColor: string;
  options: LayerOption[] = [];
}

export class LayerOption {
  constructor() {
    this.optionName = "New Option";
  }
  id: string;
  layerId: string;
  optionName: string;
  optionIndex: number;
  isSelected: boolean;
  thumbData: string;
  imageData: string;
}
