import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SiteLayoutComponent } from './components/site-layout/site-layout.component';
import { LoginComponent } from './components/login/login.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';

import { Global } from "./classes/global";
import { TokenInterceptor } from "./classes/interceptor";
import { OrdersComponent } from './components/orders/orders.component';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { PaginationComponent } from './components/pagination/pagination.component';
import { EditableComponent } from './components/editable/editable.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccountAdminComponent } from './components/account-admin/account-admin.component';
import { AccountDetailComponent } from './components/account-detail/account-detail.component';
import { ChartsModule } from 'ng2-charts';
import { AccountMessageComponent } from './components/account-message/account-message.component';
import { RedBubbleOrderComponent } from './components/red-bubble-order/red-bubble-order.component';
import { AsinReportComponent } from './components/asin-report/asin-report.component';
import { CheckTrademarkComponent } from './components/check-trademark/check-trademark.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MerchantListComponent } from './components/merchant-list/merchant-list.component';
import { AutoCommandsComponent } from './components/auto-commands/auto-commands.component';
import { Dashboardv2Component } from './components/dashboardv2/dashboardv2.component';
import { NetworthDailyChartComponent } from './components/dashboardv2/networth-daily-chart/networth-daily-chart.component';
import { ReportLoadingComponent } from './components/dashboardv2/report-loading/report-loading.component';
import { IncomeDisReportComponent } from './components/dashboardv2/income-dis-report/income-dis-report.component';
import { AccDisReportComponent } from './components/dashboardv2/acc-dis-report/acc-dis-report.component';
import { SummaryReportComponent } from './components/dashboardv2/summary-report/summary-report.component';
import { EmployeePerformanceReportComponent } from './components/dashboardv2/employee-performance-report/employee-performance-report.component';
import { AllToUsdComponent } from './components/all-to-usd/all-to-usd.component';
import { NumberCommaDirective } from './classes/number-comma.directive';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { AddTransactionComponent } from './components/add-transaction/add-transaction.component';
import { FinancesReportComponent } from './components/dashboardv2/finances-report/finances-report.component';
import { SaleRecordBatchActionComponent } from './components/sale-record-batch-action/sale-record-batch-action.component';
import { PersonalDailyReportComponent } from './components/personal-daily-report/personal-daily-report.component';
import { SaleRecordListComponent } from './components/personal-daily-report/sale-record-list/sale-record-list.component';
import { PersonalDailyReportListComponent } from './components/personal-daily-report-list/personal-daily-report-list.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { CreateDepopUploadCommandComponent } from './components/create-depop-upload-command/create-depop-upload-command.component';
import { GenDesComponent } from './components/gen-des/gen-des.component';
import { MockupListComponent } from './components/mockup-list/mockup-list.component';
import { ImagesShowComponent } from './components/images-show/images-show.component';
import { LogTimeComponent } from './components/log-time/log-time.component';
import { DesPointReportComponent } from
  "./components/dashboardv2/des-point-report/des-point-report.component";
import { SyncOrderSheetComponent } from './components/sync-order-sheet/sync-order-sheet.component';
import { FixedDesPointForSkuComponent } from './components/fixed-des-point-for-sku/fixed-des-point-for-sku.component';
import { SplitOrderItemComponent } from './components/split-order-item/split-order-item.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SiteLayoutComponent,
    LoginComponent,
    AuthLayoutComponent,
    OrdersComponent,
    PaginationComponent,
    RedBubbleOrderComponent,
    EditableComponent,
    AccountAdminComponent,
    AccountDetailComponent,
    AccountMessageComponent,
    RedBubbleOrderComponent,
    AsinReportComponent,
    CheckTrademarkComponent,
    MerchantListComponent,
    MerchantListComponent,
    AutoCommandsComponent,
    Dashboardv2Component,
    NetworthDailyChartComponent,
    ReportLoadingComponent,
    IncomeDisReportComponent,
    AccDisReportComponent,
    SummaryReportComponent,
    EmployeePerformanceReportComponent,
    AllToUsdComponent,
    NumberCommaDirective,
    TransactionsComponent,
    AddTransactionComponent,
    FinancesReportComponent,
    SaleRecordBatchActionComponent,
    PersonalDailyReportComponent,
    SaleRecordListComponent,
    PersonalDailyReportListComponent,
    ConfirmActionComponent,
    CreateDepopUploadCommandComponent,
    GenDesComponent,
    MockupListComponent,
    ImagesShowComponent,
    LogTimeComponent,
    DesPointReportComponent,
    SyncOrderSheetComponent,
    FixedDesPointForSkuComponent,
    SplitOrderItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    ClipboardModule,
    ToastrModule.forRoot(),
    NgbModule,
    ChartsModule,
    NgSelectModule
  ],
  providers: [
    Global,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
