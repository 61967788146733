import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogTimeService } from "../../services/log-time/log-time.service";
import { Global } from "../../classes/global";

@Component({
  selector: 'app-log-time',
  templateUrl: './log-time.component.html',
  styleUrls: ['./log-time.component.scss']
})
export class LogTimeComponent implements OnInit  {

  @Input() targetId;
  @Input() type;
  @Input() readonly;

  @Output() stopTimerEvent = new EventEmitter<string>();
  @Output() timerRunningChanged = new EventEmitter<string>();

  isRunning = false;
  startTime: number;
  totalTime: number = 0;
  elapsedTime: number = 0; // New property to track elapsed time

  constructor(private logTimeService: LogTimeService,
    private global: Global) {
  }

  ngOnInit() {
    this.logTimeService.getLogTimeInfo(this.targetId, this.type)
      .subscribe(data => {
        this.isRunning = data.isRunning;
        this.timerRunningChanged.emit(this.isRunning.toString());

        this.startTime = data.startTime;
        this.totalTime = data.totalTime;

        setInterval(() => {
          if (this.isRunning) {
            this.elapsedTime = Date.now() - this.startTime;
          }
        }, 1000);
      });

  }

  startTimer() {
    this.global.pageLoading = true;

    this.logTimeService.startLogTime(this.targetId, this.type)
      .subscribe(data => {
        this.global.pageLoading = false;
        if (!this.isRunning) {
          this.isRunning = true;
          this.timerRunningChanged.emit(this.isRunning.toString());
          this.startTime = Date.now();
        }
      });
  }

  stopTimer() {
    this.global.pageLoading = true;
    this.logTimeService.stopLogTime(this.targetId, this.type)
      .subscribe(data => {
        this.global.pageLoading = false;

        if (this.isRunning) {
          this.isRunning = false;
          this.timerRunningChanged.emit(this.isRunning.toString());

          this.totalTime += this.elapsedTime;
          this.elapsedTime = 0;

          this.stopTimerEvent.emit();
        }
      });
  }

  formatTime(milliseconds: number) {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60));

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}
