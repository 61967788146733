<main>
  <div class="container-fluid">
    <h1 class="mt-4">Dashboard</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Dashboard</li>
    </ol>
    <div class="col-12 n-s-d mb-2">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link tab-link" [class.active]="query.market =='DirectOrder'" (click)="changeTab('DirectOrder')">Direct</a>
        </li>
        <li class="nav-item">
          <a class="nav-link tab-link" [class.active]="query.market =='MediateOrder'" (click)="changeTab('MediateOrder')" aria-current="page">Mediate</a>
        </li>
        <li class="nav-item">
          <a class="nav-link tab-link" [class.active]="query.market =='Redbubble'" (click)="changeTab('Redbubble')" aria-current="page">Redbubble</a>
        </li>
      </ul>
    </div>
    <div [hidden]="query.market != 'DirectOrder' && query.market != 'MediateOrder'">
      <div class="row">
        <div class="col-4">
          <input class="form-control form-control-sm" [owlDateTimeTrigger]="startDate" [owlDateTime]="startDate" [(ngModel)]="query.startDate" (ngModelChange)="queryCustomSaleSummary()" placeholder="Start Date" />
          <owl-date-time [pickerType]="'calendar'" #startDate></owl-date-time>                    
        </div>        

        <div class="col-4">
          <input class="form-control form-control-sm" [owlDateTimeTrigger]="endDate" [owlDateTime]="endDate" [(ngModel)]="query.endDate" (ngModelChange)="queryCustomSaleSummary()" placeholder="End Date" />
          <owl-date-time [pickerType]="'calendar'" #endDate></owl-date-time>
        </div>

        <div class="col-4">
          <select class="form-control" [(ngModel)]="query.merchantGroupId" (ngModelChange)="queryCustomSaleSummary()">
            <option value="">All Account Groups</option>
            <option *ngFor="let g of merchantGroups" [value]="g.id">{{ g.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-xl-3 col-md-6">
          <div class="card bg-primary text-white mb-4 sale-summary">
            <div class="card-body">This Month</div>
            <div class="card-footer d-flex align-items-center justify-content-between small text-white stretched-link">
              <table class="sale-summary-table" *ngIf="dataModel != undefined">
                <tr>
                  <td>Imported</td>
                  <td class="text-right"> {{dataModel.thisMonth.importNetworth.orderCount}} units - ${{dataModel.thisMonth.importNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Canceled units</td>
                  <td class="text-right">{{dataModel.thisMonth.canceledOnBothSideNetworth.orderCount}} units - ${{dataModel.thisMonth.canceledOnBothSideNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Refund Canceled units</td>
                  <td class="text-right">{{dataModel.thisMonth.refundCanceledNetworth.orderCount}} units - ${{dataModel.thisMonth.refundCanceledNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Other Refunds</td>
                  <td class="text-right">{{dataModel.thisMonth.otherRefundNetworth.orderCount}} units - ${{dataModel.thisMonth.otherRefundNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Buy</td>
                  <td class="text-right">{{dataModel.thisMonth.boughtSaleRecordNetworth.orderCount}} units - ${{dataModel.thisMonth.boughtSaleRecordNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Hold Balance</td>
                  <td class="text-right">${{dataModel.thisMonth.holdBalanceString}}</td>
                </tr>
                <tr>
                  <td>Net Income</td>
                  <td class="text-right">${{dataModel.thisMonth.netIncomeString}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-success text-white mb-4 sale-summary">
            <div class="card-body">Yesterday</div>
            <div class="card-footer d-flex align-items-center justify-content-between small text-white stretched-link">
              <table class="sale-summary-table" *ngIf="dataModel != undefined">
                <tr>
                  <td>Imported</td>
                  <td class="text-right"> {{dataModel.yesterday.importNetworth.orderCount}} units - ${{dataModel.yesterday.importNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Canceled units</td>
                  <td class="text-right">{{dataModel.yesterday.canceledOnBothSideNetworth.orderCount}} units - ${{dataModel.yesterday.canceledOnBothSideNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Refund Canceled units</td>
                  <td class="text-right">{{dataModel.yesterday.refundCanceledNetworth.orderCount}} units - ${{dataModel.yesterday.refundCanceledNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Other Refunds</td>
                  <td class="text-right">{{dataModel.yesterday.otherRefundNetworth.orderCount}} units - ${{dataModel.yesterday.otherRefundNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Buy</td>
                  <td class="text-right">{{dataModel.yesterday.boughtSaleRecordNetworth.orderCount}} units - ${{dataModel.yesterday.boughtSaleRecordNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Hold Balance</td>
                  <td class="text-right">${{dataModel.yesterday.holdBalanceString}}</td>
                </tr>
                <tr>
                  <td>Net Income</td>
                  <td class="text-right">${{dataModel.yesterday.netIncomeString}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-info text-white mb-4 sale-summary">
            <div class="card-body">Today</div>
            <div class="card-footer d-flex align-items-center justify-content-between small text-white stretched-link">
              <table class="sale-summary-table" *ngIf="dataModel != undefined">
                <tr>
                  <td>Imported</td>
                  <td class="text-right"> {{dataModel.today.importNetworth.orderCount}} units - ${{dataModel.today.importNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Canceled units</td>
                  <td class="text-right">{{dataModel.today.canceledOnBothSideNetworth.orderCount}} units - ${{dataModel.today.canceledOnBothSideNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Refund Canceled units</td>
                  <td class="text-right">{{dataModel.today.refundCanceledNetworth.orderCount}} units - ${{dataModel.today.refundCanceledNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Other Refunds</td>
                  <td class="text-right">{{dataModel.today.otherRefundNetworth.orderCount}} units - ${{dataModel.today.otherRefundNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Buy</td>
                  <td class="text-right">{{dataModel.today.boughtSaleRecordNetworth.orderCount}} units - ${{dataModel.today.boughtSaleRecordNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Hold Balance</td>
                  <td class="text-right">${{dataModel.today.holdBalanceString}}</td>
                </tr>
                <tr>
                  <td>Net Income</td>
                  <td class="text-right">${{dataModel.today.netIncomeString}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-danger text-white mb-4 sale-summary">
            <div class="card-body">
              Custom
            </div>
            <div class="card-footer d-flex align-items-center justify-content-between small text-white stretched-link">
              <table class="sale-summary-table" *ngIf="customData != undefined">
                <tr>
                  <td>Imported</td>
                  <td class="text-right"> {{customData.importNetworth.orderCount}} units - ${{customData.importNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Canceled units</td>
                  <td class="text-right">{{customData.canceledOnBothSideNetworth.orderCount}} units - ${{customData.canceledOnBothSideNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Refund Canceled units</td>
                  <td class="text-right">{{customData.refundCanceledNetworth.orderCount}} units - ${{customData.refundCanceledNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Other Refunds</td>
                  <td class="text-right">{{customData.otherRefundNetworth.orderCount}} units - ${{customData.otherRefundNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Buy</td>
                  <td class="text-right">{{customData.boughtSaleRecordNetworth.orderCount}} units - ${{customData.boughtSaleRecordNetworth.orderMoneyString}}</td>
                </tr>
                <tr>
                  <td>Hold Balance</td>
                  <td class="text-right">${{customData.holdBalanceString}}</td>
                </tr>
                <tr>
                  <td>Net Income</td>
                  <td class="text-right">${{customData.netIncomeString}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <div class="flex">
            <div class="flex-item">
              <button class="btn btn-primary" (click)="queryDetailSaleReport()">
                Generate Detail Report
                <span [hidden]="!detailSaleReportLoading">
                  <i class="fas fa-circle-notch fa-spin"></i>
                </span>
              </button>
              <div style="display: block;" [hidden]="detailSaleReportLoading">
                <canvas baseChart
                        [datasets]="detailSaleReportChartData"
                        [labels]="detailSaleReportChartLabels"
                        [legend]="lineChartLegend"
                        [chartType]="'bar'"
                        [colors]="lineChartColors"
                        [options]="lineChartOptions"
                        [plugins]="detailSaleChartPlugins"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="query.market != 'Redbubble'">
    <app-red-bubble-order></app-red-bubble-order>
  </div>
</main>
