import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-editable',
  templateUrl: './editable.component.html',
  styleUrls: ['./editable.component.scss']
})
export class EditableComponent implements OnInit {

  @Input() data: any;
  @Input() dataType: any;
  @Output() focusOut: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('textarea') textAreaElement: ElementRef;
  @ViewChild('text') textElement: ElementRef;
  @ViewChild('number') numberElement: ElementRef;

  origin: any;
  currency = '$';
  editMode = false;

  constructor() {
    if (this.dataType === '' || this.dataType == undefined) {
      this.dataType = 'text';
    }
  }

  ngOnInit() {
    this.origin = this.data;
  }

  activeEdit() {
    this.editMode = true;

    if (this.dataType == "textarea") {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.textAreaElement.nativeElement.select();
//        document.execCommand("copy");
      }, 0);
    }
    else if (this.dataType == "text") {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        this.textElement.nativeElement.select();
//        document.execCommand("copy");
      }, 0);

    }else if (this.dataType == "number") {
      setTimeout(() => { // this will make the execution after the above boolean has changed
        if (this.data === 0) {
          this.data = undefined;
        }
        this.numberElement.nativeElement.select();
        //document.execCommand("copy");
      }, 0);
    }
  }

  onFocusOut() {
    if (this.dataType === "number" && (this.data == undefined || this.data == null)) {
      this.data = 0;
    }

    if (this.origin === this.data)
    {
      return;
    }

    this.focusOut.emit(this.data);
  }

  shortLinkForText(text, threshold = 40): string {
    var result = this.shortLinkForTextWithSplitChar(text, threshold, ' ');
    result = this.shortLinkForTextWithSplitChar(result, threshold, '\n');
    return result;
  }

  shortLinkForTextWithSplitChar(text, threshold, splitchar) {
    if (!text) {
      return '';
    }
    const words = text.split(splitchar);
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.startsWith("http") && word.length > threshold) {
        words[i] = word.slice(0, threshold) + "...";
      }
    }

    var result = words.join(splitchar);
    return result;
  }
}
