<main>
    <div class="container-fluid">
      <h1 class="mt-4">Auto Commands</h1>
      <ol class="breadcrumb mb-4">
        <li class="breadcrumb-item active">Auto Commands</li>
      </ol>
  
      <div class="row">
        <div class="col-4">
          <button class="btn btn-primary" (click)="createSoldCommandsForAll()">Create Sold Command For All</button>
        </div>
        <div class="col-4">
          <div class="input-group">
            <ng-select [items]="merchants"
                       style="width: 40%"
                       [multiple]="false"
                       class="table-select-filter"
                       placeholder="Pick Merchant"
                       bindValue="merchantId"
                       bindLabel="name"
                       [closeOnSelect]="true"
                       [(ngModel)]="merchantId">
            </ng-select>
            <div class="input-group-append">
              <button class="btn btn-primary" (click)="createSoldCommandForMerchant()">Create Sold Command For Merchant</button>
            </div>
          </div>
        </div>
        <div class="col-4">
          <button class="btn btn-primary ml-2 float-right" (click)="queryCommands()">Refresh</button>
        </div>          
      </div>

      <div class="row mt-1">
        <div class="col-12">
          <button class="btn btn-primary" (click)="openCreateUploadProductCommandModal()">Tạo lệnh up sản phẩm cho Depop</button>
          <app-create-depop-upload-command [openCreateCommandModalModalEvent]="openCreateCommandModalModalEvent"
                                           (commandSaved)="queryCommands()"></app-create-depop-upload-command>
        </div>
      </div>
  
      <div class="row">
        <div class="col mt-3">
          <form>
            <input name="find" type="text" class="form-control" placeholder="Find" [(ngModel)]="query.queryString"
              (ngModelChange)="queryCommands()">
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="includeHandled" [(ngModel)]="query.includeHandled" (ngModelChange)="queryCommands()">
            <label class="form-check-label" for="includeHandled">
              Include Handled
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="float-right">
            <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="queryResult.total"
              (onPageChanged)="queryCommands($event)">
            </app-pagination>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered table-hover mt-3">
            <thead>
              <tr>
                <th>VPS</th>
                <th>Account Name</th>
                <th class="text-center">Command Type</th>
                <th>Create Time</th>                
                <th>Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of queryResult.items" [class.table-danger]="c.merchant.isDeleted" [class.table-warning]="c.isHandled">
                <td>{{c.merchant.vpsName}}</td>
                <td>{{c.merchant.name}}</td>
                <td class="text-center" style="color: #43af73">
                  <i class="fa fa-shopping-cart fa-lg" *ngIf="c.messageType == '1'" aria-hidden="true"></i>
                  <i class="fa fa-truck fa-lg" *ngIf="c.messageType == '2'" aria-hidden="true"></i>
                  <i class="fa fa-upload" *ngIf="c.messageType == '20'"aria-hidden="true"></i>

                </td>
                <td>
                  {{c.createdDateString}}
                  <br/><span class="badge badge-pill badge-primary">{{c.hoursDiff | number : '1.2-2'}} Hours</span> 
                  <span class="ml-2 badge badge-pill badge-success">{{c.minutesDiff | number : '1.2-2'}} Minutes</span>
                </td>
                <td>
                  <span *ngIf="c.clientCommandLog">
                    <span class="badge badge-pill badge-primary" *ngIf="c.clientCommandLog.currentStatus == 0">
                      Chờ Chạy
                    </span>
                    <span class="badge badge-pill badge-warning" *ngIf="c.clientCommandLog.currentStatus == 1">
                      Đang Chạy
                    </span>
                    <span class="badge badge-pill badge-danger" *ngIf="c.clientCommandLog.currentStatus == 2">
                      Lỗi
                    </span>
                    <span class="badge badge-pill badge-info" *ngIf="c.clientCommandLog.currentStatus == 3">
                      Done
                    </span>
                    {{c.clientCommandLog.text}}
                  </span>
                </td>
                <td>
                  <button class="btn btn-danger ml-2" (click)="deleteCommand(c)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="float-right">
        <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="queryResult.total"
          (onPageChanged)="queryCommands($event)">
        </app-pagination>
      </div>
    </div>
  
    <ng-template #commandModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">          
        </h4>    
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cancel</button>
        <button type="button" class="btn btn-primary">Save</button>
      </div>
    </ng-template>
  </main>
