import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Global {
  pageLoading: boolean = false;
  emptyGuid: string = "00000000-0000-0000-0000-000000000000";
  imgHodlder: string = '/content/img-holder.jpg'
}
