import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

const headers = new HttpHeaders({ 'responseType': 'arraybuffer' });

@Injectable({
  providedIn: 'root'
})
export class RedBubbleService{
  constructor(private httpClient: HttpClient) { }

  getDetailSaleReportData(query) {
    return this.httpClient.get<any>('api/rebubbleRecord/getRedbbuleSummary/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }
}
