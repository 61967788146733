import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Global } from "../../classes/global";
import { Router } from '@angular/router';
import { UserService } from "../../services/user/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginModel: any = {
    username: '',
    password: '',
    grant_type: 'password'
  };

  submited: boolean = false;

  loginSuccess: boolean = false;

  isShowPassword = false;

  errorMessage: string = '';

  constructor(private titleService: Title, private userService: UserService, private global: Global, private router: Router) {
    this.titleService.setTitle("Login");
    let userInfo = this.userService.getLocalUserInfo();
    if (userInfo != null) {
      if (userInfo.isAdmin || userInfo.isSuperAdmin) {
        this.router.navigateByUrl('/dashboardv2');
        return;
      }

      if (userInfo.isRedBubbleUser) {
        this.router.navigateByUrl('/rbOrder');
        return;
      }

      this.router.navigateByUrl('/orders');
      return;
    }
  }

  ngOnInit(): void {
  }

  login(form: NgForm) {
    this.submited = true;
    if (!form.valid) {
      return;
    }

    this.global.pageLoading = true;
    this.userService.login(this.loginModel)
      .subscribe(
        (res) => {
          localStorage.setItem("LTV", res.access_token);
          
          this.userService.getUserInfo().subscribe(result => {
            let userInfoString = JSON.stringify(result);
            localStorage.setItem("UserInfo", userInfoString);
            this.global.pageLoading = false;

            this.router.navigateByUrl('/orders');
            return;
          });

        },
        (error) => {
          this.loginSuccess = false;
          this.errorMessage = error.error.error_description;
          this.global.pageLoading = false;
        });

  }
}
