import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AccountMessageService {
  constructor(private httpClient: HttpClient) { }


  query(query) {
    return this.httpClient.get<any>('api/accountMessage/query/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  toggleHandled(message) {
    return this.httpClient.get<any>('api/accountMessage/toggleHandled?id=' + message.id);
  }
}
