import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

const headers = new HttpHeaders({ 'responseType': 'arraybuffer' });

@Injectable({
  providedIn: 'root'
})
export class CheckTrademarkService {
  constructor(private httpClient: HttpClient) { }

  checkTrademark(target) {
    return this.httpClient.get<any>('/api/Trademark/CheckTrademark?target=' + target);
  }
}
