import { Component, OnInit, Input, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Global } from "../../classes/global";
import { SaleRecordService } from "../../services/saleRecord/sale-record.service";
import { UserService } from "../../services/user/user.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-fixed-des-point-for-sku',
  templateUrl: './fixed-des-point-for-sku.component.html',
  styleUrls: ['./fixed-des-point-for-sku.component.scss']
})
export class FixedDesPointForSkuComponent implements OnInit, AfterViewInit {

  @Input() shouldInit: EventEmitter<string>;
  @Input() sku: any;

  userData: any;
  fixedDesPoint: number = 0;

  constructor(public global: Global,
    private saleRecordService: SaleRecordService,
    private toastr: ToastrService,
    private userService: UserService,
    private changeDetectorRef: ChangeDetectorRef) {

    this.userData = this.userService.getLocalUserInfo();
  }

  ngOnInit(): void {
    this.shouldInit.subscribe(() => {

      this.saleRecordService.getFixedDesPointForSku(this.sku)
        .subscribe(fixDesPointForSku => {
          if (fixDesPointForSku) {
            this.fixedDesPoint = fixDesPointForSku.fixedDesPoint;
          } else {
            this.fixedDesPoint = 0;
          }
          console.log(this.fixedDesPoint);
          this.changeDetectorRef.markForCheck();
        });
    });

  }

  ngAfterViewInit(): void {

  }

  updateFixedDesPoint(sku, newPoint) {
    this.saleRecordService.updateFixedDesPoint(this.sku, this.fixedDesPoint)
      .subscribe(() => {
        this.toastr.success("Điểm DES cố định được update thành " + this.fixedDesPoint);
      });
  }

}
