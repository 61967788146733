import { Component, OnInit, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() pageNumber: any;
  @Input() pageSize: any;
  @Input() totalItems: any;

  @Output() onPageChanged = new EventEmitter<string>();

  get pages() {
    var result = [];
    if (this.pageNumber <= 3) {
      let min = Math.min(5, this.totalPages);
      for (var i = 1; i <= min; i++) {
        result.push(i);
      }
      return result;
    }

    if (this.totalPages <= 5) {
      for (var i = 1; i <= this.totalPages; i++) {
        result.push(i);
      }
      return result;
    }

    if (this.pageNumber >= this.totalPages - 5) {
      for (var i = this.totalPages - 4; i <= this.totalPages; i++) {
        result.push(i);
      }
      return result;
    }

    result.push(this.pageNumber - 2);
    result.push(this.pageNumber - 1);
    result.push(this.pageNumber);
    result.push(this.pageNumber + 1);
    result.push(this.pageNumber + 2);

    return result;
  }

  get totalPages() {
    return (Math.floor((this.totalItems - 1) / this.pageSize)) + 1;
  }

  ngOnInit() {
  }

  goToPage(pageNumber) {
    this.pageNumber = pageNumber;
    this.onPageChanged.next(this.pageNumber);
  }

  goToFirst() {
    if (this.pageNumber == 1) {
      return;
    }
    this.pageNumber = 1;
    this.onPageChanged.next(this.pageNumber);
  }

  goToPrev() {
    if (this.pageNumber == 1) {
      return;
    }
    this.pageNumber--;
    this.onPageChanged.next(this.pageNumber);
  }

  goToNext() {
    if (this.pageNumber == this.totalPages) {
      return;
    }
    this.pageNumber++;
    this.onPageChanged.next(this.pageNumber);
  }

  goToLast() {
    if (this.pageNumber == this.totalPages) {
      return;
    }
    this.pageNumber = this.totalPages;
    this.onPageChanged.next(this.pageNumber);
  }
}
