<h3>Chi tiết công việc</h3>
<table class="table table-bordered table-sm" [hidden]="reportLoading">
    <tr>
        <th>User</th>
        <th>Team</th>
        <th>Role</th>
        <th class="text-center">Seller Check - {{getTotalSellerCheck()}}</th>
        <th class="text-center">Design - {{getTotalDesign()}}</th>
        <th class="text-center">Fulfill- {{getTotalFulfill()}}</th>
        <th class="text-center">Add Track Acc- {{getTotalAddTrackAcc()}}</th>
        <th class="text-center">Follow Track- {{getTotalFollowTrack()}}</th>
    </tr>
    <tr *ngFor="let r of data">
        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
            || r.groupName.indexOf('Team Quynh') > -1
            || r.groupName.indexOf('Team Mui') >-1"
        >{{r.username}}</td>
        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
                                || r.groupName.indexOf('Team Quynh') > -1
                                || r.groupName.indexOf('Team Mui') >-1">{{r.groupName}}</td>
        <td [class.table-success]="r.roleName == 'Seller'" [class.table-warning]="r.roleName == 'Designer'"
            [class.table-primary]="r.roleName == 'Fulfill'" [class.table-danger]="r.roleName == 'Support'">
            {{r.roleName}}</td>
        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
            || r.groupName.indexOf('Team Quynh') > -1
            || r.groupName.indexOf('Team Mui') >-1" class="text-center"><span *ngIf="r.sellerCheckCount > 0">{{r.sellerCheckCount}}</span></td>

        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
            || r.groupName.indexOf('Team Quynh') > -1
            || r.groupName.indexOf('Team Mui') >-1" class="text-center"><span *ngIf="r.designCount > 0 || r.reDesignCount > 0">{{r.designCount}}
                ({{r.reDesignCount}})</span></td>

        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
            || r.groupName.indexOf('Team Quynh') > -1
            || r.groupName.indexOf('Team Mui') >-1" class="text-center"><span *ngIf="r.fulfillCount > 0">{{r.fulfillCount}}</span></td>

        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
            || r.groupName.indexOf('Team Quynh') > -1
            || r.groupName.indexOf('Team Mui') >-1" class="text-center"><span *ngIf="r.addTrackCount > 0">{{r.addTrackCount}}</span></td>

        <td [class.table-active]="r.groupName.indexOf('Team Trung Anh')> -1 
            || r.groupName.indexOf('Team Quynh') > -1
            || r.groupName.indexOf('Team Mui') >-1" class="text-center"><span *ngIf="r.followTrackCount > 0">{{r.followTrackCount}}</span></td>

    </tr>
</table>
<app-report-loading *ngIf="reportLoading"></app-report-loading>
