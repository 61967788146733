import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from "../../classes/global";
import { UrlHelper } from "../../classes/urlHelper";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private httpClient: HttpClient, private global: Global) { }

  checkEmailNotTaken(email: string) {
    return this.httpClient.get<boolean>('api/User/IsEmailExist?email=' + email);
  }

  login(loginModel) {
    let body = new URLSearchParams();
    body.set('username', loginModel.username);
    body.set('password', loginModel.password);
    body.set('grant_type', 'password');

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.httpClient.post<any>('/token', body.toString(), options);
  }

  signUp(signUpModel: any) {
    return this.httpClient.post<any>('api/Users/SignUp', signUpModel);
  }

  validateEmail(userId, code) {
    return this.httpClient.post<any>('api/Users/ValidateEmail', { userId: userId, code: code });
  }

  forgotPassword(forgotEmail: string) {
    return this.httpClient.post<any>('api/Users/ForgotPassword?email=' + forgotEmail, {});
  }

  resetPassword(resetPasswordModel: any) {
    return this.httpClient.post<any>('api/Users/ResetPassword', resetPasswordModel);
  }

  changePassword(changePasswordModel) {
    return this.httpClient.post<any>('api/Users/ChangePassword', changePasswordModel);
  }

  getUserInfo() {
    return this.httpClient.get<any>('api/Users/getUserInfo');
  }

  isLoggedIn() {

  }

  queryUser(query) {
    return this.httpClient.get('api/Users/queryUsers' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  getAccountDetail(accountId: string) {
    return this.httpClient.get('api/Users/getAccountDetail?id=' + accountId);
  }

  getLocalUserInfo() {
    var token = localStorage.getItem('LTV');
    var userInfoString = localStorage.getItem('UserInfo');

    if (token === "undefined" || token === '' || token === undefined || token === null)
      return null;

    if (userInfoString === "undefined" || userInfoString === '' || userInfoString === undefined || userInfoString === null)
      return null;

    const userInfo = JSON.parse(userInfoString);
    userInfo.isAdmin = userInfo.roles.indexOf("Admin") > -1;
    userInfo.isRedBubbleUser = userInfo.roles.indexOf("RedbubbleUser") > -1;
    userInfo.fromSellerToAbove = userInfo.isAdmin || userInfo.isSuperAdmin || userInfo.roles.indexOf('Team Leader') > -1 || userInfo.roles.indexOf('Seller') > -1;

    userInfo.canSeeNeedDesign = userInfo.isAdmin ||
      userInfo.isSuperAdmin ||
      userInfo.roles.indexOf('Team Leader') > -1 ||
      userInfo.roles.indexOf('Seller') > -1 ||
      userInfo.roles.indexOf('Designer') > -1 ||
      userInfo.roles.indexOf('Designer Online') > -1;

    userInfo.canSeeNeedFulfill = userInfo.isAdmin ||
      userInfo.isSuperAdmin ||
      userInfo.roles.indexOf('Team Leader') > -1 ||
      userInfo.roles.indexOf('Seller') > -1 ||
      userInfo.roles.indexOf('Fulfill') > -1;

    userInfo.canSeeNeedSupport = userInfo.isAdmin ||
      userInfo.isSuperAdmin ||
      userInfo.roles.indexOf('Team Leader') > -1 ||
      userInfo.roles.indexOf('Seller') > -1 ||
      userInfo.roles.indexOf('Support') > -1;

    userInfo.isDesigner = true;
    for (var i = 0; i < userInfo.roles.length; i++) {
      if (userInfo.roles[i] != 'Designer' && userInfo.roles[i] != 'Designer Online') {
        userInfo.isDesigner = false;
      }

    }

    return userInfo;
  }

  saveAccountDetailAdmin(user) {
    return this.httpClient.post('api/Users/saveAccountDetailAdmin', user);
  }

  logout() {
    localStorage.removeItem('LTV');
    localStorage.removeItem('UserInfo');
    window.location.href = '/';
  }
}
