import { Component, EventEmitter, Input } from '@angular/core';
import { UserService } from "../../services/user/user.service";
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { TransactionService } from 'src/app/services/transaction-service/transaction-service.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Global } from 'src/app/classes/global';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent extends NeedAuthorize {

  openTransactionModalEvent: EventEmitter<string> = new EventEmitter<string>();

  transactionTypes: [];
  merchantGroups: [];

  query: any = {
    typeId: '',
    category: '',
    readableCategory: '',
    merchantGroupIds: '',
    startDate: moment().startOf('month').toDate(),
    endDate: new Date(),
    ignorePod: true,
    pageNumber: 1,
    pageSize: 30
  }

  queryResult: any = {
    item: [],
    total: 0
  };

  constructor(protected router: Router,
    protected userService: UserService,
    protected transactionService: TransactionService,
    protected titleService: Title,
    protected global: Global) {
    super(router, userService, titleService);
    this.getMerchantGroupsForTransaction();
    this.queryTransactions();
  }

  ngOnInit(): void {

  }

  queryTransactions(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    var query = JSON.parse(JSON.stringify(this.query));

    query.startDate = moment(query.startDate).format();
    query.endDate = moment(query.endDate).format();

    this.global.pageLoading = true;
    this.transactionService.queryTransactions(query)
      .subscribe(data => {
        this.queryResult = data;
        this.global.pageLoading = false;
      });
  }

  openTransactionModal(category) {
    this.openTransactionModalEvent.emit(category);
  }

  getTransactionTypes() {
    this.transactionService.getTransactionTypes()
      .subscribe(data => { this.transactionTypes = data });
  }

  formatDate(input) {
    return moment(input).format('DD-MM-YYYY');
  }

  updateCategory() {
    if (this.query.readableCategory == 'Tiền ra') {
      this.query.category = 'out';
    }
    else if (this.query.readableCategory == 'Tiền vào') {
      this.query.category = 'in';
    }
    else {
      this.query.category = ''
    }

    this.queryTransactions();
  }

  getMerchantGroupsForTransaction() {
    this.transactionService.getMerchantGroupsForTransaction()
      .subscribe(data => { this.merchantGroups = data });
  }

  toggleDelete(transaction) {
    this.global.pageLoading = true;
    transaction.isDeleted = !transaction.isDeleted;
    this.transactionService.saveTransaction(transaction)
      .subscribe(data => {
        transaction = data;
        this.global.pageLoading = false;
      });
  }

  resetQuery() {
    this.query = {
      typeId: '',
      category: '',
      readableCategory: '',
      merchantGroupIds: '',
      ignorePod: true,
      startDate: moment().startOf('month').toDate(),
      endDate: new Date(),
      pageNumber: 1,
      pageSize: 30
    }
  }
}
