import { Component, OnInit } from '@angular/core';
import { RedBubbleService } from "../../services/redbubble/redbubble.service";

@Component({
  selector: 'app-red-bubble-order',
  templateUrl: './red-bubble-order.component.html',
  styleUrls: ['./red-bubble-order.component.scss']
})
export class RedBubbleOrderComponent implements OnInit {

  constructor(private redBubbleService: RedBubbleService) {
    this.queryRedBubbleSaleSummary();
  }

  query:any = {
    startDate: null,
    endDate: null
  }

  ngOnInit(): void {
  }

  rBdataModels: any;

  queryRedBubbleSaleSummary() {
    this.redBubbleService.getDetailSaleReportData(this.query).subscribe((data) => {
      this.rBdataModels = data;
    });
  }

}
