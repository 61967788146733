<main>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card shadow-lg border-0 rounded-lg mt-5">
          <div class="card-header">
            <h3 class="text-center font-weight-light my-4">Login</h3>
          </div>
          <div class="card-body">

            <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
              <div class="form-group">
                <label class="small mb-1">Email</label>
                <input name="email" type="email" class="form-control py-4" placeholder="Enter email address"
                       [(ngModel)]="loginModel.username"
                       #email="ngModel"
                       required
                       email>
                <label class="small mb-0 alert-danger" *ngIf="email.invalid && (email.dirty || email.touched || submited)">
                  <span *ngIf="email.errors.required">Email is required</span>
                  <span *ngIf="email.errors.email">Email is invalid</span>
                </label>
              </div>

              <div class="form-group">
                <label class="small mb-1">Password</label>
                <input name="password" [type]="isShowPassword ? 'text' : 'password'" class="form-control py-4"
                       [(ngModel)]="loginModel.password"
                       placeholder="Enter password"
                       #password="ngModel"
                       required
                       minlength="6">
                <label class="small mb-0 alert-danger" *ngIf="password.invalid && (password.dirty || password.touched || submited)">
                  <span *ngIf="password.errors.required">Password is required</span>
                  <span *ngIf="password.errors.minlength">Password need at least 6 chars</span>
                </label>
              </div>

              <!--<div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input class="custom-control-input" id="rememberPasswordCheck" type="checkbox">
                  <label class="custom-control-label" for="rememberPasswordCheck">Remember password</label>
                </div>
              </div>-->
              <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
                <a class="small" href="password.html">Forgot Password?</a>
                <button class="btn btn-primary" type="submit">Login</button>
              </div>
            </form>
          </div>
          <div class="card-footer text-center">
            <div class="small">
              <a href="register.html">Need an account? Sign up!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
