import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ReportService } from "../../services/report/report.service";
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { UserService } from "../../services/user/user.service";
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-personal-daily-report',
  templateUrl: './personal-daily-report.component.html',
  styleUrls: ['./personal-daily-report.component.scss']
})
export class PersonalDailyReportComponent extends NeedAuthorize {

  totalSellerCheck = 0;
  totalDes= 0;
  totalFulfill= 0;
  totalAddTrac= 0;

  reportLoading: boolean = false;
  reportData: any={
    employeeToTeamReports: [],
    totalSellerCheck: 0,
    totalDes: 0,
    totalFulfill: 0,
    totalAddTrac: 0,
  };

  teamMembers: any[]= [];

  isManager: boolean;

  query = {
    reportDate: new Date(),
    reportDateTo: new Date(),
    targetUserId: undefined
  };

  constructor(private reportService: ReportService,
    private toastr: ToastrService,
    protected router: Router,
    protected userService: UserService,
    private route: ActivatedRoute,
    protected titleService: Title) {

    super(router, userService, titleService);
    var userData = this.userService.getLocalUserInfo();
    this.isManager = userData.roles.indexOf('Admin') > -1 ||
      userData.roles.indexOf('Super Admin') > -1 ||
      userData.roles.indexOf('Team Leader') > -1;

    this.getAvailableUsers();

    this.route.params.subscribe(params => {
      var reportId = params['reportId'];
      if (reportId != '1') {

        this.reportLoading = true;
        this.reportService.getDailyReportById(reportId)
          .subscribe(data => {
            this.reportLoading = false;
            this.query.reportDate = data.reportDate;
            this.query.reportDateTo = data.reportDateTo;
            this.query.targetUserId = data.username;
            this.genReports();
          });
      }
    });
  }

  ngOnInit(): void {
  }

  genReports() {
    if (this.isManager && !this.query.targetUserId) {
      return;
    }

    var query = JSON.parse(JSON.stringify(this.query));

    query.reportDate = moment(query.reportDate).format();
    query.reportDateTo = moment(query.reportDateTo).format();

    this.reportLoading = true;

    this.reportService.getPersonalDailyReport(query).subscribe(data => {
      this.reportData = data;
      this.reportLoading = false;
    });
  }

  sendToTeamLeader(teamReport) {

    this.reportLoading = true;
    this.reportService.upsertDailyReport(teamReport)
      .subscribe(data => {
        this.reportLoading = false;
        for (var i = 0; i < this.reportData.employeeToTeamReports.length; i++) {
          if (data.teamName === this.reportData.employeeToTeamReports[i].teamName) {
            this.reportData.employeeToTeamReports[i].id = data.id;
            this.reportData.employeeToTeamReports[i].state = data.state;
            this.toastr.success("Đã Gửi báo cáo cho Team Leader");
            return;
          }
        }

      });
  }

  leaderAction(teamReport, type) {
    var tempReport = JSON.parse(JSON.stringify(teamReport));

    this.reportLoading = true;
    tempReport.state = type == 'refuse' ? 0 : 2;

    tempReport.managerNote = teamReport.managerNote;
    tempReport.sellerCheckPoint = teamReport.confirmedSellerCheckPoint;
    tempReport.designPoint = teamReport.confirmedDesignPoint;
    tempReport.fulfillPoint = teamReport.confirmedFulfillRecord;
    tempReport.addTrackAccPoint = teamReport.confirmedAddTrackAccPoint;
    tempReport.designPoint = teamReport.confirmedDesignPoint;
    tempReport.otherPoint = teamReport.otherPoint;

    this.reportService.upsertDailyReport(tempReport)
      .subscribe(data => {
        this.reportLoading = false;
        for (var i = 0; i < this.reportData.employeeToTeamReports.length; i++) {
          if (data.teamName === this.reportData.employeeToTeamReports[i].teamName) {
            this.reportData.employeeToTeamReports[i].id = data.id;
            this.reportData.employeeToTeamReports[i].state = data.state;

            if (type == 'refuse') {
              this.toastr.success("Đã gửi báo cáo cho nhân viên");
            } else {
              this.toastr.success("Đã xác nhận");
            }

            return;
          }
        }

      });
  }

  getAvailableUsers() {
    this.reportService.getAvailableUsers().subscribe(data => {
      this.teamMembers = data;
    });
  }
}
