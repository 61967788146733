import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../../services/user/user.service";
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { Global } from "../../classes/global";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommandService } from 'src/app/services/command-service/command.service';
import { MerchantService } from 'src/app/services/merchant/merchant.service';

@Component({
  selector: 'app-auto-commands',
  templateUrl: './auto-commands.component.html',
  styleUrls: ['./auto-commands.component.scss']
})
export class AutoCommandsComponent extends NeedAuthorize {

  merchantId: '';

  merchants = [];

  query = {
    queryString: '',
    pageSize: 50,
    pageNumber: 1,
    includeHandled: false
  };

  queryResult: any = {
    item: [],
    total: 0   
  };

  openCreateCommandModalModalEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(protected router: Router,
    protected userService: UserService,
    protected titleService: Title,
    private global: Global,
    private modalService: NgbModal,
    private commandService: CommandService,
    private merchantService: MerchantService) {
    super(router, userService, titleService);
    this.queryCommands();
    this.getMerchants();
  }

  ngOnInit(): void {
  }

  queryCommands(page = undefined) {
    if (page != undefined) {
      this.query.pageNumber = page;
    }
    this.global.pageLoading = true;
    this.commandService.queryCommands(this.query).subscribe(data => {
      this.queryResult = data;
      this.global.pageLoading = false;
    });
  }

  deleteCommand(command) {
    this.global.pageLoading = true;
    command.isHandled = true;
    this.commandService.saveCommand(command).subscribe(data => {
      this.global.pageLoading = false;
      this.modalService.dismissAll();
      
      for (let i = 0; i < this.queryResult.items.length; i++) {
        if (this.queryResult.items[i].id == data.id) {
          this.queryResult.items[i].isHandled = data.isHandled;          
          break;          
        }
      }
    });
  }
  
  createSoldCommandsForAll() {
    this.global.pageLoading = true;
    this.commandService.createSoldCommandsForAll()
      .subscribe(data => { 
        this.queryCommands();
      })
  }

  createSoldCommandForMerchant() {
    if (!this.merchantId)
    {
      return;
    }
    this.commandService.createSoldCommandForMerchant(this.merchantId).subscribe(data => {
      this.queryCommands();
    });
  }

  getMerchants() {
    this.merchantService.getGrantedMerchants('').subscribe(data => {
      this.merchants = data;
    });
  }

  openCreateUploadProductCommandModal() {
    this.openCreateCommandModalModalEvent.emit('');
  }

}
