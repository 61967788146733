import { Injectable } from '@angular/core';
import { UrlHelper } from "../../classes/urlHelper";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  
  constructor(private httpClient: HttpClient) { }

  getMerchantGroupsForTransaction() {
    return this.httpClient.get<any>('api/transactions/getMerchantGroupsForTransaction');
  }

  getTransactionTypes() {
    return this.httpClient.get<any>('api/transactions/getTransactionTypes');
  }

  addTransactionType(transactionType) {
    return this.httpClient.post<any>('api/transactions/addTransactionType', transactionType);
  }

  saveTransaction(transaction: any) {
    return this.httpClient.post<any>('api/transactions/saveTransaction', transaction);
  }

  queryTransactions(query: any) {
    return this.httpClient.get<any>('api/transactions/queryTransactions' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }
}
