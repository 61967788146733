<main>
  <div class="container-fluid">
    <h1 class="mt-4">Account Detail</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Account Detail</li>
    </ol>

    <form>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Email</label>
        <div class="col-sm-10">
          <input name="Email" type="email" class="form-control" [(ngModel)]="user.userName" placeholder="Email" [disabled]="!isCreateNew">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Password</label>
        <div class="col-sm-10">
          <input name="Password" type="password" class="form-control" placeholder="Password" [(ngModel)]="user.password">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Password Confirm</label>
        <div class="col-sm-10">
          <input name="ConfirmPassword" type="password" class="form-control" placeholder="Password Confirm" [(ngModel)]="user.confirmPassword">
        </div>
      </div>
      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Roles</legend>
          <div class="col-sm-10">
            <div class="form-check" *ngFor="let r of user.roleDetails">
              <input class="form-check-input" type="checkbox" [name]="r.roleName" [checked]="r.isInRole" [(ngModel)]="r.isInRole">
              <label class="form-check-label">
                {{r.roleName}}
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <div class="form-group row">
        <div class="col-sm-2">Account Groups</div>
        <div class="col-sm-10">
          <div class="form-check" *ngFor="let g of user.accountGroups">
            <input class="form-check-input" type="checkbox" [id]="g.groupName" [name]="g.groupName" [checked]="g.canManageAccountGroup" [(ngModel)]="g.canManageAccountGroup">
            <label class="form-check-label" [for]="g.groupName">
              {{g.groupName}}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-10">
          <button type="submit" class="btn btn-primary" (click)="saveChanges()">Save Changes</button>
        </div>
      </div>
    </form>

  </div>
</main>
