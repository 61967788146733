
<button
  [hidden]="saleRecord.progressState > 20"
  class="btn btn-warning btn-sm slit-btn" (click)="confirmSplitOrder()">
  Tách đơn
</button>

<app-confirm-action [confirmMessage]="'Bạn có chắc tách đơn này thành ' + productQuantity + ' đơn?'"
                    (action)="splitOrder()"
                    [openModal]="openConfirmAction">
</app-confirm-action>

<ng-template #splitFromQuantity1 let-modal>
  <div class="modal-lg">
    <div class="modal-header">
      <h4 class="modal-title">Nhập số đơn bạn muốn tách</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <label>Bạn muốn tách thành</label>
        <input class="form-control" type="number" [(ngModel)]="desiredProductQuantity"/> đơn
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="closeSplitFromQuantity1Modal()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="splitOrderFromSingleOrder()">Ok</button>
    </div>
  </div>
</ng-template>
