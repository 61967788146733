<body>
  <nav class="sb-topnav navbar navbar-expand navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/">Sale Data Center</a>
    <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" href="#">
      <i class="fas fa-bars"></i>
    </button><!-- Navbar Search-->
    <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
      <div class="input-group">
        <input class="form-control" type="text" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
        <div class="input-group-append">
          <button class="btn btn-primary" type="button">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>
    <!-- Navbar-->
    <div>
      <ul class="navbar-nav ml-auto ml-md-0">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-user fa-fw"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
            <a class="dropdown-item" href="#">Settings</a><a class="dropdown-item" href="#">Activity Log</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <div id="layoutSidenav">
    <div id="layoutSidenav_nav">
      <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
        <div class="sb-sidenav-menu">
          <div class="nav">
            <div class="sb-sidenav-menu-heading">Core</div>
            <a class="nav-link" *ngIf="userData.isAdmin
               || userData.roles.indexOf('Team Leader')>
              -1" routerLink="/dashboardv2" [class.active]=" currentUrl == '/dashboardv2' ">
              <div class="sb-nav-link-icon">
                <i class="fas fa-tachometer-alt"></i>
              </div>
              Dashboard
            </a>
            <a class="nav-link" *ngIf="userData.isSuperAdmin
               || userData.isAdmin
               || userData.roles.indexOf('Team Leader')>-1
              || userData.roles.indexOf('Seller') > -1 " routerLink="/asinReport" [class.active]=" currentUrl == '/asinReport' ">
              <div class="sb-nav-link-icon">
                <i class="fab fa-product-hunt"></i>
              </div>
              Product Report
            </a>
            <a class="nav-link" routerLink="/orders" [class.active]="currentUrl == '/orders'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-cash-register"></i>
              </div>
              Đơn
            </a>
            <a class="nav-link" routerLink="/mockupList" [class.active]="currentUrl == '/mockupList'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-palette"></i>
              </div>
              Mockups
            </a>

            <a class="nav-link" *ngIf="userData.isSuperAdmin
               || userData.isAdmin
               || userData.roles.indexOf('Team Leader')>
              -1
              || userData.roles.indexOf('Support') > -1" routerLink="/accountMessage" [class.active]="currentUrl == '/accountMessage'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-envelope"></i>
              </div>
              Messages
            </a>
            <a class="nav-link" *ngIf="userData.isAdmin ||userData.isSuperAdmin || userData.roles.indexOf('RedBubbleUser') > -1" routerLink="/rbOrder" [class.active]="currentUrl == '/rbOrder'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-registered"></i>
              </div>
              Redbubble Order
            </a>
            <a class="nav-link" *ngIf="userData.isSuperAdmin
               || userData.isAdmin
               || userData.roles.indexOf('Team Leader')>
              -1
              || userData.roles.indexOf('Seller') > -1
              || userData.roles.indexOf('Accountant') > -1" routerLink="/transactions" [class.active]="currentUrl == '/transactions'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-piggy-bank"></i>
              </div>
              Giao dịch
            </a>
            <a class="nav-link" *ngIf="userData.isSuperAdmin
               || userData.isAdmin
               || userData.roles.indexOf('Team Leader') > -1" routerLink="/accountAdmin" [class.active]="currentUrl == '/accountAdmin'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-user-circle"></i>
              </div>
              Accounts
            </a>
            <a class="nav-link" *ngIf="userData.isSuperAdmin
               || userData.isAdmin
               || userData.roles.indexOf('Team Leader')> -1
              || userData.isGrantedForMerchantActions > -1" routerLink="/merchants" [class.active]="currentUrl == '/merchants'">
              <div class="sb-nav-link-icon">
                <i class="fa fa-briefcase"></i>
              </div>
              Merchants
            </a>
            <a class="nav-link" *ngIf="userData.isSuperAdmin
               || userData.isAdmin
               || userData.roles.indexOf('Team Leader')>
              -1
              || userData.roles.indexOf('Seller') > -1" routerLink="/autoCommands" [class.active]="currentUrl == '/autoCommands'">
              <div class="sb-nav-link-icon">
                <i class="fas fa-terminal"></i>
              </div>
              Commands
            </a>

            <a class="nav-link" routerLink="/personalDailyReport/1" [class.active]="currentUrl.indexOf('personalDailyReport') > -1
                                                                                && currentUrl.indexOf('personalDailyReportList') == -1">
              <div class="sb-nav-link-icon">
                <i class="fas fa-user-clock"></i>
              </div>
              Báo cáo cá nhân
            </a>

            <!-- <a class="nav-link" *ngIf=" -->
            <!--    userData.isSuperAdmin -->
            <!--    || userData.isAdmin -->
            <!--    || userData.roles.indexOf('Team Leader') > -1" -->
            <!--   routerLink="/personalDailyReportList" [class.active]="currentUrl == '/personalDailyReportList'"> -->
            <!--   <div class="sb-nav-link-icon"> -->
            <!--     <i class="fas fa-user-clock"></i> -->
            <!--   </div> -->
            <!--   Duyệt báo cáo ngày -->
            <!-- </a> -->
          </div>
        </div>
      </nav>
    </div>
    <div id="layoutSidenav_content">
      <router-outlet></router-outlet>
      <footer class="mt-auto"></footer>
    </div>
  </div>

  <div class="overlay" *ngIf="global.pageLoading" style="z-index: 999999999999999">
    <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</body>
