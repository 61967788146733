<ng-template #createCommandModal let-modal>
  <div class="createCommandModal-modal actions-modal">
    <div class="modal-header">
      Taọ lệnh upload sản phẩm Depop
    </div>

    <div class="modal-body">
      <form>

        <div class="form-group">
          <label>Tài khoản</label>
          <ng-select [items]="merchants"
                     [multiple]="false"
                     placeholder="Chọn Tài Khoản"
                     bindValue="merchantId"
                     bindLabel="name"
                     [closeOnSelect]="true"
                     [(ngModel)]="command.merchantId"
                     (change)="autoDropboxFolder()"
                     name="accountName">
          </ng-select>
        </div>

        <div class="form-group">
          <label>Thư mục dropbox</label>
          <input class="form-control" name="dropboxPath" [(ngModel)]="command.dropboxFolder" />
        </div>

        <div class="form-group">
          <label>Link Sản Phẩm mẫu</label>
          <input class="form-control" name="sampleLink" [(ngModel)]="command.sampleLink" />
        </div>

        <div class="form-group">
          <label>Ship</label>
          
          <div>
            <label class="form-check-label">
              Depop Ship
              <input type="radio" name="group1" [value]="false" [(ngModel)]="command.isManualShip" [checked]="!command.isManualShip">
            </label>
            <br>
            <label class="form-check-label">
              Manual Ship 
              <input type="radio" name="group1" [value]="true" [(ngModel)]="command.isManualShip" [checked]="command.isManualShip">
            </label>
          </div>
        </div>

      </form>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="createUploadDepopCommand()">Tạo Lệnh</button>
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Hủy bỏ</button>
    </div>
  </div>
</ng-template>
