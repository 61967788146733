<main>
  <div class="container-fluid" *ngIf="!reportLoading">
    <h1 class="mt-4">Daily Report</h1>
    <ol class="breadcrumb mb-4">
      <li class="breadcrumb-item active">Báo cáo cá nhân theo ngày</li>
    </ol>
    <div class="col-12">
      <div>
        <div class="row">
          <div class="col-3 row-eq-height">
            <input class="form-control form-control-sm" [owlDateTimeTrigger]="reportDate"
                   [owlDateTime]="reportDate" [(ngModel)]="query.reportDate"
                   placeholder="Từ ngày" />
            <owl-date-time [pickerType]="'calendar'" #reportDate></owl-date-time>
          </div>
          <div class="col-3 row-eq-height">
            <input class="form-control form-control-sm" [owlDateTimeTrigger]="reportDateTo"
                   [owlDateTime]="reportDateTo" [(ngModel)]="query.reportDateTo"
                   placeholder="Đến ngày" />
            <owl-date-time [pickerType]="'calendar'" #reportDateTo></owl-date-time>
          </div>
          <div class="col-3 row-eq-height" *ngIf="isManager">
            <ng-select [items]="teamMembers"
                       placeholder="Nhân viên" bindValue="id" bindLabel="userName"
                       [(ngModel)]="query.targetUserId">
            </ng-select>

          </div>

          <div class="col-3 row-eq-height">
            <button class="btn btn-primary" (click)="genReports()">Tạo báo cáo</button>
          </div>
        </div>
      </div>

      <div class="header bg-gradient-primary">
        <div class="container-fluid">
          <h2 class="mb-5 text-white">Tổng các Team</h2>
          <div class="header-body">
            <div class="row">
              <div class="col-xl-3 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Seller Check</h5>
                        <span class="h2 font-weight-bold mb-0">{{reportData.totalSellerCheck}}</span>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i class="fas fa-user-check"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Design</h5>
                        <span class="h2 font-weight-bold mb-0">{{reportData.totalDes}}</span>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-paint-roller"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Fulfill</h5>
                        <span class="h2 font-weight-bold mb-0">{{reportData.totalFulfill}}</span>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i class="fas fa-cash-register"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Add Track TK</h5>
                        <span class="h2 font-weight-bold mb-0">{{reportData.totalAddTrack}}</span>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i class="fas fa-truck-loading"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 20px;">
        <div class="col-12 row-eq-height">
          <div class="col-12" *ngFor="let teamReport of reportData.employeeToTeamReports">
            <div class="alert alert-primary" role="alert">
              <h6>{{teamReport.teamName}}</h6>
              <hr>

              <table class="table table-bordered team-table">
                <!-- <tr> -->
                <!--   <td colspan="6"> -->
                <!--     Hệ thống tính toán: -->
                <!--   </td> -->
                <!-- </tr> -->

                <tr>
                  <td class="text-center">Seller Check - {{teamReport.sellerCheckPoint}}</td>
                  <td class="text-center">Design - {{teamReport.designPoint}}</td>
                  <td class="text-center">Fulfill- {{teamReport.fulfillPoint}}</td>
                  <td class="text-center">Add Track Acc- {{teamReport.addTrackAccPoint}}</td>
                  <td class="text-center">Follow Track- {{teamReport.followTrackPoint}}</td>
                  <td class="text-center">N/A</td>
                </tr>

                <!-- <tr> -->
                <!--   <td colspan="6"> -->
                <!--     Xác nhận của Leader: -->
                <!--   </td> -->
                <!-- </tr> -->
                <!-- <tr> -->
                <!--   <td class="text-center"> -->
                <!--     Seller Check -->
                <!--     <input class="form-control form-control-sm" [disabled]="teamReport.state !== 1 || !isManager" [(ngModel)]="teamReport.confirmedSellerCheckPoint" type="number" /> -->
                <!--   </td> -->
                <!--   <td class="text-center"> -->
                <!--     Design -->
                <!--     <input class="form-control form-control-sm" [disabled]="teamReport.state !== 1 || !isManager" [(ngModel)]="teamReport.confirmedDesignPoint" type="number"/> -->
                <!--   </td> -->
                <!--   <td class="text-center"> -->
                <!--     Fulfill -->
                <!--     <input class="form-control form-control-sm" [disabled]="teamReport.state !== 1 || !isManager" [(ngModel)]="teamReport.confirmedFulfillRecord" type="number"/> -->
                <!--   </td> -->
                <!--   <td class="text-center"> -->
                <!--     Add Track Acc -->
                <!--     <input class="form-control form-control-sm" [disabled]="teamReport.state !== 1 || !isManager" [(ngModel)]="teamReport.confirmedAddTrackAccPoint" type="number"/> -->
                <!--   </td> -->
                <!--   <td class="text-center"> -->
                <!--     Follow Track -->
                <!--     <input class="form-control form-control-sm" [disabled]="teamReport.state !== 1 || !isManager" [(ngModel)]="teamReport.confirmedDesignPoint" type="number"/> -->
                <!--   </td> -->
                <!--   <td class="text-center"> -->
                <!--     Khác -->
                <!--     <input class="form-control form-control-sm" [disabled]="teamReport.state !== 1 || !isManager" [(ngModel)]="teamReport.otherPoint" type="number"/> -->
                <!--   </td> -->
                <!-- </tr> -->

              </table>

              <!-- <div class="row daily-report-action"> -->
              <!--   <div class="col-6 row-eq-height"> -->
              <!--     <textarea class="form-control" -->
              <!--               [(ngModel)]="teamReport.employeeExplain" -->
              <!--               rows="7" -->
              <!--               placeholder="Ý kiến của nhân viên" -->
              <!--               [disabled]="teamReport.state !== 0 || isManager"></textarea> -->
              <!-- -->
              <!--     <button class="btn btn-primary" -->
              <!--             [disabled]="teamReport.state !== 0 || isManager" -->
              <!--             (click)="sendToTeamLeader(teamReport)"> -->
              <!--       Gửi Team Leader -->
              <!--     </button> -->
              <!--   </div> -->
              <!--   <div class="col-6 row-eq-height"> -->
              <!--     <textarea class="form-control" -->
              <!--               [(ngModel)]="teamReport.managerNote" -->
              <!--               rows="7" placeholder="Ý kiến của Team Leader" -->
              <!--               [disabled]="teamReport.state !== 1 || !isManager"></textarea> -->
              <!--     <div class="row"> -->
              <!--       <div class="col-6"><button class="btn btn-warning" [disabled]="teamReport.state !== 1 || !isManager" -->
              <!--                                  (click)="leaderAction(teamReport, 'refuse')">Team Leader Từ Chối</button></div> -->
              <!--       <div class="col-6"><button class="btn btn-primary" [disabled]="teamReport.state !== 1 || !isManager" -->
              <!--                                  (click)="leaderAction(teamReport, 'approval')">Team Leader Xác Nhận</button></div> -->
              <!--     </div> -->
              <!--   </div> -->
              <!-- </div> -->
            </div>
            <app-sale-record-list [title]="'Đơn Seller Check'"
                                  [records]="teamReport.sellerCheckRecords"></app-sale-record-list>

            <app-sale-record-list [title]="'Đơn Design'"
                                  [records]="teamReport.designRecords"
                                  [mockupRecords]="teamReport.designPreProducts"></app-sale-record-list>

            <app-sale-record-list [title]="'Đơn Fulfill'"
                                  [records]="teamReport.fulfillRecords"></app-sale-record-list>

            <app-sale-record-list [title]="'Đơn Add Track'"
                                  [records]="teamReport.addTrackAccRecords"></app-sale-record-list>

            <app-sale-record-list [title]="'Đơn Follow Track'"
                                  [records]="teamReport.followTrackRecords"></app-sale-record-list>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-report-loading *ngIf="reportLoading"></app-report-loading>
</main>


