<button type="button" class="btn btn-sm btn-primary" (click)="openBatchActionModal()">
  <i class="fas fa-stream"></i>
  Đa tác vụ
</button>

<ng-template #batchActionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Đa tác vụ</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group row">
        <label class="col-sm-12 col-form-label text-red">Tác vụ này có ảnh hưởng đến {{selectedRecords.length}} bản ghi</label>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label">Loại tác vụ</label>
        <div class="col-sm-8">
          <ng-select [items]="actionTypes" name="actionTypes"
                     [(ngModel)]="selectedActionType">
          </ng-select>
        </div>
      </div>
      <div class="form-group row" [hidden]="selectedActionType == undefined || selectedActionType.value !== 0">
        <label class="col-sm-4 col-form-label">Trạng thái đơn muốn chuyển</label>
        <div class="col-sm-8">
          <ng-select [items]="progressStates" name="progressStates"
                     [(ngModel)]="selectedState">
          </ng-select>
        </div>
      </div>
    </form>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">Hủy</button>
    <button type="button" class="btn btn-primary" (click)="processBatchAction()">Thực hiện</button>
  </div>
</ng-template>
