import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http/http";
import { UrlHelper } from "../../classes/urlHelper";

@Injectable({
  providedIn: 'root'
})
export class MockupService {
  constructor(private httpClient: HttpClient) {}

  getAvailableDesigners() {
    return this.httpClient.get<any>('/api/PreProducts/getAvailableDesigners');
  }

  getMockupEdit(mockupId) {
    return this.httpClient.get<any>('/api/PreProducts/getPreProductEdit/' + mockupId);
  }

  saveMockup(mockupDetail: any) {
    return this.httpClient.post<any>('/api/PreProducts/savePreProduct', mockupDetail);
  }

  query(query: any) {
    return this.httpClient.get<any>('/api/PreProducts/query/' + new UrlHelper().parseToUrlParams(query, 'query.'));
  }

  updateSingleValue(mockup, type) {
    return this.httpClient.post<any>('api/PreProducts/UpdateSingleValue/?type=' + type, mockup);
  }

  getLogs(mockup: any) {
    return this.httpClient.get<any>('api/PreProducts/getLogs?preProductId=' + mockup.id);
  }
}
