import { Component, OnInit, Pipe, PipeTransform, Input } from '@angular/core';
import { UserService } from "../../../services/user/user.service";
import { LogTimeService } from "../../../services/log-time/log-time.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sale-record-list',
  templateUrl: './sale-record-list.component.html',
  styleUrls: ['./sale-record-list.component.scss']
})
export class SaleRecordListComponent implements OnInit {
  userData: any;
  constructor(protected userService: UserService,
    private logTimeService: LogTimeService,
    private toastr: ToastrService) {
    this.userData = userService.getLocalUserInfo();
  }

  @Input() title: string;
  @Input() records: any[];
  @Input() mockupRecords: any[];

  ngOnInit(): void {
  }

  desPoints: any = [0,1,2,3,4,5];

  shortLinkForText(text, threshold = 40): string {
    var result = this.shortLinkForTextWithSplitChar(text, threshold, ' ');
    result = this.shortLinkForTextWithSplitChar(result, threshold, '\n');
    return result;
  }

  shortLinkForTextWithSplitChar(text, threshold, splitchar) {
    if (!text) {
      return '';
    }
    const words = text.split(splitchar);
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      if (word.startsWith("http") && word.length > threshold) {
        words[i] = word.slice(0, threshold) + "...";
      }
    }

    var result = words.join(splitchar);
    return result;
  }

  async updateDesPointFromManager(record, type) {
    var targetId = '';
    if (type == 'SaleRecord') {
      targetId = record.orderItemId;
    }
    if (type == 'Mockup') {
      targetId = record.id;
    }
    this.logTimeService.updateDesPointFromManager(targetId, record.desPoint)
      .subscribe(() => {
        this.toastr.success('Điểm design được cập nhật.');
      });
    
  }
}
