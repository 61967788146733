import { Component, OnInit } from '@angular/core';
import { UserService } from "../../services/user/user.service";
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NeedAuthorize } from "../../classes/needAuthorize";
import { Global } from "../../classes/global";

@Component({
  selector: 'app-account-admin',
  templateUrl: './account-admin.component.html',
  styleUrls: ['./account-admin.component.scss']
})
export class AccountAdminComponent extends NeedAuthorize {

  query = {
    queryString: '',
    pageSize: 10,
    pageNumber: 1
  };

  accounts: any =[];

  constructor(protected router: Router, protected userService: UserService, protected titleService: Title, private global: Global) {
    super(router, userService, titleService);
    this.queryUsers();
  }

  queryUsers() {
    this.global.pageLoading = true;
    this.userService.queryUser(this.query).subscribe(data => {
      this.accounts = data;
      this.global.pageLoading = false;
    });
  }

  ngOnInit(): void {
  }

}
