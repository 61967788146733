<main>
<div class="container-fluid">
<h1 class="mt-4">Orders</h1>
<ol class="breadcrumb mb-4">
  <li class="breadcrumb-item active">Orders</li>
</ol>
<div class="clearfix"></div>

<div class="col-12 mb-4 actions">
  <div class="float-left">
    <button type="button" class="btn-custom btn-outline-info-custom mr-1"
            (click)="toggleQuickFilter('isNeedSellerCheck')" [class.active]="query.isNeedSellerCheck"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-user-check"></i>
      Cần Seller check
      <span class="badge badge-info">{{filterCount.needSellerCheckCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-danger-custom mr-1" (click)="toggleQuickFilter('isNoticed')"
            [class.active]="query.isNoticed"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-flag"></i>
      Cần Xác Minh
      <span class="badge badge-danger">{{filterCount.needConfirmCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-warning-custom mr-1"
            (click)="toggleQuickFilter('isNeedFindLink')" [class.active]="query.isNeedFindLink">
      <i class="fas fa-paint-roller"></i>
      Cần Design
      <span class="badge badge-warning">{{filterCount.needDesignCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-info-custom mr-1"
            (click)="toggleQuickFilter('isNeedFulfill')" [class.active]="query.isNeedFulfill"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-cash-register"></i>
      Cần Fulfill
      <span class="badge badge-info">{{filterCount.needFulfillCount}}</span>
    </button>
    <button type="button" class="btn btn-outline-success mr-1" (click)="toggleQuickFilter('isNeedShip')"
            [class.active]="query.isNeedShip"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-truck-loading"></i>
      Đợi Tracking
      <span class="badge badge-success">{{filterCount.waitTrackCount}}</span>
    </button>
    <button type="button" class="btn btn-outline-primary mr-1"
            (click)="toggleQuickFilter('isNeedAddTrackToAccount')" [class.active]="query.isNeedAddTrackToAccount"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-truck-loading"></i>
      Cần Add Track TK
      <span class="badge badge-primary">{{filterCount.needAddTrackToAccountCount}}</span>
    </button>
    <button type="button" class="btn btn-outline-primary mr-1" (click)="toggleQuickFilter('isFollowTrack')"
            [class.active]="query.isFollowTrack"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-truck"></i>
      Đang theo dõi tracking
      <span class="badge badge-primary">{{filterCount.followTrackCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-info-custom mr-1" [class.active]="query.isDone"
            (click)="toggleQuickFilter('isDone')"
            *ngIf="userData.roles.indexOf('Designer Online') == -1">
      <i class="fas fa-check-square"></i>
      Done
    </button>
  </div>
</div>
<div class="clearfix"></div>
<div class="col-12 mt-10 mb-4 actions" *ngIf="userData.roles.indexOf('Designer Online') == -1">
  <div class="float-left">
    <button type="button" class="btn-custom btn-outline-danger-custom mr-1"
            (click)="toggleQuickFilter('isWarningLateTrack')" [class.active]="query.isWarningLateTrack">
      <i class="fas fa-exclamation-triangle"></i>
      5 Ngày Chưa Trả Track
      <span class="badge badge-danger">{{filterCount.lateTrackingCount}}</span>
    </button>
    <button type="button" class="btn-custom btn-outline-danger-custom mr-1"
            (click)="toggleQuickFilter('isWarningLateDelivery')" [class.active]="query.isWarningLateDelivery">
      <i class="fas fa-exclamation-triangle"></i>
      10 Ngày Chưa Delivery
      <span class="badge badge-danger">{{filterCount.lateDeliveryCount}}</span>
    </button>
  </div>
</div>
<div class="clearfix"></div>
<div class="col-12 mt-10 actions" *ngIf="userData.roles.indexOf('Designer Online') == -1">
  <div class="float-right">
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="openImportSaleRecordModal()">
      <i class="fas fa-cloud-upload-alt"></i>
      Import Đơn Ebay/Etsy
    </button>
    <button type="button" class="btn btn-sm  btn-smal btn-primary mr-1" (click)="downloadExportFileForPrintWay()">
      <i class="fas fa-ruler-combined"></i>
      Export PrintWay
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="preConfirmBuyApi('printway')">
      <i class="fa fa-rocket" aria-hidden="true"></i>
      Mua Printway - Api
    </button>
    <button type="button" class="btn btn-sm  btn-smal btn-primary mr-1" (click)="downloadExportFileForGeargag()">
      <i class="fas fa-ruler-combined"></i>
      Export Geargag
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="downloadExportFileForArtsAdd()">
      <i class="fas fa-ruler-combined"></i>
      Export ArtsAdd
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="downloadExportFileForGearment()">
      <i class="fas fa-ruler-combined"></i>
      Export Gearment
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="downloadExportFileForMerchize()">
      <i class="fas fa-ruler-combined"></i>
      Export Merchize
    </button>

    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="preConfirmBuyApi('merchize')">
      <i class="fa fa-rocket" aria-hidden="true"></i>
      Mua Merchize - Api
    </button>

    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="downloadExportFileForFlashship()">
      <i class="fas fa-ruler-combined"></i>
      Export Flashship
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="preConfirmBuyApi('flashship')">
      <i class="fa fa-rocket" aria-hidden="true"></i>
      Mua Flashship - Api
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="downloadExportFileForBestPrintCare()">
      <i class="fas fa-ruler-combined"></i>
      Export Best Print Care
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="preConfirmBuyApi('bestPrintCare')">
      <i class="fa fa-rocket" aria-hidden="true"></i>
      Mua Best Print Care - Api
    </button>

    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="preConfirmBuyApi('printify')">
      <i class="fa fa-rocket" aria-hidden="true"></i>
      Mua Printify - Api
    </button>
    <button type="button" class="btn btn-sm btn-primary mr-1" (click)="querySaleRecords()">
      <i class="fas fa-redo"></i>
      Refresh Table
    </button>
    <button type="button" class="btn btn-sm btn-primary" (click)="clearFilter()">
      <i class="fas fa-eraser"></i>
      Clear Filter
    </button>
  </div>
  <div class="clearfix"></div>
</div>
<div class="col-12 mt-10 mb-4 actions">
  <div class="float-left">
    <app-sale-record-batch-action [saleRecords]="this.saleRecords" (batchActionDone)="querySaleRecords()"></app-sale-record-batch-action>
  </div>
  <div class="clearfix"></div>
</div>
<div class="table-responsive order-table">
  <table class="table table-bordered table-hover" *ngIf="userData.roles.indexOf('Fulfill') == -1
                                                              && userData.roles.indexOf('Support') == -1">
    <tr>
      <th class="text-center" width="40px"></th>
      <th class="text-center" width="100px">Buy/Ship Date</th>
      <th class="text-center" width="143px">Source Order Id</th>
      <th width="150px">Customer Name</th>
      <th class="text-center" width="135px">SKU</th>
      <th class="text-right" width="85px">Customer Charged</th>
      <th class="text-center" width="143px">Destination Order Id</th>
      <th class="text-right" width="85px">Product Price</th>
      <th class="text-right" width="85px">Order Price</th>
      <th width="205px">Tracking</th>
      <th>Note</th>
      <th class="text-right" width="85px">Order Refund</th>
      <th width="205px">Account</th>
      <th width="250px">Actions</th>
    </tr>
    <tr>
      <th class="text-center">
        <label>
          <input type="checkbox" [(ngModel)]="checkAll" (click)="toggleCheckAll()">
        </label>
      </th>
      <th class="text-center">
        <input class="form-control form-control-sm" [owlDateTimeTrigger]="purchaseDate" [owlDateTime]="purchaseDate"
               [(ngModel)]="query.purchaseDate" (ngModelChange)="querySaleRecords(1)" placeholder="Buy Date">
        <owl-date-time [pickerType]="'calendar'" #purchaseDate></owl-date-time>
        <input class="form-control form-control-sm" [owlDateTimeTrigger]="shipDueDate" [owlDateTime]="shipDueDate"
               [(ngModel)]="query.shipDueDate" (ngModelChange)="querySaleRecords(1)" placeholder="Ship Date">
        <owl-date-time [pickerType]="'calendar'" #shipDueDate></owl-date-time>
      </th>
      <th class="text-center">
        <input class="form-control form-control-sm" [(ngModel)]="query.sourceOrderId"
               (ngModelChange)="querySaleRecords(1)" placeholder="Source Order Id">
      </th>
      <th>
        <input class="form-control form-control-sm" [(ngModel)]="query.customerName"
               (ngModelChange)="querySaleRecords(1)" placeholder="Customer Name">
      </th>
      <th class="text-center">
        <input class="form-control form-control-sm" [(ngModel)]="query.sku" (ngModelChange)="querySaleRecords(1)"
               placeholder="SKU">
      </th>
      <th class="text-right"></th>
      <th class="text-center">
        <input class="form-control form-control-sm" [(ngModel)]="query.destinationOrderId"
               (ngModelChange)="querySaleRecords(1)" placeholder="Destination Order Id">
        <select class="form-control" [(ngModel)]="query.wantedPrintService" (ngModelChange)="querySaleRecords(1)">
          <option value="" selected>Toàn bộ nhà in</option>
          <option value="Flashship">Flashship</option>
          <option value="Flashship Ornament">Flashship Ornament</option>
          <option value="Printcare">Printcare</option>
          <option value="Merchize">Merchize</option>
          <option value="Printify">Printify</option>
          <option value="Gearment">Gearment</option>
          <option value="Geargag">Geargag</option>
          <option value="Artsadd">Artsadd</option>
          <option value="Printway">Printway</option>
          <option value="Yoycol">Yoycol</option>
          <option value="Rabful">Rabful</option>
          <option value="BurgerPrint">BurgerPrint</option>
          <option value="Nhà in khác">Nhà in khác</option>
        </select>
      </th>
      <th class="text-right"></th>
      <th class="text-right"></th>
      <th>
        <input class="form-control form-control-sm" [(ngModel)]="query.trackingNumber"
               (ngModelChange)="querySaleRecords(1)" placeholder="Tracking">
      </th>
      <th>
        <input class="form-control form-control-sm" [(ngModel)]="query.note" (ngModelChange)="querySaleRecords(1)"
               placeholder="Note">
      </th>
      <th class="text-right"></th>
      <th>
        <ng-select [items]="merchantGroups" [multiple]="true" class="table-select-filter"
                   placeholder="Toàn bộ các team" bindValue="id" bindLabel="name" [closeOnSelect]="false"
                   (close)="updateMerchantGroupFilter()" (clear)="updateMerchantGroupFilter()"
                   [(ngModel)]="query.merchantGroupIds">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
          </ng-template>
        </ng-select>

        <ng-select [items]="merchants" [multiple]="true" class="table-select-filter" placeholder="Toàn bộ tài khoản"
                   bindValue="merchantId" bindLabel="name" [closeOnSelect]="false" (close)="querySaleRecords(1)"
                   (clear)="querySaleRecords(1)" [(ngModel)]="query.merchantIds">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}


          </ng-template>
        </ng-select>
      </th>
      <th>
      </th>
    </tr>
    <tbody>
      <tr *ngFor="let record of saleRecords" [class.warning]="record.isScamWarning">
        <td class="text-center">
          <label>
            <input type="checkbox" [(ngModel)]="record.isSelected">
          </label>
        </td>

        <td class="text-center">
          {{record.createdDateString}}
          <br />
          {{record.createdTimeString}}
          <br />

          <span class="badge badge-pill"
                [class.badge-primary]="record.sourceOrderType == 'Amazon'"
                [class.badge-warning]="record.sourceOrderType == 'Ebay'"
                [class.badge-success]="record.sourceOrderType == 'Etsy'"
                [class.badge-info]="record.sourceOrderType == 'Depop'"
                [class.badge-secondary]="record.sourceOrderType == 'TikTok'">
            {{record.sourceOrderType}}
            <span *ngIf="record.isPrepaidLabel"> - Prepaid</span>
          </span>

          <br />
          <app-split-order-item [saleRecord]="record" (splitOrderDone)="querySaleRecords()"></app-split-order-item>

        </td>
        <td class="text-center can-copy" ngxClipboard [cbContent]="record.sourceOrderId"
            (cbOnSuccess)="onCoppied(record, 'sourceOrderId')" [class.coppied]="record.sourceOrderIdCoppied">
          <span>{{record.sourceOrderId}}</span>
          <br />
          <b>{{getCustomization(record.customizeInfo, 1)}}</b>
          <br />
          <b>{{getCustomization(record.customizeInfo, 4)}}</b>
          <br />
          <b>{{getCustomization(record.customizeInfo, 7)}}</b>
        </td>
        <td class="can-copy" ngxClipboard [cbContent]="record.customerName"
            (cbOnSuccess)="onCoppied(record, 'customerName')" [class.coppied]="record.customerNameCoppied">
          <span>{{record.customerName}}</span>
          <br />
          <b>{{getCustomization(record.customizeInfo, 2)}}</b>
          <br />
          <b>{{getCustomization(record.customizeInfo, 5)}}</b>
          <br />
          <b>{{getCustomization(record.customizeInfo, 9)}}</b>
        </td>
        <td class="text-center can-copy" ngxClipboard [cbContent]="record.sku"
            (cbOnSuccess)="onCoppied(record, 'asin')" [class.coppied]="record.asinCoppied">
          {{record.sku}} (Q - {{record.productQuantity}})
          <br />
          <b>{{getCustomization(record.customizeInfo, 3)}}</b>
          <br />
          <b>{{getCustomization(record.customizeInfo, 6)}}</b>
          <br />
          <b>{{getCustomization(record.customizeInfo, 10)}}</b>
        </td>
        <td class="text-right">
          ${{record.customerChargedTotal}}
        </td>
        <td class="text-center">
          <span class="can-copy" (cbOnSuccess)="onCoppied(record, 'destinationOrderId')" ngxClipboard
                [cbContent]="record.destinationOrderId" [class.coppied]="record.destinationOrderIdCoppied">
            {{record.destinationOrderId}}
          </span>
          <br />
          <span class="badge badge-pill badge-info" *ngIf="record.wantedPrintService">
            {{record.wantedPrintService}}
          </span>
        </td>
        <td class="text-right" ngxClipboard [cbContent]="record.productPrice"
            (cbOnSuccess)="onCoppied(record, 'productPrice')" [class.coppied]="record.productPriceCoppied">
          {{record.productPrice}}
        </td>
        <td class="text-right" ngxClipboard [cbContent]="record.orderPrice"
            (cbOnSuccess)="onCoppied(record, 'orderPrice')" [class.coppied]="record.orderPriceCoppied">
          {{record.orderPrice}}
        </td>
        <td ngxClipboard [cbContent]="record.TrackingNumber" (cbOnSuccess)="onCoppied(record, 'TrackingNumber')"
            [class.coppied]="record.trackingNumberCoppied">
          {{record.trackingNumber}}
        </td>
        <td>
          <app-editable [data]="record.note" [dataType]="'textarea'"
                        (focusOut)="updateSingleValue(record, 'Note', $event)">
          </app-editable>
        </td>
        <td class="text-right">
          <app-editable [data]="record.orderRefund" [dataType]="'number'"
                        (focusOut)="updateSingleValue(record, 'OrderRefund', $event)">
          </app-editable>
        </td>
        <td>
          {{record.merchantName}}
          <br />
          ({{record.channel}})
          <br />
          <br />

          <span *ngIf="record.sellerName">
            Seller: <strong>{{record.sellerName}}</strong>
          </span>
          <br />
          <span *ngIf="record.designerName">
            Designer: <strong>{{record.designerName}}</strong>
          </span>
          <br />
          <span>
            Điểm Designer: <strong>{{record.desPoint}}</strong>
            <br />
            <i *ngIf="record.currentlyDesign" class="fas fa-play" style="color: #74C0FC;"></i>
          </span>
        </td>
        <td class="saler-record-actions-container">
          <div class="text-center">
            <button *ngIf="record.progressState == 0 && userData.fromSellerToAbove"
                    class="btn-custom btn-outline-info-custom main-act active" (click)="openProductLinkModal(record)"
                    [class.text-red]="record.sourceOrderId.length == 10 && !record.sourceProductImage "
                    title="Seller Check">
              <i class="fas fa-user-check"></i>
              Seller Check
            </button>

            <button class="btn-custom btn-outline-warning-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 10 && userData.canSeeNeedDesign">
              <i class="fas fa-paint-roller"></i>
              Cần Design
            </button>

            <button class="btn-custom btn-outline-info-custom main-act active" (click)="openProductLinkModal(record)"
                    [class.text-red]="record.stillGetLink"
                    *ngIf="record.progressState == 20 && userData.canSeeNeedFulfill">
              <i class="fas fa-cash-register"></i>
              Cần Fulfill
            </button>

            <button class="btn-custom btn-outline-success-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 30 &&  userData.canSeeNeedFulfill">
              <i class="fas fa-truck-loading"></i>
              Đợi Track
            </button>

            <button class="btn-custom btn-outline-primary-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 35 && userData.canSeeNeedSupport">
              <i class="fas fa-truck-loading"></i>
              Add track
            </button>

            <button class="btn-custom btn-outline-primary-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 40 && userData.canSeeNeedSupport">
              <i class="fas fa-truck"></i>
              Follow track
            </button>

            <button class="btn-custom btn-outline-info-custom main-act active"
                    *ngIf="record.progressState == 50 && userData.canSeeNeedSupport"
                    (click)="openProductLinkModal(record)">
              <i class="fas fa-check-square"></i>
              Done
            </button>

            <button class="btn-custom btn-outline-danger-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 5">
              <i class="fas fa-flag"></i>
              Xác minh
            </button>

            <br />
            <button class="btn-custom btn-outline-secondary-custom"
                    (click)="updateSingleValue(record, 'IsCanceled', $event)" [class.active]="record.isCanceled"
                    *ngIf="userData.fromSellerToAbove"
                    title="Cancel đơn">
              <i class="fas fa-times"></i>
            </button>

            <button class="btn-custom btn-outline-danger-custom active" (click)="confirmDeleteSaleRecord(record)"
                    *ngIf="userData.fromSellerToAbove"
                    title="Xóa đơn">
              <i class="fas fa-trash"></i>
            </button>

            <select class="form-control mt-10 mb-1" [(ngModel)]="record.progressState"
                    (ngModelChange)="updateSingleValue(record, 'ProgressState', record.progressState)">
              <option *ngFor="let p of progressStates" [value]="p.value">{{ p.name }}</option>
            </select>
          </div>

        </td>
      </tr>

    </tbody>
  </table>


  <table class="table table-bordered table-hover" *ngIf="userData.roles.indexOf('Fulfill') >
    -1
    || userData.roles.indexOf('Support') > -1">
    <tr>
      <th class="text-center" width="40px"></th>
      <th class="text-center" width="100px">Buy/Ship Date</th>
      <th class="text-center">Đơn nguồn</th>
      <th class="text-center">SKU</th>
      <th>Note</th>
      <th class="text-center">Nhà in</th>
      <th class="text-center" width="143px">Đơn Fulfill</th>
      <th class="text-right" width="85px">Product Price</th>
      <th class="text-right" width="85px">Order Price</th>
      <th class="text-right" width="85px">Refund</th>
      <th width="205px">Tracking</th>
      <th>Account</th>
      <th width="250px">Actions</th>
    </tr>
    <tr>
      <th class="text-center">
        <label>
          <input type="checkbox" [(ngModel)]="checkAll" (click)="toggleCheckAll()">
        </label>
      </th>
      <th class="text-center">
        <input class="form-control form-control-sm" [owlDateTimeTrigger]="purchaseDate" [owlDateTime]="purchaseDate"
               [(ngModel)]="query.purchaseDate" (ngModelChange)="querySaleRecords(1)" placeholder="Buy Date">
        <owl-date-time [pickerType]="'calendar'" #purchaseDate></owl-date-time>
        <input class="form-control form-control-sm" [owlDateTimeTrigger]="shipDueDate" [owlDateTime]="shipDueDate"
               [(ngModel)]="query.shipDueDate" (ngModelChange)="querySaleRecords(1)" placeholder="Ship Date">
        <owl-date-time [pickerType]="'calendar'" #shipDueDate></owl-date-time>
      </th>
      <th class="text-center">
        <input class="form-control form-control-sm" [(ngModel)]="query.sourceOrderId"
               (ngModelChange)="querySaleRecords(1)" placeholder="Đơn nguồn">
      </th>
      <th>
        <input class="form-control form-control-sm" [(ngModel)]="query.sku" (ngModelChange)="querySaleRecords(1)"
               placeholder="SKU">
      </th>
      <th>
        <input class="form-control form-control-sm" [(ngModel)]="query.note" (ngModelChange)="querySaleRecords(1)"
               placeholder="Note">
      </th>
      <th>
        <select class="form-control" [(ngModel)]="query.wantedPrintService" (ngModelChange)="querySaleRecords(1)">
          <option value="" selected>Toàn bộ nhà in</option>
          <option value="Flashship">Flashship</option>
          <option value="Flashship Ornament">Flashship Ornament</option>
          <option value="Printcare">Printcare</option>
          <option value="Merchize">Merchize</option>
          <option value="Printify">Printify</option>
          <option value="Gearment">Gearment</option>
          <option value="Geargag">Geargag</option>
          <option value="Artsadd">Artsadd</option>
          <option value="Printway">Printway</option>
          <option value="Yoycol">Yoycol</option>
          <option value="Rabful">Rabful</option>
          <option value="BurgerPrint">BurgerPrint</option>
          <option value="Nhà in khác">Nhà in khác</option>
        </select>
      </th>
      <th class="text-center">
        <input class="form-control form-control-sm" [(ngModel)]="query.destinationOrderId"
               (ngModelChange)="querySaleRecords(1)" placeholder="Đơn Fulfill">
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <input class="form-control form-control-sm" [(ngModel)]="query.trackingNumber"
               (ngModelChange)="querySaleRecords(1)" placeholder="Tracking">
      </th>
      <th>
        <ng-select [items]="merchantGroups" [multiple]="true" class="table-select-filter"
                   placeholder="Toàn bộ các team" bindValue="id" bindLabel="name" [closeOnSelect]="false"
                   (close)="updateMerchantGroupFilter()" (clear)="updateMerchantGroupFilter()"
                   [(ngModel)]="query.merchantGroupIds">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}



          </ng-template>
        </ng-select>

        <ng-select [items]="merchants" [multiple]="true" class="table-select-filter" placeholder="Toàn bộ tài khoản"
                   bindValue="merchantId" bindLabel="name" [closeOnSelect]="false" (clear)="querySaleRecords(1)"
                   (close)="querySaleRecords(1)" [(ngModel)]="query.merchantIds">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}



          </ng-template>
        </ng-select>
      </th>
      <th></th>
    </tr>
    <tbody>
      <tr *ngFor="let record of saleRecords" [class.warning]="record.isScamWarning">
        <td class="text-center">
          <label>
            <input type="checkbox" [(ngModel)]="record.isSelected">
          </label>
        </td>
        <td class="text-center">
          {{record.createdDateString}}
          <br />
          {{record.createdTimeString}}
          <br />

          <span class="badge badge-pill"
                [class.badge-primary]="record.sourceOrderType == 'Amazon'"
                [class.badge-warning]="record.sourceOrderType == 'Ebay'"
                [class.badge-success]="record.sourceOrderType == 'Etsy'"
                [class.badge-info]="record.sourceOrderType == 'Depop'">
            {{record.sourceOrderType}}
            <span *ngIf="record.isPrepaidLabel"> - Prepaid</span>
          </span>
          <br />
          <app-split-order-item [saleRecord]="record" (splitOrderDone)="querySaleRecords()"></app-split-order-item>

          <div class="form-check mt-10">
            <input class="form-check-input" type="checkbox" [id]="record.orderItemId" [(ngModel)]="record.isPhysical" (ngModelChange)="updateSingleValue(record, 'IsPhysic', record.isPhysical)">
            <label class="form-check-label" [for]="record.orderItemId">
              Physical
            </label>
          </div>

        </td>
        <td class="can-copy" ngxClipboard [cbContent]="record.sourceOrderId"
            (cbOnSuccess)="onCoppied(record, 'sourceOrderId')" [class.coppied]="record.sourceOrderIdCoppied">
          <b>{{record.sourceOrderId}}</b> <br />

          <span>{{record.customerName}}</span><br />
          <span>{{record.streetAddress}}</span><br />
        </td>
        <td ngxClipboard [cbContent]="record.sku" (cbOnSuccess)="onCoppied(record, 'sku')"
            [class.coppied]="record.skuCoppied">
          <b>{{record.sku}}</b> (Q - {{record.productQuantity}}) <br />
          <span>{{getCustomization(record.customizeInfo, 1)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 2)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 3)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 4)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 5)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 6)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 7)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 8)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 9)}}</span><br />
          <span>{{getCustomization(record.customizeInfo, 10)}}</span><br />

        </td>
        <td>
          <app-editable [data]="record.note" [dataType]="'textarea'"
                        (focusOut)="updateSingleValue(record, 'Note', $event)">
          </app-editable>
        </td>
        <td class="text-center">
          <ng-select class="text-left" [items]="availablePrintConfig.printServices" placeholder="Nhà in"
                     (change)="updatePrintService(record.orderItemId , record.wantedPrintService)"
                     [(ngModel)]="record.wantedPrintService">
          </ng-select>
        </td>
        <td class="text-right">
          <app-editable [data]="record.destinationOrderId" [dataType]="'text'"
                        (focusOut)="updateSingleValue(record, 'DestinationOrderId', $event)">
          </app-editable>
          <span class="badge badge-pill" *ngIf="record.destinationOrderType != ''"
                [class.badge-danger]="record.destinationOrderType == 'Printify'"
                [class.badge-info]="record.destinationOrderType == 'Best Print Care'"
                [class.badge-warning]="record.destinationOrderType == 'Flash Ship'"
                [class.badge-light]="record.destinationOrderType == 'Nhà in khác'">
            {{record.destinationOrderType}}



          </span>
        </td>
        <td class="text-right">
          <app-editable [data]="record.productPrice" [dataType]="'number'"
                        (focusOut)="updateSingleValue(record, 'ProductPrice', $event)">
          </app-editable>
        </td>
        <td class="text-right">
          <app-editable [data]="record.orderPrice" [dataType]="'number'"
                        (focusOut)="updateSingleValue(record, 'OrderPrice', $event)">
          </app-editable>
        </td>
        <td class="text-right">
          <app-editable [data]="record.orderRefund" [dataType]="'number'"
                        (focusOut)="updateSingleValue(record, 'OrderRefund', $event)">
          </app-editable>
        </td>
        <td class="text-right">
          <app-editable [data]="record.trackingNumber" [dataType]="'text'"
                        (focusOut)="updateSingleValue(record, 'TrackingNumber', $event)">
          </app-editable>
        </td>

        <td>
          {{record.merchantName}}
          <br />
          ({{record.channel}})
          <span *ngIf="record.sellerName != undefined">
            Seller: <strong>{{record.sellerName}}</strong>
          </span> <br />
          <span *ngIf="record.designerName != undefined">
            Designer: <strong>{{record.designerName}}</strong>
          </span>
        </td>
        <td class="saler-record-actions-container">
          <div class="text-center">
            <button *ngIf="record.progressState == 0 && userData.canSeeNeedSupport"
                    class="btn-custom btn-outline-info-custom main-act active" (click)="openProductLinkModal(record)"
                    [class.text-red]="record.sourceOrderId.length == 10 && !record.sourceProductImage "
                    title="Seller Check">
              <i class="fas fa-user-check"></i>
              Seller Check
            </button>

            <button class="btn-custom btn-outline-warning-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 10 && userData.canSeeNeedDesign">
              <i class="fas fa-paint-roller"></i>
              Cần Design
            </button>

            <button class="btn-custom btn-outline-info-custom main-act active" (click)="openProductLinkModal(record)"
                    [class.text-red]="record.stillGetLink"
                    *ngIf="record.progressState == 20 && userData.canSeeNeedFulfill">
              <i class="fas fa-cash-register"></i>
              Cần Fulfill
            </button>

            <button class="btn-custom btn-outline-success-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 30 &&  userData.canSeeNeedFulfill">
              <i class="fas fa-truck-loading"></i>
              Đợi Track
            </button>

            <button class="btn-custom btn-outline-primary-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 35 && userData.canSeeNeedSupport">
              <i class="fas fa-truck-loading"></i>
              Add track
            </button>

            <button class="btn-custom btn-outline-primary-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 40 && userData.canSeeNeedSupport">
              <i class="fas fa-truck"></i>
              Follow track
            </button>

            <button class="btn-custom btn-outline-info-custom main-act active"
                    *ngIf="record.progressState == 50 && userData.canSeeNeedSupport"
                    (click)="openProductLinkModal(record)">
              <i class="fas fa-check-square"></i>
              Done
            </button>

            <button class="btn-custom btn-outline-danger-custom main-act active" (click)="openProductLinkModal(record)"
                    *ngIf="record.progressState == 5">
              <i class="fas fa-flag"></i>
              Xác minh
            </button>

            <br />
            <button class="btn-custom btn-outline-secondary-custom"
                    (click)="updateSingleValue(record, 'IsCanceled', $event)" [class.active]="record.isCanceled"
                    *ngIf="userData.fromSellerToAbove"
                    title="Cancel đơn">
              <i class="fas fa-times"></i>
            </button>

            <button class="btn-custom btn-outline-danger-custom active" (click)="confirmDeleteSaleRecord(record)"
                    *ngIf="userData.fromSellerToAbove"
                    title="Xóa đơn">
              <i class="fas fa-trash"></i>
            </button>

            <select class="form-control mt-10 mb-1" [(ngModel)]="record.progressState"
                    (ngModelChange)="updateSingleValue(record, 'ProgressState', record.progressState)">
              <option *ngFor="let p of progressStates" [value]="p.value">{{ p.name }}</option>
            </select>
          </div>

        </td>
      </tr>

    </tbody>
  </table>


  <div class="float-right">
    <app-pagination [(pageNumber)]="query.pageNumber" [pageSize]="query.pageSize" [totalItems]="totalItem"
                    (onPageChanged)="querySaleRecords($event)">
    </app-pagination>
  </div>
</div>
</div>
</main>

<ng-template #productLinkModal let-modal>
<div class="productLinkModal actions-modal">
<div class="modal-header">
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 0" class="modal-title" id="modal-basic-title">
    Seller Check
  </h4>
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 5" class="modal-title" id="modal-basic-title">
    Cần xác minh
  </h4>
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 10" class="modal-title" id="modal-basic-title">
    Upload Design
  </h4>
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 20" class="modal-title" id="modal-basic-title">
    Điền thông
    tin Fulfill
  </h4>
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 30" class="modal-title" id="modal-basic-title">
    Chờ Tracking
  </h4>
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 35" class="modal-title" id="modal-basic-title">
    Add Track TK
  </h4>
  <h4 *ngIf="sellerCheck.currentRecord.progressState == 40" class="modal-title" id="modal-basic-title">
    Đang theo dõi
    Track
  </h4>
</div>

<div class="modal-body">
<div class="form-group">
<div class="col-12">
<div class="row">
<div class="col-6">
  <strong>SẢN PHẨM NGUỒN</strong>
  <strong class="ml-5">
    Đơn nguồn:
    {{sellerCheck.sourceProduct.sourceOrderId}}


  </strong>
  <br />
  <strong>SKU:</strong> {{sellerCheck.sourceProduct.sku}}


  <strong>
    (Qty:
    {{sellerCheck.sourceProduct.productQuantity}})
  </strong>

  <div class="row">
    <div class="col-4">
      <ng-select class="text-left" [items]="sellerCheck.availableColors" placeholder="Màu"
                 (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'color', sellerCheck.calculatedColor)"
                 [addTag]="addNewColor"
                 [(ngModel)]="sellerCheck.calculatedColor">
      </ng-select>
    </div>
    <div class="col-4">
      <ng-select class="text-left" [items]="sellerCheck.availableSizes" placeholder="Size"
                 (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'size', sellerCheck.calculatedSize)"
                 [addTag]="addNewSize"
                 [(ngModel)]="sellerCheck.calculatedSize">
      </ng-select>
    </div>
  </div>

  <strong>{{genSellerCheckCustomizeInfo(sellerCheck.sourceProduct.customizeInfo)}}</strong>
  <br />
  <strong>Tên: </strong>
  <a href="{{sellerCheck.sourceProduct.productLink}}"
     target="_blank">
    {{sellerCheck.sourceProduct.productName}}



  </a>
  <br />
  <img [src]="sellerCheck.sourceProduct.sourceImage" class="img-responsive" />
  <br />
  <div class="above-des-container">
    <strong>Link sản phẩm chọn: </strong>
    <span class="sellerCheckDownloadLink" *ngFor="let link of sellerCheck.downloadLinks; let i = index">
      <a [href]="link">Download {{i + 1}}</a>
    </span>
    <button class="btn btn-sm btn-primary"
            (click)="updateSingleValue(sellerCheck.currentRecord, 'Des', sellerCheck.destinationProductLinkText)"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      Cập nhật design
    </button>
  </div>
  <textarea [disabled]="sellerCheck.currentRecord.progressState > 20" class="w-100"
                [(ngModel)]="sellerCheck.destinationProductLinkText" (change)="genDownloadLinks()" rows="9"></textarea>
  <br />

  <strong>Note:</strong> <br />
  <textarea class="w-100" [(ngModel)]="sellerCheck.sourceProduct.note" rows="3"
            [disabled]="userData.isDesigner"
            (blur)="updateNoteForSellerCheck(sellerCheck.currentRecord, $event)"></textarea><br />

  <div class="row no-padding-side left-actions">
    <div class="col-4 mt-36" *ngIf="sellerCheck.currentRecord.progressState != 5">
      <button class="btn btn-danger" (click)="passToNeedConfirm()">
        <i class="fas fa-flag"></i>
        Cần Xác Minh
      </button>
    </div>

    <div class="col-4 mt-36" *ngIf="sellerCheck.currentRecord.progressState != 0">
      <button class="btn btn-info" (click)="passToSeller()">
        <i class="fas fa-user-check"></i>
        Chuyển sang Seller
      </button>
    </div>

    <div class="col-4"
         *ngIf="sellerCheck.currentRecord.progressState != 10 && sellerCheck.currentRecord.progressState <=20">

      <ng-select class="text-left" [items]="sellerCheck.availableDesigners" bindLabel="userName" placeholder="Chọn Designer"
                 [(ngModel)]="sellerCheck.selectedDesigner">
      </ng-select>

      <button class="btn btn-warning" (click)="passToDesigner()">
        <i class="fas fa-paint-roller"></i>
        Chuyển Sang Designer
      </button>
    </div>

    <div class="col-4"
         *ngIf="sellerCheck.currentRecord.progressState != 20 && sellerCheck.currentRecord.progressState <=30">
      <ng-select class="text-left" [items]="sellerCheck.availablePrintServices" placeholder="Nhà in"
                 [disabled]="userData.isDesigner"
                 (change)="updatePrintService(sellerCheck.sourceProduct.orderItemId ,sellerCheck.sourceProduct.wantedPrintService)"
                 [(ngModel)]="sellerCheck.sourceProduct.wantedPrintService">
      </ng-select>
      <button class="btn btn-info" (click)="passToFulfill()">
        <i class="fas fa-cash-register"></i>
        Chuyển Sang Fulfill
      </button>
    </div>
  </div>
  <div class="row no-padding-side">
    <div class="col-12 mt-10 saleRecordLogs">
      <p *ngFor="let log of saleRecordLogs">
        {{log.createdString}} - <span [innerHTML]="log.logMessage"></span>
      </p>
    </div>
  </div>
</div>

<div class="col-6">
<label class="col-sm-12 text-red" *ngIf="sellerCheck.otherRecordForSourceOrderCount > 0">
  *Có {{sellerCheck.otherRecordForSourceOrderCount}} sản phẩm khác trong cùng một đơn nguồn
</label>

<div class="suggestion" *ngIf="sellerCheck.currentRecord.progressState == 0 ||
                                        sellerCheck.currentRecord.progressState == 10">
  Có {{sellerCheck.suggestProducts.length}} đề xuất

  <span *ngFor="let product of sellerCheck.suggestProducts">
    <div class="image-place-holder-card"
         [class.isActive]="product.label == sellerCheck.selectedProduct?.label"
         *ngIf="!product.ignoredDes">
      <div class="c-header">
        {{product.label}}



        <a class="ignore-des" (click)="ignoreDes(product)">
          <i class="fas fa-times ignore-des"></i>
        </a>
        <a class="ignore-des-totally" (click)="confirmIgnoreDesTotally(product)">
          <i class="fas fa-times ignore-des-totally"></i>
        </a>
      </div>
      <div class="c-body" (click)="selectSuggestProduct(product)">
        <img *ngFor="let link of product.demoLinks" [src]="link" class="placeholder  show-trans-image" />
      </div>
    </div>
  </span>
  <br />
</div>
<hr />
<div class="row">
  <form class="col-12">

    <app-log-time
                  [targetId]="sellerCheck.currentRecord.orderItemId"
                  [type]="'Design'"
                  [readonly]="sellerCheck.sourceProduct.progressState != 10"
                  (stopTimerEvent)="recalculateDesPoint($event)"
                  (timerRunningChanged)="assignTimerRunning($event)">
    </app-log-time>
    <br />
    <div class="row">
      <div class="col-6">
        Điểm design: <strong>{{sellerCheck.sourceProduct.desPoint}}</strong>
      </div>
      <div class="col-6">
        <app-fixed-des-point-for-sku 
          [shouldInit]="openProductLinkModalEvent"
          [sku]="sellerCheck.currentRecord.sku">
        </app-fixed-des-point-for-sku>
        </div>
    </div>
  </form>
</div>
<hr />

<div style="display: inline-flex">
  <div class="uploadDesign" *ngIf="sellerCheck.currentRecord.progressState <= 10">
    Upload file design
    <input #uploadDesInput type="file" multiple="true" [(ngModel)]="desImages" (change)="upload($event.target.files)">
  </div>

  <app-gen-des *ngIf="sellerCheck.currentRecord.progressState <= 10" [inputSku]="sellerCheck.sourceProduct.sku"></app-gen-des>
</div>


<img class="loader" *ngIf="sellerCheck.uploadProgress" src="/content/loading.gif" />


<div class="mt-10" *ngFor="let demoImageLink of sellerCheck.selectedProduct?.demoLinks; let i = index" [hidden]="temptPreviewImages.length > 0">
  <div class="preview-container">
    <img [src]="demoImageLink == null ? global.imgHodlder : demoImageLink"
         class="img-responsive show-trans-image preview-image" />
    <div *ngIf="sellerCheck.imageSizes != undefined && sellerCheck.imageSizes.length >= (i+1)"
         class="preview-overlay">
      {{sellerCheck.imageSizes[i]}}

    </div>
  </div>
</div>
<div class="mt-10" *ngFor="let demoImageLink of temptPreviewImages; let i = index" [hidden]="temptPreviewImages.length == 0">
  <div class="preview-container">
    <img [src]="demoImageLink == null ? global.imgHodlder : demoImageLink"
         class="img-responsive show-trans-image preview-image" />
  </div>
</div>


<div class="mt-10"
     *ngIf="sellerCheck.currentRecord.progressState >= 20 || sellerCheck.currentRecord.progressState == 5">
<div class="form-group row">
  <label class="col-sm-2 col-form-label">Đơn nguồn</label>
  <div class="col-sm-10">
    {{sellerCheck.sourceProduct.sourceOrderId}}




  </div>
</div>
<div class="form-group row">
  <label class="col-sm-2 col-form-label">Sku</label>
  <div class="col-sm-10">
    {{sellerCheck.sourceProduct.sku}}




    <strong>
      (Qty
      {{sellerCheck.sourceProduct.productQuantity}})
    </strong>
    |
    <strong class="ml-2">
      {{genSellerCheckCustomizeInfo(sellerCheck.sourceProduct.customizeInfo)}}




    </strong>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-2 col-form-label">Khách hàng</label>
  <div class="col-sm-10">
    {{sellerCheck.sourceProduct.mobile}}<br />
    {{sellerCheck.sourceProduct.customerName}} <br />
    {{sellerCheck.sourceProduct.streetAddress}}<br />
    <span *ngIf="sellerCheck.sourceProduct.streetAddress2 != undefined && sellerCheck.sourceProduct.streetAddress2 != ''">
      {{sellerCheck.sourceProduct.streetAddress2}}<br />
    </span>
    {{sellerCheck.sourceProduct.city}}, {{sellerCheck.sourceProduct.state}}
        {{sellerCheck.sourceProduct.zipCode}}<br />{{sellerCheck.sourceProduct.channel}}<br />
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-2 col-form-label">Id Đơn mua</label>
  <div class="col-sm-10">
    <input [disabled]="sellerCheck.currentRecord.progressState != 20" type="text" class="form-control"
           [(ngModel)]="sellerCheck.sourceProduct.destinationOrderId" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-2 col-form-label">Nhà in</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availablePrintServices" placeholder="Nhà in"
               (change)="updatePrintService(sellerCheck.sourceProduct.orderItemId ,sellerCheck.sourceProduct.wantedPrintService)"
               [(ngModel)]="sellerCheck.sourceProduct.wantedPrintService">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Flashship' || sellerCheck.sourceProduct.wantedPrintService == 'Flashship Ornament'">
  <label class="col-sm-2 col-form-label">Loại áo FlashShip</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableFlashShipShirtTypes"
               placeholder="Loại áo Flashship"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'FlashShipShirt', sellerCheck.calculatedFlashShipShirtType)"
               [(ngModel)]="sellerCheck.calculatedFlashShipShirtType">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Gearment'">
  <label class="col-sm-2 col-form-label">Loại áo Gearment</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableGearmentTypes"
               placeholder="Loại áo Gearment"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'GearmentType', sellerCheck.calculatedGearmentType)"
               [(ngModel)]="sellerCheck.calculatedGearmentType">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Gearment'">
  <label class="col-sm-2 col-form-label">Vị trí in Gearment</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableGearmentSides"
               placeholder="Vị trí in Gearment"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'GearmentSide', sellerCheck.calculatedGearmentSide)"
               [(ngModel)]="sellerCheck.calculateGearmentSide">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Merchize'">
  <label class="col-sm-2 col-form-label">Loại áo Merchize</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableMerchizeShirtTypes"
               placeholder="Loại áo Merchize"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'merchize', sellerCheck.calculatedMerchizeShirtType)"
               [(ngModel)]="sellerCheck.calculatedMerchizeShirtType">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Artsadd'">
  <label class="col-sm-2 col-form-label">Artsadd Sku</label>
  <div class="col-sm-10">
    <input class="form-control"
           [(ngModel)]="sellerCheck.calculatedArtsAddSku"
           (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'ArtsaddSku', sellerCheck.calculatedArtsAddSku)">
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Artsadd'">
  <label class="col-sm-2 col-form-label">Artsadd One Size</label>
  <div class="col-sm-10">
    <input class="form-control"
           type="checkbox"
           [(ngModel)]="sellerCheck.calculatedIsArtsAddOneSize"
           (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'IsArtsaddOneSize', sellerCheck.calculatedIsArtsAddOneSize)">
  </div>
</div>
<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printify'">
  <label class="col-sm-2 col-form-label">Loại áo Printify</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="blueprints" placeholder="Loại áo Printify" bindValue="id"
               bindLabel="title"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'printifyBlueprint', sellerCheck.calculatedPrintifyBlueprintId)"
               [(ngModel)]="sellerCheck.calculatedPrintifyBlueprintId">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printify'">
  <label class="col-sm-2 col-form-label">Nhà in Printify</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="printifyProviders" placeholder="Nhà in Printify"
               bindValue="id" bindLabel="title"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'printifyProvider', sellerCheck.calculatedPrintifyProviderId)"
               [(ngModel)]="sellerCheck.calculatedPrintifyProviderId">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printcare'">
  <label class="col-sm-2 col-form-label">Loại áo Printcare</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableBestPrintCareShirtTypes"
               placeholder="Loại áo Printcare"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'bestprintcare', sellerCheck.calculatedBestPrintCareShirtType)"
               [(ngModel)]="sellerCheck.calculatedBestPrintCareShirtType">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printcare'">
  <label class="col-sm-2 col-form-label">Line Ship Best Print Care:</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="bestPrintCareLineShips"
               placeholder="Line Ship Best Print Care"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'bestPrintLineShip', sellerCheck.calculatedBestPrintCareLineShip)"
               [(ngModel)]="sellerCheck.calculatedBestPrintCareLineShip">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Geargag'">
  <label class="col-sm-2 col-form-label">Loại áo Geargag</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableGeargagShirtTypes"
               placeholder="Loại áo Geargag"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'geargag', sellerCheck.calculatedGeargagShirtType)"
               [(ngModel)]="sellerCheck.calculatedGeargagShirtType">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printway'">
  <label class="col-sm-2 col-form-label">Sản phẩm PrintWay</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availablePrintWayConfigs"
               placeholder="Sản phẩm PrintWay"
               bindLabel="name"
               bindValue="sku"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'PrintWaySku', sellerCheck.calculatedPrintWaySku)"
               [groupBy]="groupByPrintway"
               [(ngModel)]="sellerCheck.calculatedPrintWaySku">
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span style="font-size: small">{{item.name}}</span>
      </ng-template>

    </ng-select>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-2 col-form-label">Size</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableSizes" placeholder="Size"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'size', sellerCheck.calculatedSize)"
               [addTag]="addNewSize"
               [(ngModel)]="sellerCheck.calculatedSize">
    </ng-select>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-2 col-form-label">Màu</label>
  <div class="col-sm-10">
    <ng-select class="text-left" [items]="sellerCheck.availableColors" placeholder="Màu"
               (change)="updateVariantInfo(sellerCheck.sourceProduct.orderItemId, 'color', sellerCheck.calculatedColor)"
               [addTag]="addNewColor"
               [(ngModel)]="sellerCheck.calculatedColor">
    </ng-select>
  </div>
</div>

<div class="form-group row" *ngIf="sellerCheck.sourceProduct.isPrepaidLabel">
  <label class="col-sm-2 col-form-label">Tracking Trả Trước</label>
  <div class="col-sm-10">
    {{sellerCheck.sourceProduct.trackingNumber}}


  </div>
</div>
<div class="form-group row" *ngIf="sellerCheck.sourceProduct.isPrepaidLabel">
  <label class="col-sm-2 col-form-label">Link Label Tracking Trả Trước</label>
  <div class="col-sm-10">
    {{sellerCheck.sourceProduct.shippingLabelLink}}


  </div>
</div>


<div class="form-group row">
  <label class="col-sm-2 col-form-label">Giá tiền sản phẩm</label>
  <div class="col-sm-10">
    <input [disabled]="sellerCheck.currentRecord.progressState != 20" type="number" class="form-control"
           [(ngModel)]="sellerCheck.sourceProduct.productPrice"
           (change)="syncSellerCheckPrice(sellerCheck.sourceProduct, 'productPrice')">
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-2 col-form-label">Giá tiền toàn bộ đơn</label>
  <div class="col-sm-10">
    <!-- <input type="number" class="form-control" [disabled]="sellerCheck.currentRecord.progressState != 20"
                [(ngModel)]="sellerCheck.sourceProduct.orderPrice"
                (change)="syncSellerCheckPrice(sellerCheck.sourceProduct, 'orderPrice')"> -->
    <app-all-to-usd [(des)]="sellerCheck.sourceProduct.orderPrice"
                    [disabled]="sellerCheck.currentRecord.progressState != 20"
                    (desChange)="syncSellerCheckPrice(sellerCheck.sourceProduct, 'orderPrice')">
    </app-all-to-usd>
  </div>
</div>

<div class="form-group row"
     *ngIf="sellerCheck.currentRecord.progressState >= 30 || sellerCheck.currentRecord.progressState == 5">
  <label class="col-sm-2 col-form-label">Mã Tracking</label>
  <div class="col-sm-10">
    <input [disabled]="sellerCheck.currentRecord.progressState != 30" type="text" class="form-control"
           [(ngModel)]="sellerCheck.sourceProduct.trackingNumber">
  </div>
</div>


<div class="form-group row">
  <div class="col-sm-6" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printify'">
    <button class="btn btn-info" (click)="buyPrintifyByApi()"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      <i class="fas fa-cash-register"></i>
      Mua bằng API
    </button>
  </div>
  <div class="col-sm-6" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Flashship'">
    <button class="btn btn-info" (click)="buyFlashshipByApi()"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      <i class="fas fa-cash-register"></i>
      Mua bằng API
    </button>
  </div>
  <div class="col-sm-6" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printway'">
    <button class="btn btn-info" (click)="buyPrintwayByApi()"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      <i class="fas fa-cash-register"></i>
      Mua bằng API
    </button>
  </div>
  <div class="col-sm-6" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printcare'">
    <button class="btn btn-info" (click)="buyPrintCareByApi()"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      <i class="fas fa-cash-register"></i>
      Mua bằng API
    </button>
  </div>
  <div class="col-sm-6" *ngIf="sellerCheck.sourceProduct.wantedPrintService == 'Printify'
                                || sellerCheck.sourceProduct.wantedPrintService == 'Flashship'
                                || sellerCheck.sourceProduct.wantedPrintService == 'Printcare'
                                || sellerCheck.sourceProduct.wantedPrintService == 'Printway'">
    <button class="btn btn-info" (click)="confirmBuy()"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      <i class="fas fa-cash-register"></i>
      Xác nhận thông tin mua hàng
    </button>
  </div>
  <div class="col-sm-12" *ngIf="sellerCheck.sourceProduct.wantedPrintService != 'Printify'
                              && sellerCheck.sourceProduct.wantedPrintService != 'Flashship'
                              && sellerCheck.sourceProduct.wantedPrintService != 'Printcare'
                              && sellerCheck.sourceProduct.wantedPrintService != 'Printway'">
    <button class="btn btn-info" (click)="confirmBuy()"
            *ngIf="sellerCheck.currentRecord.progressState == 20">
      <i class="fas fa-cash-register"></i>
      Xác nhận thông tin mua hàng
    </button>
  </div>
</div>

<div class="form-group row">
  <div class="col-sm-12">
    <button class="btn btn-success" (click)="confirmTracking()"
            *ngIf="sellerCheck.currentRecord.progressState == 30">
      <i class="fas fa-truck-loading"></i>
      Xác nhận mã tracking
    </button>
  </div>
</div>

<div class="form-group row">
  <div class="col-sm-12">
    <button class="btn btn-primary" (click)="confirmTrackingAccount()"
            *ngIf="sellerCheck.currentRecord.progressState == 35">
      <i class="fas fa-truck-loading"></i>
      Xác nhận trả mã tracking vào TK
    </button>
  </div>
</div>

<div class="form-group row">
  <div class="col-sm-12">
    <button class="btn btn-primary" (click)="confirmTrackingDone()"
            *ngIf="sellerCheck.currentRecord.progressState == 40">
      <i class="fas fa-truck"></i>
      Xác nhận đơn hàng đã giao thành công
    </button>
  </div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</ng-template>

<ng-template #exportPrinityShirtModal let-modal>
  <div class="modal-lg">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Export Printify Shirts</h4>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <select class="form-control" [(ngModel)]="printifyExport.providerId">
          <option value="">Please Select Print Provider</option>
          <option *ngFor="let provider of printifyProviders" [value]="provider.printifyId">
            {{ provider.providerName }}


          </option>
        </select>
      </div>
      <div class="form-group">
        <select class="form-control" [(ngModel)]="printifyExport.productId">
          <option value="">Please Select Product</option>
          <option *ngFor="let product of printifyProducts" [value]="product.printifyId">
            {{ product.productName }}


          </option>
        </select>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="downloadExportFileForPrintify()">Ok</button>
    </div>
  </div>
</ng-template>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Modal title</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>One fine body&hellip;</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #deleteSaleRecordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirm Delete</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure want to delete sale record: {{deletedSaleRecord.productName}}


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">No</button>
    <button type="button" class="btn btn-light btn-danger" (click)="deleteSaleRecord()">Yes</button>
  </div>
</ng-template>

<app-confirm-action [confirmMessage]="'Bạn có chắc muốn xóa des này và không gợi ý với toàn bộ đơn?'"
                    (action)="ignoreDesTotally()"
                    [openModal]="openConfirmAction">
</app-confirm-action>

<app-confirm-action [confirmMessage]="'Bạn đang có Des khác đang làm dở, bạn có chắc chắn muốn bắt đầu làm việc với Des này?'"
                    (action)="confirmDesWork()"
                    [openModal]="openConfirmStartDesModal">
</app-confirm-action>

<app-confirm-action [confirmMessage]="confirmBuyApiMessage"
                    (action)="buyApiAction()"
                    [openModal]="openConfirmBuyApiAction">
</app-confirm-action>

<ng-template #importSaleRecordModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Import Đơn Etsy/Ebay</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Nhóm
    <select class="form-control" [class.hasError]="uploadToMerchantGroupId==''" [(ngModel)]="uploadToMerchantGroupId">
      <option value="">Nếu có tài khoản mới, sẽ được thêm vào team</option>
      <option *ngFor="let group of merchantGroups" [value]="group.id">{{ group.name }}</option>
    </select>

    Tài khoản
    <ng-select [class.hasError]="uploadToMerchantId==''"
               [items]="etsyMerchants"
               [multiple]="false"
               placeholder="Chọn Tài Khoản"
               bindValue="merchantId"
               bindLabel="name"
               [closeOnSelect]="true"
               [(ngModel)]="uploadToMerchantId"
               name="accountName">
    </ng-select>

    <input class="mt-10 mb-4" type="file" multiple="true" (change)="uploadImportFile($event.target.files)">
    <div class="progress mt-10" *ngIf="uploadImportFileProgress">
      <div class="progress-bar" [style.width]="uploadImportFileProgress + '%'">
        {{uploadImportFileProgress}}%
      </div>
    </div>
    <p class="mt-10" *ngFor="let message of importMessages">
      {{message}}


    </p>
  </div>
</ng-template>

<ng-template #importSaleRecordTiktokModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Import Đơn Tiktok</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select
      [class.hasError]="uploadToMerchantGroupId==''"
      [items]="tiktokMerchants"
      [multiple]="false"
      placeholder="Chọn Tài Khoản"
      bindValue="merchantId"
      bindLabel="name"
      [closeOnSelect]="true"
      [(ngModel)]="uploadToMerchantGroupId"
      name="accountName">
    </ng-select>


    <input class="mt-10 mb-4" type="file" multiple="true" (change)="uploadImportFile($event.target.files)">
    <div class="progress mt-10" *ngIf="uploadImportFileProgress">
      <div class="progress-bar" [style.width]="uploadImportFileProgress + '%'">
        {{uploadImportFileProgress}}%
      </div>
    </div>
    <p class="mt-10" *ngFor="let message of importMessages">
      {{message}}


    </p>
  </div>
</ng-template>
